<template>
  <div class="bennian_qianshou_container">
    <div class="work_box">
      <div class="content">
        <huan-echarts
          v-if="!currentRatio.isLoading"
          :chartData="currentRatioChart"
          :className="'huan_chart'"
          id="current_ratio"
        ></huan-echarts>
        <huan-echarts
          v-if="!oldRatio.isLoading"
          :chartData="oldRatioChart"
          :className="'huan_chart'"
          id="old_ratio"
        ></huan-echarts>
        <huan-echarts
          v-if="!ratio.isLoading"
          :chartData="ratioChart"
          :className="'huan_chart'"
          id="ratio"
        ></huan-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SjlvBox",
  props: ["currentRatio", "oldRatio", "ratio"],
  data() {
    return {
      currentRatioChart: {
        nameOfSeries: "当年整体收缴率",
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        emphasisOfSeries: {},
        labelOfSeries: {
          normal: {
            show: false,
            fontWeight: "bold",
            fontSize: "20",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
      oldRatioChart: {
        nameOfSeries: "当年旧欠收缴率",
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        emphasisOfSeries: {},
        labelOfSeries: {
          normal: {
            show: false,
            fontWeight: "bold",
            fontSize: "20",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
      ratioChart: {
        nameOfSeries: "整体收缴率",
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        emphasisOfSeries: {},
        labelOfSeries: {
          normal: {
            show: false,
            fontWeight: "bold",
            fontSize: "20",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
    };
  },
  watch: {
    "currentRatio.datum"(data) {
      if (data && data.org_list) {
        console.log("当年整体");
        this.formatCurrentChart();
      }
    },
    "oldRatio.datum"(data) {
      if (data && data.org_list) {
        // this.formatOldChart();
        console.log("当年旧欠");
      }
    },
    "ratio.datum"(data) {
      if (data && data.org_list) {
        // this.formatChart();
        console.log("整体");
      }
    },
  },
  methods: {
    formatCurrentChart() {
      console.log("当年整体format", this.currentRatio);
      this.currentRatioChart.dataOfSeries = [
        {
          name: "当年已收",
          value: Number(this.currentRatio.datum.total.pay_amount).toFixed(
            2
          ),
          label: { show: false },
        },
        {
          name: "当年未收",
          value: (
            Number(this.currentRatio.datum.total.total_amount) -
            Number(this.currentRatio.datum.total.pay_amount)
          ).toFixed(2),
          label: { show: true },
        },
      ];
    },
    formatOldChart() {
      this.oldRatioChart.dataOfSeries = [
        {
          name: "当年旧欠",
          value: Number(this.oldRatio.datum.total.pay_amount).toFixed(
            2
          ),
          label: { show: false },
        },
        {
          name: "当年未收",
          value: (
            Number(this.oldRatio.datum.total.total_amount) -
            Number(this.oldRatio.datum.total.pay_amount)
          ).toFixed(2),
          label: { show: true },
        },
      ];
    },
    formatChart() {
      console.log("当年整日收缴率", this.currentRatio);
      this.ratioChart.dataOfSeries = [
        {
          name: "当年已收",
          value: Number(this.ratio.datum.total.pay_amount).toFixed(
            2
          ),
          label: { show: false },
        },
        {
          name: "当年未收",
          value: (
            Number(this.ratio.datum.total.total_amount) -
            Number(this.ratio.datum.total.pay_amount)
          ).toFixed(2),
          label: { show: true },
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
