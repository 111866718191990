<template>
  <div class="video_container">
    <slot name="video_arr"></slot>
    <div class="btn-list">
      <template v-if="btnList && btnList.length">
        <div
          class="btn-list-item"
          :class="curTab.id == item.id && 'active'"
          v-for="(item, index) in btnList"
          :key="index"
          @click="select(item)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoTab",
  props: {
    btnList: { type: Array, default: () => [] },
    curVideo: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      curTab: { id: 1 },
    };
  },
  watch: {
    curVideo: {
      handler(val, oldVal) {
        if (val) {
          this.curTab = val;
        }
      },
      deep: true,
    },
  },
  methods: {
    select(item) {
      this.curTab = item;
      this.$emit("update:curVideo", this.curTab);
    },
  },
};
</script>
<style lang="scss">
@import "./style.scss";
</style>
