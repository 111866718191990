<template>
  <layout :navWidth="curOrg.type == 0 ? '30em' : '30em'" :isAsideShow="false">
    <div slot="nav" class="shishou_nav">
      <shishou-rank
        :isLoading="income.isLoading"
        :datum="income.datum"
      ></shishou-rank>
    </div>
    <div slot="content" class="xianjinshoujiaolv_content">
      <div class="top_container">
        <div class="work_box" style="width: 58%">
          <div class="content">
            <shoukuan-chart
              :isLoading="income.isLoading"
              :datum="charge.datum"
              :datumOfIncome="income.datum"
              :isLoadingOfIncome="income.isLoading"
              :height="curOrg && curOrg.type == 1 ? '100%' : '35%'"
              :curOrg="curOrg"
            ></shoukuan-chart>
          </div>
        </div>
        <sjlv-box
          :currentRatio="RatioOfCurrentBill"
          :oldRatio="RatioOfOldBill"
          :ratio="ratio"
        ></sjlv-box>
      </div>
      <div class="bottom_container">
        <template v-if="income.datum">
          <div
            :style="{
              backgroundColor: 'red',
              width: `${
                57 / Object.keys(income.datum.total.year.tag_list).length
              }%`,
            }"
            v-for="(value, key) in income.datum.total.year.tag_list"
            :key="key"
          >
            <duibi
              :datum="income.datum"
              :keyOfDatum="key"
              :isLoading="income.isLoading"
              :curOrg="curOrg"
            ></duibi>
          </div>
        </template>

        <!-- <shequ-duibi :datum="income.datum" :isLoading="income.isLoading" :curOrg="curOrg"></shequ-duibi>
          <feishequ-duibi :datum="income.datum" :isLoading="income.isLoading" :curOrg="curOrg"></feishequ-duibi> -->
        <year-sjlv-rank
          :datum="RatioOfCurrentBill.datum"
          :isLoading="RatioOfCurrentBill.isLoading"
        ></year-sjlv-rank>
        <jiuqian-sjlv-rank
          :datum="RatioOfOldBill.datum"
          :isLoading="RatioOfOldBill.isLoading"
        ></jiuqian-sjlv-rank>
      </div>
    </div>
  </layout>
</template>

<script>
import ShishouRank from "./components/shishouRank/ShishouRank";
import SjlvBox from "./components/sjlvBox/SjlvBox";
import Duibi from "./components/duibi/Duibi";
import ShequDuibi from "./components/shequDuibi/ShequDuibi";
import FeishequDuibi from "./components/feishequDuibi/FeishequDuibi";
import YearSjlvRank from "./components/yearSjlvRank/YearSjlvRank";
import JiuqianSjlvRank from "./components/jiuqianSjlvRank/JiuqianSjlvRank";
import { mapState } from "vuex";
import financialApi from "@/api/company/financial";
import shoukuanChart from "./components/shoukuanChart/ShoukuanChart";
export default {
  name: "Index",
  components: {
    ShishouRank,
    SjlvBox,
    Duibi,
    ShequDuibi,
    FeishequDuibi,
    YearSjlvRank,
    JiuqianSjlvRank,
    shoukuanChart,
  },
  data() {
    return {
      income: { isLoading: false, datum: null },
      charge: { isLoading: false, datum: null },
      RatioOfCurrentBill: { isLoading: false, datum: null },
      RatioOfOldBill: { isLoading: false, datum: null },
      ratio: { isLoading: false, datum: null },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getSummaryOfIncome(); // 收款
          this.getSummaryOfCharge(); // 收款
          this.getRatioOfCurrentBill(); //当年收缴率
          this.getRatioOfOldBill(); //旧欠收缴率
          this.getRatioOfBill(); //收缴率
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        return state.curOrg ? state.curOrg : { path: [], id: 1, type: 0 };
      },
    }),
  },
  methods: {
    getRatioOfBill() {
      this.ratio.isLoading = true;
      financialApi.bill
        .getRatioOfBill(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.ratio.datum = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.ratio.isLoading = false;
        });
    },
    getRatioOfCurrentBill() {
      this.RatioOfCurrentBill.isLoading = true;
      financialApi.bill
        .getRatioOfCurrentBill(
          this.curOrg && { organization_id: this.curOrg.id }
        )
        .then(
          (res) => {
            this.RatioOfCurrentBill.datum = res.data.datum;
            console.log("当年整体收缴率是", this.RatioOfCurrentBill.datum);
          },
          (err) => {
            console.log(err);
          }
        )
        .finally(() => {
          this.RatioOfCurrentBill.isLoading = false;
        });
    },
    getRatioOfOldBill() {
      this.RatioOfOldBill.isLoading = true;
      financialApi.bill
        .getRatioOfOldBill(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.RatioOfOldBill.datum = res.data.datum;
            console.log("当年整体收缴率是", this.RatioOfOldBill.datum);
          },
          (err) => {
            console.log(err);
          }
        )
        .finally(() => {
          this.RatioOfOldBill.isLoading = false;
        });
    },
    getSummaryOfIncome() {
      this.income.isLoading = true;
      financialApi.income
        .getSummaryOfIncome(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.income.isLoading = false;
            this.income.datum = res.data.datum;
          },
          (err) => {
            this.income.isLoading = false;
          }
        );
    },
    getSummaryOfCharge() {
      this.charge.isLoading = true;
      financialApi.charge
        .getSummaryOfCharge(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.charge.isLoading = false;
            this.charge.datum = res.data.datum;
          },
          (err) => {
            console.log(err);
            this.charge.isLoading = false;
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
