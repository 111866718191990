<template>
  <div class="jiuqian_sjlv_container">
    <div class="work_box">
      <div class="title">旧欠收缴率排行</div>
      <div class="content">
        <hor-bar-echarts
          v-if="!isLoading"
          :chartData="chart"
          :className="'hor_bar_chart'"
          id="jiuqian_sjlv_rank"
        ></hor-bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "JiuqianSjlvRank",
  props: ["datum", "isLoading", "curOrg"],
  watch: {
    datum(data) {
      if (data && data.org_list) {
        this.formatChart();
      }
    },
  },
  data() {
    return {
      chart: {
        series: [],
        // dataOfLegend: [],
        dataOfYaxis: [],
        grid: {
          left: "1%",
          right: "5%",
          bottom: "0%",
          top: "5%",
          containLabel: true,
        },
      },
    };
  },
  methods: {
    formatChart() {
      this.chart.series = [
        {
          name: "收缴率",
          type: "bar",
          stack: "率",
          label: {
            show: false,
            position: "center",
          },
          data: [],
        },
        {
          name: "欠缴率",
          type: "bar",
          stack: "率",
          label: {
            show: false,
            position: "center",
          },
          data: [],
        },
      ];
      if (this.datum.org_list) {
        let orgList = this.datum.org_list;
        // 从小到大排序
        orgList = Object.entries(orgList).sort((a, b) => {
          return a[1].percent - b[1].percent;
        });
        this.chart.dataOfYaxis = [];
        orgList.forEach((itm) => {
          if (!isNaN(Number(itm[1].percent)) && itm[1].percent != 0) {
            this.chart.dataOfYaxis.push(itm[1].name);
            this.chart.series[0].data.push(Number(itm[1].percent));
            this.chart.series[1].data.push(100 - Number(itm[1].percent));
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
