<template>
  <layout :navWidth="curOrg.type == 0 ? '30em' : '30em'" :isAsideShow="false">
    <template v-if="curOrg.type == 0">
      <div slot="nav" class="renliziyuan_nav">
        <count-of-zhigong
          :isLoading="count.isLoading"
          :datum="count.datum"
          v-loading="count.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></count-of-zhigong>
        <count-of-department
          :countOfDepartment="countOfDepartment"
          v-loading="count.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></count-of-department>
        <!-- <chengben-of-renjun
          :isLoading="chengbenOfRenjun.isLoading"
          :datum="chengbenOfRenjun.datum"
          v-loading="chengbenOfRenjun.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></chengben-of-renjun>
        <qushi-of-chengben
          :isLoading="chengbenOfRenjun.isLoading"
          :datum="chengbenOfRenjun.datum"
          v-loading="chengbenOfRenjun.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></qushi-of-chengben> -->
      </div>
      <div slot="content" class="renliziyuan_content">
        <sex
          :isLoading="sex.isLoading"
          :datum="sex.datum"
          v-loading="sex.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></sex>
        <change
          :isLoading="change.isLoading"
          :list="change.data"
          v-loading="change.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></change>
        <age
          :isLoading="age.isLoading"
          :datum="age.datum"
          v-loading="age.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></age>
        <add-minus
          :isLoading="addMinus.isLoading"
          :datum="addMinus.datum"
          v-loading="addMinus.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></add-minus>
        <education
          :isLoading="education.isLoading"
          :datum="education.datum"
          v-loading="education.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></education>
        <education-change
          :isLoading="educationChange.isLoading"
          :list="educationChange.data"
          v-loading="educationChange.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></education-change>
        <level
          :isLoading="level.isLoading"
          :datum="level.datum"
          v-loading="level.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></level>
        <level-change
          :isLoading="levelChange.isLoading"
          :list="levelChange.data"
          v-loading="levelChange.isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></level-change>
      </div>
    </template>
    <template v-else>
      <div slot="nav" class="project_renliziyuan_nav">
        <base-info :isLoading="org.isLoading" :datum="org.datum" :curOrg="curOrg"></base-info>
      </div>
      <div slot="content" class="project_renliziyuan_content">
        <div class="left">
          <count-of-zhigong
            :isLoading="count.isLoading"
            :datum="count.datum"
            :countOfDepartment="countOfDepartment"
            v-loading="count.isLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></count-of-zhigong>
          <count-of-department
            :countOfDepartment="countOfDepartment"
            v-loading="count.isLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></count-of-department>
          <!-- <chengben-of-renjun
            :isLoading="chengbenOfRenjun.isLoading"
            :datum="chengbenOfRenjun.datum"
            v-loading="chengbenOfRenjun.isLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></chengben-of-renjun> -->
        </div>
        <div class="right">
          <add-minus
            :isLoading="addMinus.isLoading"
            :datum="addMinus.datum"
            v-loading="addMinus.isLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></add-minus>
          <change
            :isLoading="change.isLoading"
            :list="change.data"
            v-loading="change.isLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></change>
          <!-- <qushi-of-chengben
            :isLoading="chengbenOfRenjun.isLoading"
            :datum="chengbenOfRenjun.datum"
            v-loading="chengbenOfRenjun.isLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></qushi-of-chengben> -->
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import hrApi from "@/api/renliziyuan/renliziyuan";
import CountOfZhigong from "./components/countOfZhigong/CountOfZhigong";
import CountOfZhigongProject from "./components/countOfZhigongProject/CountOfZhigongProject";
import CountOfDepartment from "./components/countOfDepartment/CountOfDepartment";
import ChengbenOfRenjun from "./components/chengbenOfRenjun/ChengbenOfRenjun";
import QushiOfChengben from "./components/qushiOfChengben/QushiOfChengben.vue";
import Sex from "./components/sex/Sex.vue";
import Change from "./components/change/Change.vue";
import MonthEnd from "./components/monthEnd/MonthEnd.vue";
import Level from "./components/level/Level.vue";
import LevelChange from "./components/level/LevelChange.vue";
import Education from "./components/education/Education.vue";
import EducationChange from "./components/education/EducationChange.vue";
import AddMinus from "./components/addMinus/AddMinus.vue";
import Sallary from "./components/sallary/Sallary.vue";
import Age from "./components/age/Age.vue";
import { mapState } from "vuex";
import BaseInfo from "@/components/common/baseInfo/BaseInfo";
import orgApi from "@/api/org/org";
import companyHrApi from "@/api/company/hr";

export default {
  name: "Index",
  components: {
    MonthEnd,
    CountOfZhigong,
    CountOfZhigongProject,
    CountOfDepartment,
    ChengbenOfRenjun,
    QushiOfChengben,
    Sex,
    Change,
    Level,
    LevelChange,
    Education,
    EducationChange,
    AddMinus,
    Sallary,
    Age,
    BaseInfo,
  },
  data() {
    return {
      sex: { isLoading: false, datum: null },
      change: { isLoading: false, data: [] },
      level: { isLoading: false, datum: null },
      levelChange: { isLoading: false, data: [] },
      education: { isLoading: false, datum: null },
      educationChange: { isLoading: false, data: [] },
      count: { isLoading: false, datum: null },
      age: { isLoading: false, datum: null },
      addMinus: { isLoading: false, datum: null },
      chengbenOfRenjun: { isLoading: false, datum: null },
      qushiOfRenjunChengben: { isLoading: false, datum: null },
      org: { isLoading: false, datum: null },
      countOfDepartment: { isLoading: false, data: [] },
      curRoute: { name: "人力资源", url: "renliziyuan" },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        if (newVal) {
          // console.log("人力资源模块的vuex发生变化");
          this.getSummaryOfHr(); // 人均工资
          this.getSummaryOfAge(); // 人力资源概况
          this.getSummary_CountOfJobType(); // 按照用工性质（人数统计）
          this.getSummaryOfSex(); // 性别
          this.getSummaryOfMonth(); // 每月在职员工数量
          this.getNameOfJob(); // 职级结构
          this.getSummaryOfEducatition(); // 学历结构
          this.getSummaryOfEducationChange(); // 学历变化趋势
          this.getTrendOfNameChange(); // 职级变化趋势
          this.getSummaryOfAddMinus(); // 增员减员
          this.getDetailOfOrg();
          this.getSummaryOfDeparment();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        return state.curOrg ? state.curOrg : { path: [], id: 1, type: 0 };
      },
    }),
  },
  methods: {
    getDetailOfOrg() {
      this.org.isLoading = true;
      orgApi.detailOfOrg(this.curOrg.id).then(
        (res) => {
          this.org.isLoading = false;
          this.org.datum = res.data.datum;
        },
        (err) => {
          this.org.isLoading = false;
        }
      );
    },
    getSummaryOfHr() {
      this.chengbenOfRenjun.isLoading = true;
      companyHrApi.getSummaryOfHr({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.chengbenOfRenjun.isLoading = false;
          this.chengbenOfRenjun.datum = res.data.datum;
        },
        (err) => {
          this.chengbenOfRenjun.isLoading = false;
        }
      );
    },
    getSummaryOfAddMinus() {
      this.addMinus.isLoading = true;
      hrApi.summaryOfAddMinus({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.addMinus.isLoading = false;
          this.addMinus.datum = res.data.datum;
        },
        (err) => {
          this.addMinus.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfMonth() {
      console.log("变化趋势");
      this.change.isLoading = true;
      hrApi.summaryOfMonth({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.change.isLoading = false;
          this.change.data = res.data.data;
        },
        (err) => {
          this.change.isLoading = false;
          console.log(err);
        }
      );
    },
    getNameOfJob() {
      this.level.isLoading = true;
      hrApi.nameOfJob({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.level.isLoading = false;
          this.level.datum = res.data.datum;
        },
        (err) => {
          this.level.isLoading = false;
          console.log(err);
        }
      );
    },
    getTrendOfNameChange() {
      this.levelChange.isLoading = true;
      hrApi.trendOfNameChange({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.levelChange.isLoading = false;
          this.levelChange.data = res.data.data;
        },
        (err) => {
          this.levelChange.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfEducatition() {
      this.education.isLoading = true;
      hrApi.summaryOfEducation({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.education.isLoading = false;
          this.education.datum = res.data.datum;
        },
        (err) => {
          this.education.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfEducationChange() {
      this.educationChange.isLoading = true;
      hrApi.summaryOfEducationChange({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.educationChange.isLoading = false;
          this.educationChange.data = res.data.data;
        },
        (err) => {
          this.educationChange.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfSex() {
      this.sex.isLoading = true;
      hrApi.summaryOfSex({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.sex.isLoading = false;
          this.sex.datum = res.data.datum;
        },
        (err) => {
          this.sex.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummary_CountOfJobType() {
      this.count.isLoading = true;
      hrApi.summary_CountOfJobType({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.count.isLoading = false;
          this.count.datum = res.data.datum;
          console.log(this.count.datum);
        },
        (err) => {
          this.count.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfDeparment() {
      this.countOfDepartment.isLoading = true;
      hrApi.summaryOfDepartment({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.countOfDepartment.isLoading = false;
          this.countOfDepartment.data = res.data.data;
        },
        (err) => {
          this.countOfDepartment.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfAge() {
      this.age.isLoading = true;
      hrApi.summaryOfAge({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.age.isLoading = false;
          this.age.datum = res.data.datum;
        },
        (err) => {
          this.age.isLoading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
