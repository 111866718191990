<template>
    <div class="gongcheng_department_container">
       <div class="work_box">
           <div class="title">工程部</div>
           <div class="content">afsdfas</div>
        </div> 
    </div>
</template>
<script>
export default {
    name:"GongchengDepartment"
}
</script>
<style lang="scss" scoped>
    .gongcheng_department_container{
        height: 28%;
        & > .work_box{
            height:100%;
        }
    }
</style>