<template>
  <div class="container">
    <div class="content_left">
      <slot name="l_left">1</slot>
    </div>
    <div class="content_right">
      <slot name="l_right">2</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentContainer",
};
</script>
<style lang="scss">
@import "./style.scss";
</style>