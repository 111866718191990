<template>
  <div class="gongcheng_container">
    <div class="work_box">
      <div class="title">
        <span>工程运维</span>
        <router-link :to="{ name: 'gongcheng', query: $route.query }"
          >前往 >></router-link
        >
      </div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <template v-if="data && data.length">
          <div v-for="(value, key) in obj" :key="key">
            <div class="huan">
              <huan-echarts
                v-if="!isLoading"
                :chartData="value.chart"
                :id="key"
                :className="'huan_chart'"
              ></huan-echarts>
            </div>
            <h6>{{ value.name }}</h6>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GongchengYunwei",
  props: ["isLoading", "data"],
  data() {
    return {
      obj: {
        finishOfXunjian: {
          name: "巡检完成率",
          chart: {
            dataOfSeries: [],
            posOfChart: ["50%", "50%"],
            radiusOfChart: ["50%", "60%"],
            labelOfSeries: {
              normal: {
                show: false,
                fontWeight: "bold",
                position: "center",
                formatter: "{d}%",
              },
            },
          },
        },
        finishOfYanghu: {
          name: "养护完成率",
          chart: {
            dataOfSeries: [],
            posOfChart: ["50%", "50%"],
            radiusOfChart: ["50%", "60%"],
            labelOfSeries: {
              normal: {
                show: false,
                fontWeight: "bold",
                position: "center",
                formatter: "{d}%",
              },
            },
          },
        },
        // JishiOfXunjian: {
        //   name: "巡检及时率",
        //   chart: {
        //     dataOfSeries: [],
        //     posOfChart: ["50%", "50%"],
        //     radiusOfChart: ["50%", "60%"],
        //   },
        // },
        // JishiOfYanghu: {
        //   name: "养护及时率",
        //   chart: {
        //     dataOfSeries: [],
        //     posOfChart: ["50%", "50%"],
        //     radiusOfChart: ["50%", "60%"],
        //   },
        // },
      },
      keys: {
        total_count: "总数量",
        finished_count: "完成数量",
        unfinished_count: "未完成数量",
        timeout_count: "超时数量",
      },
    };
  },
  watch: {
    data: {
      handler() {
        if (this.data && this.data.length) {
          this.formatFinishOfYanghu();
          this.formatFinishOfXunjian();
        }
      },
      deep: true,
    },
  },
  methods: {
    formatFinishOfYanghu() {
      this.obj.finishOfYanghu.chart.dataOfSeries = [
        {
          name: "已完成",
          value: this.data[0].finished_count,
          label: { show: true },
        },
        {
          name: "未完成",
          value: this.data[0].unfinished_count,
           label: { show: false },
        },
      ];
    },
    formatFinishOfXunjian() {
      this.obj.finishOfXunjian.chart.dataOfSeries = [
        {
          name: "已完成",
          value: this.data[1].finished_count,
          label: { show: true },
        },
        {
          name: "未完成",
          value: this.data[1].unfinished_count,
          label: { show: false },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
