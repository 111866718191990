<template>
  <div class="add_minus_container">
    <div class="work_box">
      <div class="content">
        <div class="add_left">
          <hor-tabs
            v-if="!isLoading"
            :tabs="add.list"
            :curTab.sync="add.curTab"
          >
            <div slot="list" class="list_chart">
              <div
                v-for="(value, key) in add.list"
                :key="'add_' + key"
                v-if="key == add.curTab"
              >
                <pie-echarts
                  :chartData="value.chartData"
                  :className="'pie__chart'"
                  :id="'add_' + key"
                ></pie-echarts>
              </div>
            </div>
          </hor-tabs>
        </div>
        <div class="minus_right">
          <hor-tabs
            v-if="!isLoading"
            :tabs="minus.list"
            :curTab.sync="minus.curTab"
          >
            <div slot="list" class="list_chart">
              <div
                v-for="(value, key) in minus.list"
                :key="'minus_' + key"
                v-if="key == minus.curTab"
              >
                <pie-echarts
                  :chartData="value.chartData"
                  :className="'pie__chart'"
                  :id="'minus_' + key"
                ></pie-echarts>
              </div>
            </div>
          </hor-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HorTabs from "@/components/common/horTabs/HorTabs.vue";

export default {
  name: "AddMinus",
  components: { HorTabs },
  props: ["isLoading", "datum"],
  watch: {
    datum() {
      console.log(this.datum);
      this.formatChart();
    },
  },
  data() {
    return {
      add: {
        curTab: "curMonth",
        list: {
          curMonth: {
            name: "本月增员",
            chartData: {
              dataOfSeries: [],
              posOfChart: ["60%", "50%"],
              radiusOfSeries: "60%",
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} 人 ({d}%)",
              },
              legend: {
                orient: "vertical",
                left: 0,
                top: "center",
                textStyle: {
                  fontSize: 10,
                  color: "#fff",
                },
                data: [],
              },
            },
          },
          curYear: {
            name: "年初增员累计",
            chartData: {
              dataOfSeries: [],
              posOfChart: ["60%", "50%"],
              radiusOfSeries: "60%",
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} 人 ({d}%)",
              },
              legend: {
                orient: "vertical",
                left: 0,
                top: "center",
                textStyle: {
                  fontSize: 10,
                  color: "#fff",
                },
                data: [],
              },
            },
          },
        },
      },
      minus: {
        curTab: "curMonth",
        list: {
          curMonth: {
            name: "本月减员",
            chartData: {
              dataOfSeries: [],
              posOfChart: ["60%", "50%"],
              radiusOfSeries: "60%",
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} 人 ({d}%)",
              },
              legend: {
                orient: "vertical",
                left: 0,
                top: "center",
                textStyle: {
                  fontSize: 10,
                  color: "#fff",
                },
                data: [],
              },
            },
          },
          curYear: {
            name: "年初减员累计",
            chartData: {
              dataOfSeries: [],
              posOfChart: ["60%", "50%"],
              radiusOfSeries: "60%",
              tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b}: {c} 人 ({d}%)",
              },
              legend: {
                orient: "vertical",
                left: 0,
                top: "center",
                textStyle: {
                  fontSize: 10,
                  color: "#fff",
                },
                data: [],
              },
            },
          },
        },
      },
    };
  },
  methods: {
    formatChart() {
      if (this.datum.job_type_list && this.datum.job_type_list.length) {
        for (let key in this.add.list) {
          this.add.list[key].chartData.dataOfSeries = [];
          this.add.list[key].chartData.legend.data = [];
          this.datum.job_type_list.forEach((item) => {
            let obj = {
              name: item.name,
              value:
                key == "curMonth" ? item.month_inc_count : item.year_inc_count,
            };
            this.add.list[key].chartData.dataOfSeries.push(obj);
            this.add.list[key].chartData.legend.data.push(item.name);
          });
        }
        for (let key in this.minus.list) {
          this.minus.list[key].chartData.dataOfSeries = [];
          this.minus.list[key].chartData.legend.data = [];
          this.datum.job_type_list.forEach((item) => {
            let obj = {
              name: item.name,
              value:
                key == "curMonth"
                  ? item.month_dec_count
                  : item.year_dec_count,
            };
            this.minus.list[key].chartData.dataOfSeries.push(obj);
            this.minus.list[key].chartData.legend.data.push(item.name);
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
