<template>
  <div class="time_out_container">
    <div class="type" v-for="(value, key) in type" :key="key">
      <div class="work_box">
        <div class="title">{{ value.name }}</div>
        <div
          class="content"
          :class="key + '_content'"
          v-loading="isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div :id="key + '_scroll_div'">
            <div :id="key + '_scroll_begin'">
              <h6 v-for="(item, index) in value.data" :key="index">
                {{ `${item.org.name}-${item.title}超时` }}
              </h6>
            </div>
            <div :id="key + '_scroll_end'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeOut",
  props: ["data", "isLoading"],
  data() {
    return {
      rankMyMar1: null,
      rankMyMar0: null,
      type: {
        0: {
          name: "养护超时预警",
          data: [],
        },
        1: {
          name: "巡检超时预警",
          data: [],
        },
      },
      speed: 100,
    };
  },
  destroyed() {
    console.log("销毁");
    clearInterval(this.rankMyMar0);
    clearInterval(this.rankMyMar1);
    this.rankMyMar1 = null;
    this.rankMyMar0 = null;
  },
  mounted() {
    const content_1 = document.getElementsByClassName("1_content")[0];
    const content_0 = document.getElementsByClassName("0_content")[0];
    content_1.addEventListener("mouseenter", () => {
      console.log("鼠标移入1");
      clearInterval(this.rankMyMar1); //鼠标移入清除定时器
    });
    content_1.addEventListener("mouseleave", () => {
      console.log("鼠标移出1");
      this.rankMyMar1 = setInterval(this.Marquee1, this.speed);
    });
    content_0.addEventListener("mouseenter", () => {
      console.log("鼠标移入0");
      clearInterval(this.rankMyMar0); //鼠标移入清除定时器
    });
    content_0.addEventListener("mouseleave", () => {
      console.log("鼠标移出0");
      this.rankMyMar0 = setInterval(this.Marquee0, this.speed);
    });
  },
  watch: {
    data: {
      handler() {
        if (this.data && this.data.length) {
          this.formatData();
        }
      },
      deep: true,
    },
  },
  methods: {
    Marquee1() {
      const scroll_begin = document.getElementById("1_scroll_begin");
      const scroll_end = document.getElementById("1_scroll_end");
      const scroll_div = document.getElementById("1_scroll_div");
      if (scroll_end.offsetHeight - scroll_div.scrollTop <= 0) {
        scroll_div.scrollTop -= scroll_begin.offsetHeight;
        scroll_end.innerHTML = scroll_begin.innerHTML;
      } else {
        scroll_div.scrollTop++;
      }
    },
    Marquee0() {
      const scroll_begin = document.getElementById("0_scroll_begin");
      const scroll_end = document.getElementById("0_scroll_end");
      const scroll_div = document.getElementById("0_scroll_div");
      if (scroll_end.offsetHeight - scroll_div.scrollTop <= 0) {
        scroll_div.scrollTop -= scroll_begin.offsetHeight;
        scroll_end.innerHTML = scroll_begin.innerHTML;
      } else {
        scroll_div.scrollTop++;
      }
    },
    //文字横向滚动
    ScrollImgTop1() {
      if (this.rankMyMar1) {
        clearInterval(this.rankMyMar1);
        this.rankMyMar1 = null;
      }
      this.rankMyMar1 = setInterval(this.Marquee1, this.speed);
    },
    ScrollImgTop0() {
      if (this.rankMyMar0) {
        clearInterval(this.rankMyMar0);
        this.rankMyMar0 = null;
      }
      this.rankMyMar0 = setInterval(this.Marquee0, this.speed);
    },
    formatData() {
      this.data.forEach((item) => {
        this.type[item.planType].data.push(item);
      });
      this.$nextTick(() => {
        this.ScrollImgTop0();
        this.ScrollImgTop1();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
