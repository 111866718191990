<template>
  <layout :isAsideShow="false" :navWidth="curOrg.type != 0 ? '30em' : '40em'">
    <template v-if="curOrg.type == 0">
      <div slot="nav" class="yunwei_nav">
        <project-box
          :isLoading="project.isLoading"
          :datum="project.datum"
        ></project-box>
        <room-box :isLoading="room.isLoading" :datum="room.datum"></room-box>
        <park-box :isLoading="park.isLoading" :datum="park.datum"></park-box>
      </div>
      <div slot="content" class="yunwei_content">
        <yingshou-data
          :isLoading="financial.isLoading"
          :datum="financial.datum"
        ></yingshou-data>
        <div>
          <gongwei></gongwei>
          <fugai-lv
            :isLoading="structure.isLoading"
            :datum="structure.datum"
          ></fugai-lv>
        </div>
      </div>
    </template>
    <template v-else>
      <div slot="nav" class="project_yunwei_nav">
        <base-info
          :isLoading="org.isLoading"
          :datum="org.datum"
          :curOrg="curOrg"
        ></base-info>
      </div>
      <div slot="content" class="project_yunwei_content">
        <div class="_left">
          <yingshou-data
            :isLoading="financial.isLoading"
            :datum="financial.datum"
          ></yingshou-data>
          <!-- <fugai-lv
            :isLoading="structure.isLoading"
            :datum="structure.datum"
          ></fugai-lv> -->
        </div>
        <div class="_right">
          <room-box
            :isLoading="room.isLoading"
            :datum="room.datum"
            :curOrg="curOrg"
          ></room-box>
          <park-box
            :isLoading="park.isLoading"
            :datum="park.datum"
            :curOrg="curOrg"
          ></park-box>
          <gongwei></gongwei>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import operationApi from "@/api/operation";
import ProjectBox from "./components/projectBox/ProjectBox";
import YingshouData from "./components/yingshouData/YingshouData";
import FugaiLv from "./components/fugaiLv/FugaiLv";
import Gongwei from "./components/gongwei/Gongwei";
import RoomBox from "./components/roomBox/RoomBox";
import ParkBox from "./components/parkBox/ParkBox";
import BaseInfo from "@/components/common/baseInfo/BaseInfo";
import orgApi from "@/api/org/org";

import { mapState } from "vuex";

export default {
  name: "Index",
  components: {
    ProjectBox,
    YingshouData,
    FugaiLv,
    RoomBox,
    ParkBox,
    BaseInfo,
    Gongwei,
  },
  data() {
    return {
      project: { isLoading: false, datum: null },
      room: { isLoading: false, datum: null },
      park: { isLoading: false, datum: null },
      financial: { isLoading: false, datum: null },
      structure: { isLoading: false, datum: null },
      org: { isLoading: false, datum: null },
      curRoute: { name: "运营", url: "yunying" },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        // console.log("运营模块的vuex发生变化");
        this.getSummaryOfProject(); // 项目概况
        this.getSummaryOfRoom(); // 房间概况
        this.getSummaryOfPark(); // 车位概况
        this.getSummaryOfFinancial(); // 营收数据
        this.getDetailOfOrg();
        this.getDetailOfOrg();
        if (this.curOrg && this.curOrg.type != 1) {
          this.getSummaryOfStructure(); //覆盖率
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        console.log("主页中的state", state);
        return state.curOrg ? state.curOrg : { path: [], id: 1 };
      },
    }),
  },
  // mounted() {
  //   console.log(this.$route.query);
  //   this.getSummaryOfProject(); // 项目概况
  //   this.getSummaryOfRoom(); // 房间概况
  //   this.getSummaryOfPark(); // 车位概况
  //   this.getSummaryOfFinancial(); // 营收数据
  //   this.getDetailOfOrg(); // 项目详情
  // },
  methods: {
    getDetailOfOrg() {
      this.org.isLoading = true;
      orgApi.detailOfOrg(this.curOrg.id).then(
        (res) => {
          this.org.isLoading = false;
          this.org.datum = res.data.datum;
        },
        (err) => {
          this.org.isLoading = false;
        }
      );
    },
    getSummaryOfStructure() {
      this.structure.isLoading = true;
      operationApi.summaryOfStructure({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.structure.isLoading = false;
          this.structure.datum = res.data.datum;
        },
        (err) => {
          this.structure.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfFinancial() {
      this.financial.isLoading = true;
      operationApi.summaryOfFinancial({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.financial.isLoading = false;
          this.financial.datum = res.data.datum;
        },
        (err) => {
          this.financial.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfProject() {
      this.project.isLoading = true;
      operationApi.summaryOfProject({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.project.isLoading = false;
          this.project.datum = res.data.datum;
        },
        (err) => {
          this.project.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfRoom() {
      this.room.isLoading = true;
      operationApi.summaryOfRoom({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.room.isLoading = false;
          this.room.datum = res.data.datum;
        },
        (err) => {
          this.room.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfPark() {
      this.park.isLoading = true;
      operationApi.summaryOfPark({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.park.isLoading = false;
          this.park.datum = res.data.datum;
        },
        (err) => {
          this.park.isLoading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
