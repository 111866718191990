export default {
    FORCE_UPDATEUSER: 'forceUpdateUsr',
    USER_UPDATED: 'currentUserUpdated',

    NOTIFYDLG: '_evt_notify_dlg',
    CONFIRMDLG: '_evt_confirm_dlg',
    LOGOUT: '_evt_logout',
    NEWPAGE: '_evt_newpage',
    BROADCAST: '_evt_broadcast',

    PAGEPARAM: '_evt_page_param',
    SETPAGECB: '_evt_set_page_callback',
    SENDPAGEPARAM: '_evt_page_param'
};
