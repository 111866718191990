/* eslint-disable */
import Vue from 'vue';

Vue.filter('consumeTime', function(val) {
    const v = parseInt(val);
    if(isNaN(v))
        return '';

    const durDay =
        Math.floor(v / 86400) == 0
            ? ""
            : Math.floor(v / 86400) + "天";
    const durHour =
        Math.floor((v % 86400) / 3600) == 0
            ? ""
            : Math.floor((v % 86400) / 3600) + "小时";
    const durMin =
        Math.floor(((v % 86400) % 3600) / 60) == 0
            ? ""
            : Math.floor(((v % 86400) % 3600) / 60) + "分";
    const durSec =
        Math.floor((((v % 86400) % 3600) % 60) / 60) == 0
            ? ""
            : Math.floor((((v % 86400) % 3600) % 60) / 60) + "秒";
    return durDay + durHour + durMin + durSec;
});
Vue.filter('round', function(val,num) {
    if(!num)
        num = 2;
    let v = Number(val);
    if(isNaN(v))
        v = 0;
    return v.round(num);
});
// 千分号格式化过滤器
Vue.filter('decimalSeparator', function(input) {
    return input.toDecimalSeparatedString();
});
Vue.filter('RMB', function(input, prefix) {
    let v = Number(input);
    if(isNaN(v))
        v = 0;
    if(prefix === undefined)
        prefix = 'ⓧ';
    return prefix + v.toCNCurrency();
});
Vue.filter('cnDate', function(input, fmt) {
    if(!input)
        return '';
    if(fmt === undefined)
        fmt = 'yyyy年MM月dd日';
    return new Date(Date.parse(input.replace(/-/g, '/'))).format(fmt);
});
Vue.filter('dtFmt', function(input, fmt) {
    if(!input)
        return '';
    if(fmt === undefined)
        fmt = Date.DTONLY;
    const d = input.toDate();
    if(isNaN(d.getTime()))
        return '';
    return d.format(fmt);
});
Vue.filter('cr2br', function(input) {
    return input.replace(/\n/g, '<br />');
});