<template>
  <div class="yingshouqiankuan_container">
    <div class="work_box">
      <div class="title">
        <span>营收欠款</span>
        <router-link
          :to="{
            name: 'yingshouqiankuan',
            query: $route.query,
          }"
          >前往 >></router-link
        >
      </div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="data_container" v-if="!isLoading && datum">
          <div class="_left">
            <div class="txt">
              <span>总欠款</span>
              <div>
                <h1 class="yw">
                  {{ (datum.total["total"].total / 10000).toFixed(2) }}
                </h1>
                <span>万元</span>
              </div>
            </div>
          </div>
          <div class="_center">
            <pie-echarts
              :chartData="chartData"
              id="yingshou_qiankuan"
              :className="'pie__chart'"
            ></pie-echarts>
          </div>
          <div class="_right">
            <div v-for="(value, key) in seriesItem" :key="key">
              <div
                class="dot"
                :style="{ 'background-color': value.bgColor }"
              ></div>
              <span>{{ value.name }}：{{ value.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "YingShouqiankuanChart",
  props: ["isLoading", "datum", "curOrg"],
  watch: {
    datum(data) {
      if (data) {
        this.formatChart();
      }
    },
  },
  data() {
    return {
      colorList: [
        "#0089f8",
        "#9bf8fe",
        "#1baff9",
        "#0073ed",
        "#1e1988",
        "#3f5b9b",
        "#B17CFF",
        "#2576ac",
        "#79a3bd",
      ],
      seriesItem: {
        old: { name: "往年旧欠", value: 0, bgColor: "#0089f8" },
        current: { name: "本年欠收", value: 0, bgColor: "#9bf8fe" },
      },
      total: {},
      chartData: {
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        radiusOfSeries: "80%",
      },
    };
  },
  methods: {
    formatChart() {
      this.chartData.dataOfSeries = [];
      for (let key in this.seriesItem) {
        this.seriesItem[key].value = (
          this.datum.total[key].total / 10000
        ).toFixed(2);
        this.chartData.dataOfSeries.push(this.seriesItem[key]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
