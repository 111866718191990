<template>
  <div class="gongwei_container">
    <div class="work_box">
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="item">
          <h5>公维数据</h5>
          <div class="chart">
            <pie-echarts
              v-if="!isLoading"
              :chartData="gongwei_chartData"
              id="gongwei"
              :className="'pie__chart'"
            ></pie-echarts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Gongwei",
  props: ["datum", "isLoading"],
  data() {
    return {
      gongwei_chartData: {
        dataOfSeries: [
          { name: "已用", value: 100 },
          { name: "余额", value: 500 },
        ],
        legend: {
          orient: "horizontal",
          data: ['已用','余额'],
          bottom: 0,
          textStyle: {
            color: "#fff",
          },
        },
        nameOfSeries: "公维数据",
        posOfChart: ["45%", "50%"],
        radiusOfSeries: "50%",
      },
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatData();
      }
    },
  },
  methods: {
    formatData() {},
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>