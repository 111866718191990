<template>
  <div class="bennian_qianshou_container">
    <div class="work_box">
      <div class="title">社区增值服务收款对比</div>
      <div class="content">收缴率</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "JichuDuibi",
  props: ["isLoading"],
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
