<template>
  <div class="rank_container">
    <div class="gxl work_box" v-show="curOrg && curOrg.path == 1">
      <div class="title">贡献率排行</div>
      <div
        class="content"
        v-loading="isLoadingOfIncome"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="title">
          <h5>公司名称</h5>
          <h5>收入额(万) / 收缴率</h5>
        </div>
        <div id="rank_scroll_div">
          <div id="rank_scroll_begin">
            <div class="company" v-for="item in companys" :key="item.id">
              <span>{{ item.name }}</span>
              <span
                >{{ (item.year.total / 10000).toFixed(2) }} /
                {{ item.year.percent }}%</span
              >
            </div>
          </div>
          <div id="rank_scroll_end"></div>
        </div>
      </div>
    </div>
    <div
      class="sjl work_box"
      :style="{ width: curOrg && curOrg.path != 1 ? '100%' : '49.5%' }"
    >
      <div class="title">
        <span>物业收缴率排行</span>
        <router-link :to="{ name: 'xianjinshoujiaolv', query: $route.query }"
          >前往 >></router-link
        >
      </div>
      <div
        class="content"
        v-loading="isLoadingOfCurrentBillRatio"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="huan_sjv">
          <h6 class="text-center col-blue">当年整体收缴率</h6>
          <huan-echarts
            v-if="!isLoadingOfCurrentBillRatio"
            :chartData="chartDataOfCurrentBillRatio"
            :className="'huan_chart'"
            id="old_sjl"
          ></huan-echarts>
        </div>
        <div class="company_sjl">
          <hor-bar-echarts
            v-if="!isLoadingOfCurrentBillRatio"
            :chartData="chartDataOfCompany"
            :className="'hor_bar_chart'"
            id="company_sjl"
          ></hor-bar-echarts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rank",
  props: [
    "datumOfCurrentBillRatio",
    "datumOfIncome",
    "isLoadingOfCurrentBillRatio",
    "isLoadingOfIncome",
    "curOrg",
  ],
  watch: {
    curOrg(data) {
      // clearInterval(this.rankMyMar);
    },
    datumOfCurrentBillRatio(data) {
      if (data && data.org_list) {
        // this.formatter(data.org_list);
        this.formatCurrentBillRatio();
        this.formatCompanySjl();
      }
    },
    datumOfIncome(data) {
      if (data && data.org_list) {
        // 不是集团（顶级组织的情况下不加载排行率）
        // if (this.curOrg.path != 1) return;
        this.formatCompanyGxl();
      }
    },
  },
  destroyed() {
    clearInterval(this.rankMyMar);
    this.rankMyMar = null;
  },
  mounted() {
    const scroll_div = document.getElementById("rank_scroll_div");
    scroll_div.addEventListener("mouseenter", () => {
      clearInterval(this.rankMyMar); //鼠标移入清除定时器
    });
    scroll_div.addEventListener("mouseleave", () => {
      this.rankMyMar = setInterval(this.Marquee, this.speed);
    });
  },
  data() {
    return {
      rankMyMar: null,
      speed: 50,
      chartDataOfCurrentBillRatio: {
        nameOfSeries: "当年整体收缴率",
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        emphasisOfSeries: {},
        labelOfSeries: {
          normal: {
            show: false,
            fontWeight: "bold",
            fontSize: "20",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
      companyObj: {
        sjl: "收缴率",
        qjl: "欠缴率",
      },
      chartDataOfCompany: {
        series: [],
        // dataOfLegend: [],
        dataOfYaxis: [],
        grid: {
          left: "1%",
          right: "5%",
          bottom: "0%",
          top: "5%",
          containLabel: true,
        },
      },
      chartDataOfcurrent: {},
      companys: [],
    };
  },
  methods: {
    Marquee() {
      let scroll_begin = document.getElementById("rank_scroll_begin");
      let scroll_end = document.getElementById("rank_scroll_end");
      let scroll_div = document.getElementById("rank_scroll_div");
      if (scroll_end.offsetHeight - scroll_div.scrollTop <= 0) {
        scroll_div.scrollTop -= scroll_begin.offsetHeight;
        scroll_end.innerHTML = scroll_begin.innerHTML;
      } else {
        scroll_div.scrollTop++;
      }
    },
    //文字横向滚动
    ScrollImgTop() {
      if (this.rankMyMar) {
        clearInterval(this.rankMyMar);
        this.rankMyMar = null;
      }
      this.rankMyMar = setInterval(this.Marquee, this.speed);
    },
    formatCompanyGxl() {
      this.companys = [];
      // 从小到大排序
      let orgList = Object.entries(this.datumOfIncome.org_list).sort((a, b) => {
        return a[1].year.total - b[1].year.total;
      });
      orgList.forEach((item) => {
        this.companys.push(item[1]);
      });
      this.$nextTick(() => {
        if (this.curOrg && this.curOrg.path == 1) {
          this.ScrollImgTop();
        }
      });
    },
    formatCompanySjl() {
      this.chartDataOfCompany.series = [
        {
          name: "收缴率",
          type: "bar",
          stack: "率",
          label: {
            show: false,
            position: "center",
          },
          data: [],
        },
        {
          name: "欠缴率",
          type: "bar",
          stack: "率",
          label: {
            show: false,
            position: "center",
          },
          data: [],
        },
      ];
      if (this.datumOfCurrentBillRatio.org_list) {
        let orgList = this.datumOfCurrentBillRatio.org_list;
        // 从小到大排序
        orgList = Object.entries(orgList).sort((a, b) => {
          return a[1].percent - b[1].percent;
        });
        this.chartDataOfCompany.dataOfYaxis = [];
        orgList.forEach((itm) => {
          if (!isNaN(Number(itm[1].percent)) && itm[1].percent != 0) {
            this.chartDataOfCompany.dataOfYaxis.push(itm[1].name);
            this.chartDataOfCompany.series[0].data.push(Number(itm[1].percent));
            this.chartDataOfCompany.series[1].data.push(
              100 - Number(itm[1].percent)
            );
          }
        });
      }
    },
    formatCurrentBillRatio() {
      this.chartDataOfCurrentBillRatio.dataOfSeries = [
        {
          name: "当年已收",
          value: Number(this.datumOfCurrentBillRatio.total.pay_amount).toFixed(
            2
          ),
          label: { show: true },
        },
        {
          name: "当年未收",
          value: (
            Number(this.datumOfCurrentBillRatio.total.total_amount) -
            Number(this.datumOfCurrentBillRatio.total.pay_amount)
          ).toFixed(2),
          label: { show: false },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
