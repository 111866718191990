/**
 * Created by lijian on 2017/2/7.
 */

window._Utils = (function() {
    function isOperator(value) {
        return "+-*/()".indexOf(value) > -1;
    }

    function getPrioraty(value) {
        switch (value) {
        case '+':
        case '-':
            return 1;
        case '*':
        case '/':
            return 2;
        default:
            return 0;
        }
    }

    //判断加减乘除的优先级
    function prioraty(o1, o2) {
        return getPrioraty(o1) <= getPrioraty(o2);
    }
    function dal2Rpn(exp) {
        //输入栈
        let inputStack = [];
        //输出栈
        let outputStack = [];
        //输出队列
        let outputQueue = [];
        let cur;

        let n = "";
        for (let i = 0, len = exp.length; i < len; i++) {
            cur = exp[i];
            if(cur === ' ')
                continue;
            switch(cur) {
            case '+':
            case '-':
            case '*':
            case '/':
            case '(':
            case ')':
                if(n)
                    inputStack.push(n);
                n = "";
                inputStack.push(cur);
                break;
            default:
                n += cur;
                break;
            }
//			if (cur != ' ') {
//				inputStack.push(cur); //+-*/() 数字，逐个添加到末尾
//			}
        }
        if(n)
            inputStack.push(n);

        //处理字符和数字
        while (inputStack.length > 0) {
            //shift 顶部取得一项后移除，unshift 顶部推入
            cur = inputStack.shift();

            //如果是符号 -->  + - * / ( )
            if (isOperator(cur)) {
                if (cur === '(') {
                    //push 从尾部推入一项
                    outputStack.push(cur);
                } else if (cur === ')') {
                    //pop 从尾部取得一项，之后移出
                    let po = outputStack.pop();
                    while (po !== '(' && outputStack.length > 0) {
                        outputQueue.push(po);
                        po = outputStack.pop();
                    }
                    if (po !== '(') {
                        throw "错误：没有匹配";
                    }
                } else { //符号时，处理 + - * /
                    while (outputStack.length > 0 && prioraty(cur, outputStack[outputStack.length - 1])) {
                        outputQueue.push(outputStack.pop());
                    }
                    outputStack.push(cur);
                }
            } else { //是数字的时候，推入数字
                outputQueue.push(Number(cur));
            }
        }

        if (outputStack.length > 0) {
            if((outputStack[outputStack.length - 1] === ')') || (outputStack[outputStack.length - 1] === '(')) {
                throw "错误：没有匹配";
            }
            while (outputStack.length > 0) {
                outputQueue.push(outputStack.pop());
            }
        }
        return evalRpn(outputQueue);
    }
    function getResult(first, second, operator) {
        let result = 0;
        switch (operator) {
            case '+':
                result = first + second;
                break;
            case '-':
                result = first - second;
                break;
            case '*':
                result = first * second;
                break;
            case '/':
                result = first / second;
                break;
            default:
                return 0;
        }

        //浮点数的小数位超过两位时，只保留两位小数点
        function formatFloat(f, digit) {
            //pow(10,n) 为 10 的 n 次方
            let m = Math.pow(10, digit);
            return parseInt(f * m, 10) / m;
        }
        return (formatFloat(result, 2));
    }
    let evalRpn = function(queue) {
        let outputStack = [];
        while (queue.length > 0) {
            let cur = queue.shift();

            if (!isOperator(cur)) {
                outputStack.push(cur);
            } else {
                //如果输出堆栈长度小于 2
                if (outputStack.length < 2) {
                    throw "无效堆栈长度";
                }
                let second = outputStack.pop();
                let first = outputStack.pop();

                outputStack.push(getResult(first, second, cur));
            }
        }

        if (outputStack.length !== 1) {
            throw "不正确的运算";
        } else {
            return outputStack[0];
        }
    };
    let doTblSpan = function(tbl, firstRow, rowSpan, excludeCols) {
        let j, jj;
        let cols = tbl.rows[0].cells.length;
        for(j = firstRow + 1; j < firstRow+rowSpan; j++) {
            if(tbl.rows[j].cells.length !== cols)
                return;
            for(jj = cols-1; jj >= 0; jj--) {
                if(excludeCols === jj || (excludeCols.indexOf && (excludeCols.indexOf(jj) !== -1)))
                    continue;
                tbl.rows[j].deleteCell(jj);
            }
        }
        for(jj = 0; jj < cols; jj++) {
            if(excludeCols === jj || (excludeCols.indexOf && (excludeCols.indexOf(jj) !== -1)))
                continue;
            if(!tbl.rows[firstRow].cells[jj]) {
                continue;
            }
            tbl.rows[firstRow].cells[jj].rowSpan = rowSpan;
        }
    };
    let _ = {
        runExpression: function(exp, datas) {
            exp = exp.replace(/\s/g, '');
            if(!exp)
                return 0;
            function IF(c,a,b) {return c?a:b;}
            function ROUND(a,b) {
                return Number(a.round(b));
            }
            function ROUNDDOWN(a,b) {
                return Number(a.roundDown(b));
            }
            if(datas){
                for(let key in datas) {
                    try {
                        eval('let ' + key + ' = ' + datas[key] + ';');
                    } catch(e) {
                        return false;
                    }
                }
            }
            try {
                return eval(exp);
            } catch(e) {
                // return false;
            }
            return false;
        },
        checkExpression: function(exp, datas) {
            return _.runExpression(exp, datas) !== false;
        },
        calculate: function(exp) {
            return dal2Rpn(exp);
        },
        padLeft: function(str, n, c) {
            let len = ('' + str).length;
            while(len < n) {
                str = c + str;
                len += c.length;
            }
            return str;
        },
        parseNumber: function(n) {
            n = '' + n;
            if(n === '')
                return [0, 'n', 0, ''];
            var num = n.match(/^([0-9]+)$/);
            if(num)
                return [parseInt(num[1]), 'n', (''+num[1]).length, ''];
            num = n.match(/^([A-Z])$/);
            if(num)
                return [n.charCodeAt(0) - 64, 'c', 1, ''];
            num = n.match(/^([A-Z])([0-9]+)$/);
            if(num) {
                return [parseInt(num[2]), 'n', num[2].length, num[1]];
            }
            num = n.match(/^([0-9A-Z]+)([A-Z])$/);
            if(num) {
                return [num[2].charCodeAt(0) - 64, 'c', 1, num[1]];
            }
            return false;
        },
        calcEndSequence: function(start, count)
        {
            count = parseInt(count);
            if(isNaN(count) || count <= 0)
                return '';
            if(!start)
                start = '01';
            var n = _.parseNumber(start);
            if(n === false)
                return '';
            var newVal = n[0] + count - 1;
            if(n[1] == 'c')
                return n[3] + String.fromCharCode(newVal + 64);
            else
                return '' + n[3] + _.padLeft(newVal, n[2], '0');
        },
        autoSpan: function(tbl, excludeCols) {
            let lastRow = 0;
            let lastId = 0;
            let rowSpan = 1;
            let cols = tbl.rows[0].cells.length;
            let j, jj;
            for(let row = 1, l = tbl.rows.length; row < l; row++) {
                let c = tbl.rows[row].cells;
                if(c[0].innerHTML == lastId)
                    rowSpan++;
                else {
                    if(lastId) {
                        doTblSpan(tbl, lastRow, rowSpan, excludeCols);
                    }
                    lastRow = row;
                    lastId = c[0].innerHTML;
                    rowSpan = 1;
                }
            }
            if(lastId) {
                doTblSpan(tbl, lastRow, rowSpan, excludeCols);
            }
        },
        randomColor: function() {
            return '#'+(function(cr) {
                    return (cr += ('0123456789ABCDEF'[Math.floor(Math.random()*16)])) && ((cr.length == 6) ? cr: arguments.callee(cr));
                })('');
        },
        checkError: function(e) {
            if(!e)
                return false;
            if(typeof e != 'object')
                return !!e;
            for(let k in e) {
                if(typeof e[k] != 'boolean')
                    continue;
                if(e[k])
                    return true;
            }
            return false;
        },

        /**
         *
         * @param amount 含税金额
         * @param tax 税率（百分比）
         */
        calcTax: function(amount, tax) {
            amount = Number(amount);
            if(isNaN(amount))
                return 0;
            tax = Number(tax);
            if(isNaN(tax))
                return amount;
            return (amount * tax) / (100 + tax);
        }
    };
    return _;
})();
(function () {
    Array.prototype.ltrim = function() {
        if(this.length) {
            for(let i = 0, l = this.length; (i < l) && !this[i]; i++);
            if(i > 0)
                this.splice(0, i);
        }
        return this;
    };
    Array.prototype.rtrim = function() {
        if(this.length) {
            for(let i = this.length - 1; (i >= 0) && !this[i]; i--);
            if(i < this.length - 1)
                this.splice(i + 1);
        }
        return this;
    };
    Array.prototype.trim = function() {
        return this.ltrim().rtrim();
    };
    Array.prototype.search = function(val, fieldName) {
        let l = this.length;
        while(l--) {
            if((fieldName && this[l][fieldName] == val) ||
                (!fieldName && this[l] == val))
                return {index: l, value: this[l]};
        }
        return {index: -1, value: null};
    };
    Array.prototype.proceedRowSpan = function(fieldName) {
        let i, l;
        let last = null;
        for(i = 0, l = this.length; i < l; i++) {
            if(!last) {
                last = this[i];
                last._rowSpan = 1;
            }
            else {
                if(last[fieldName] == this[i][fieldName]) {
                    this[i]._rowSpan = 0;
                    last._rowSpan++;
                }
                else {
                    last = this[i];
                    last._rowSpan = 1;
                }
            }
        }
        return this;
    };
    /**
     * 在指定位置插入元素,第一个参数代表插入位置
     * @returns {Array}
     */
    Array.prototype.insert = function() {
        if(arguments.length > 1) {
            let idx = parseInt(arguments[0]);
            if(!isNaN(idx)) {
                let a = this.splice(idx);
                for(let i = 1; i < arguments.length; i++)
                    this.push(arguments[i]);
                this.push.apply(this, a);
            }
        }
        return this;
    };
    Object.defineProperty(Array.prototype, 'getColumn', {
      configurable: true,
      enumerable: false,
      writable: false,
      value: function (columnKey, indexKey) {
        let ret = indexKey ? {} : [];
        let l = this.length;
        let v, k;
        while (l--) {
          try {
            v = columnKey ? this[l][columnKey] : this[l];
          } catch (e) {
            v = undefined;
          }
          try {
            k = indexKey ? this[l][indexKey] : ret.length;
          } catch (e) {
            continue;
          }
          if(k !== undefined)
            ret[k] = v;
        }
        return ret;
      }
    });

    String.prototype.startsWith = function(str) {
        return this.slice(0, str.length) == str;
    };
    String.prototype.endsWith = function(str) {
        return this.slice(-str.length) == str;
    };
    String.prototype.toTaxCode = function() {
        if(this.length != 15 && this.length != 18 && this.length != 20)
            return '';
        let str = this.toUpperCase();
        if(/^([0-9A-Z]){15,20}$/.test(str))
            return str;
        return '';
    };
    String.prototype.toDate = function() {
        return new Date(Date.parse(this.replace(/-/g, "/")));
    };
    String.prototype.toFixed = function(n) {
        let nn = Number(this);
        if(isNaN(nn))
            nn = 0;
        return nn.toFixed(n);
    };
    String.prototype.round = function(n) {
        let nn = Number(this);
        if(isNaN(nn))
            return 0;
        return nn.round(n);
    };
    String.prototype.roundDown = function(n) {
        let nn = Number(n);
        if(isNaN(nn))
            nn = 0;
        return nn.roundDown(n);
    };
    String.prototype.urlencode = function() {
        return encodeURIComponent(this).replace(/!/g, '%21').replace(/'/g, '%27')
            .replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
    };
    String.prototype.leftPadding = function(n,ch) {
        return Array(n>this.length?(n-this.length+1):0).join(ch)+this;
    };
    String.urlencode = function(obj) {
        if(typeof obj == 'object') {
            let q = [];
            for(let i in obj) {
                if(typeof obj[i] == 'number' ||  typeof obj[i] == 'string')
                    q.push(encodeURI(i) + '=' + encodeURI(obj[i]));
            }
            return q.length > 0 ? q.join('&') : '';
        }
        else if(obj)
            return obj.toString();
        return '';
    };
    Object.defineProperty(String.prototype, 'toDecimalSeparatedString', {
        configurable: true,
        enumerable: false,
        writable: true,
        value: function() {
            return Number(this).toDecimalSeparatedString();
        }
    });

    Date.DT1 = 'yyyy-MM-dd hh:mm:ss';
    Date.DTONLY = 'yyyy-MM-dd';
    Date.DTONLY_CN = 'yyyy年MM月dd日';
    Date.prototype.getWeekNumber = function() {
        let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
        let dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
    };
    Date.prototype.format = function(format) {
        let o = {
            "M+" : this.getMonth() + 1,
            "d+" : this.getDate(),
            "h+" : this.getHours(),
            "m+" : this.getMinutes(),
            "s+" : this.getSeconds(),
            "q+" : Math.floor((this.getMonth() + 3) / 3),
            "S"  : this.getMilliseconds()
        };
        if(/(y+)/.test(format))
            format = format.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
        for(let k in o) {
            if(new RegExp("(" + k + ")").test(format))
                format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr((""+o[k]).length));
        }
        return format;
    };
    Date.prototype.firstDayOfWeek = function() {
        let dt = new Date(this.getTime());
        if(dt.getDay() === 0)
            dt.setDate(dt.getDate() - 6);
        else
            dt.setDate(dt.getDate() + 1 - dt.getDay());
        return dt;
    };
    Date.prototype.lastDayOfWeek = function() {
        let dt = new Date(this.getTime());
        if(dt.getDay() !== 0)
            dt.setDate(dt.getDate() + 7 - dt.getDay());
        return dt;
    };
    Date.prototype.firstDayOfMonth = function() {
        let dt = new Date(this.getTime());
        dt.setDate(1);
        return dt;
    };
    Date.prototype.lastDayOfMonth = function() {
        let dt = new Date(this.getTime());
        dt.setDate(1);
        dt.setMonth(dt.getMonth() + 1);
        dt.setDate(0);
        return dt;
    };
    Date.prototype.add = function(day, month, year) {
        if(typeof day === 'number')
            this.setDate(this.getDate() + day);
        if(typeof month === 'number')
            this.setMonth(this.getMonth() + month);
        if(typeof year === 'number')
            this.setFullYear(this.getFullYear() + year);
        return this;
    };
    /**
     *
     * @param dt 可以接受毫秒时间戳、字符串、Date对象
     * @return {number}
     */
    Date.prototype.compareTo = function(dt) {
        if(typeof dt === 'string')
            dt = dt.toDate();
        else if(!(dt instanceof Date))
            dt = new Date(dt);

        if(isNaN(dt.getTime()))
            return isNaN(this.getTime()) ? 0 : 1;
        else if(isNaN(this.getTime()))
            return -1;
        else
            return this.getTime() - dt.getTime();
    };
    Date.prototype.ago2 = function(dt) {
        // new Date("month dd,yyyy hh:mm:ss")
        // new Date("month dd,yyyy");
        // new Date(yyyy,month,dd,hh,mm,ss)
        // new Date(yy,month,dd)
        // new Date(timestamp_ms)
        // yyyy-MM-dd hh:mm:ss
        if(!dt)
            return "";
        let past = new Date(Date.parse(dt.replace(/-/g, "/"))),
            isFuture = this.getTime() < past.getTime(),
            diffValue = isFuture ? (past.getTime() - this.getTime()) : (this.getTime() - past.getTime()),
            result = '',
            second = 1000,
            minute = 1000 * 60,
            hour = minute * 60,
            day = hour * 24,
            halfmonth = day * 15,
            month = day * 30,
            year = month * 12,
            _year = diffValue / year,
            _month = diffValue / month,
            _week = diffValue / (7 * day),
            _ttday = diffValue / (3 * day),
            _tday = diffValue / (2 * day),
            _day = diffValue / day,
            _hour = diffValue / hour,
            _min = diffValue / minute,
            _sec = diffValue / second;
        if(_year >= 1)
            result = past.format("yyyy年MM月dd日");
        else if(_month >= 1)
            result = past.format("MM月dd日 hh:mm");
        else if(_tday >= 1)
            result = past.format("MM月dd日 hh:mm");
        else if(_day >= 1)
            result = past.format("昨天 hh:mm");
        else if(_hour >= 1)
            result = past.format("hh:mm");
        else if(_min >= 1)
            result = parseInt(_min) + "分钟前";
        else if(_sec >= 1)
            result = parseInt(_sec) + "秒前";
        else
            result = "刚刚";
        return result;
    };
    Date.prototype.ago = function(dt) {
        if(!dt)
            return "";
        let now = this;
        let past = new Date(Date.parse(dt.replace(/-/g, "/")));
        let future = false;
        if(past > this) {
            future = past;
            past = now;
            now = future;
            future = true;
        }

        if(now.getFullYear() != past.getFullYear())
            return past.format("yyyy年MM月dd日");
        if(now.getMonth() != past.getMonth())
            return past.format("MM月dd日 hh:mm");
        if(now.getDate() != past.getDate()) {
            if(now.getDate() - 1 == past.getDate())
                return past.format(future ? "明天 hh:mm" : "昨天 hh:mm");
            else
                return past.format("MM月dd日 hh:mm");
        }
        if(now.getHours() != past.getHours())
            return past.format("hh:mm");
        if(now.getMinutes() != past.getMinutes())
            return parseInt(now.getMinutes() - past.getMinutes()) + (future ? '分钟后' : '分钟前');
        if(now.getSeconds() != past.getSeconds())
            return parseInt(now.getSeconds() - past.getSeconds()) + (future ? '秒后' : '秒前');
        return "刚刚";
    };
    Date.ago = function(dt) {
        return new Date().ago(dt);
    };
    Date.ago2 = function(dt) {
        return new Date().ago2(dt);
    };
    Date.numOfMonthBetween = function(d1, d2) {
        let beginDt = d1.toDate();
        let endDt = d2.toDate();
        if(beginDt.getTime() > endDt.getTime()) {
            let d = beginDt;
            beginDt = endDt;
            endDt = d;
        }
        let period = endDt.getFullYear() * 12 + endDt.getMonth() - beginDt.getFullYear() * 12 - beginDt.getMonth();
        if(beginDt.getDate() <= endDt.getDate())
            period++;
        return period;
    };

    Number.prototype.round = function(n) {
        if(n < 0)
            n = 0;
        if(isNaN(this))
            return (0).toFixed(n);
        let p = Math.pow(10, n);
        return (Math.round(this * p) / p).toFixed(n);
    };
    Number.prototype.roundDown = function(n) {
        if(n < 0)
            n = 0;
        if(isNaN(this))
            return (0).toFixed(n);
        let p = Math.pow(10, n);
        return (parseInt(this * p) / p).toFixed(n);
    };
    Number.prototype.leftPadding = function(n) {
        return (''+this).leftPadding(n,'0');
    };
    Number.prototype.toCNCurrency = function () {
        // Constants:
        let MAXIMUM_NUMBER = 99999999999.99;
        // Predefine the radix characters and currency symbols for output:
        let CN_ZERO = "零";
        let CN_ONE = "壹";
        let CN_TWO = "贰";
        let CN_THREE = "叁";
        let CN_FOUR = "肆";
        let CN_FIVE = "伍";
        let CN_SIX = "陆";
        let CN_SEVEN = "柒";
        let CN_EIGHT = "捌";
        let CN_NINE = "玖";
        let CN_TEN = "拾";
        let CN_HUNDRED = "佰";
        let CN_THOUSAND = "仟";
        let CN_TEN_THOUSAND = "万";
        let CN_HUNDRED_MILLION = "亿";
        let CN_SYMBOL = "";
        let CN_DOLLAR = "元";
        let CN_TEN_CENT = "角";
        let CN_CENT = "分";
        let CN_INTEGER = "整";

        // Variables:
        let integral;    // Represent integral part of digit number.
        let decimal;    // Represent decimal part of digit number.
        let outputCharacters;    // The output result.
        let parts;
        let digits, radices, bigRadices, decimals;
        let zeroCount;
        let i, p, d;
        let quotient, modulus;

        // Validate input string:
        let currencyDigits = this.toFixed(2);
        if (isNaN(this) || currencyDigits == "") {
            // alert("不能为空 请输入数字金额!如：123.23");
            return "";
        }
        // if (currencyDigits.match(/[^,.\d]/) != null) {
        //     alert("输入字符串中的字符无效!");
        //     return "";
        // }
        // if ((currencyDigits).match(/^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/) == null) {
        //     alert("请输入正确的数字金额!");
        //     return "";
        // }

// Normalize the format of input digits:
        currencyDigits = currencyDigits.replace(/,/g, "");    // Remove comma delimiters.
        currencyDigits = currencyDigits.replace(/^0+/, "");    // Trim zeros at the beginning.
        // Assert the number is not greater than the maximum number.
        if (Number(currencyDigits) > MAXIMUM_NUMBER) {
            // alert("Too large a number to convert!");
            return "";
        }

// Process the coversion from currency digits to characters:
        // Separate integral and decimal parts before processing coversion:
        parts = currencyDigits.split(".");
        if (parts.length > 1) {
            integral = parts[0];
            decimal = parts[1];
            // Cut down redundant decimal digits that are after the second.
            decimal = decimal.substr(0, 2);
            if(decimal == '00')
                decimal = '';
        }
        else {
            integral = parts[0];
            decimal = "";
        }
        // Prepare the characters corresponding to the digits:
        digits = new Array(CN_ZERO, CN_ONE, CN_TWO, CN_THREE, CN_FOUR, CN_FIVE, CN_SIX, CN_SEVEN, CN_EIGHT, CN_NINE);
        radices = new Array("", CN_TEN, CN_HUNDRED, CN_THOUSAND);
        bigRadices = new Array("", CN_TEN_THOUSAND, CN_HUNDRED_MILLION);
        decimals = new Array(CN_TEN_CENT, CN_CENT);
        // Start processing:
        outputCharacters = "";
        // Process integral part if it is larger than 0:
        if (Number(integral) > 0) {
            zeroCount = 0;
            for (i = 0; i < integral.length; i++) {
                p = integral.length - i - 1;
                d = integral.substr(i, 1);
                quotient = p / 4;
                modulus = p % 4;
                if (d == "0") {
                    zeroCount++;
                }
                else {
                    if (zeroCount > 0)
                    {
                        outputCharacters += digits[0];
                    }
                    zeroCount = 0;
                    outputCharacters += digits[Number(d)] + radices[modulus];
                }
                if (modulus == 0 && zeroCount < 4) {
                    outputCharacters += bigRadices[quotient];
                }
            }
            outputCharacters += CN_DOLLAR;
        }
        // Process decimal part if there is:
        if (decimal != "") {
            for (i = 0; i < decimal.length; i++) {
                d = decimal.substr(i, 1);
                if (d != "0") {
                    outputCharacters += digits[Number(d)] + decimals[i];
                }
            }
        }
        // Confirm and return the final output string:
        if (outputCharacters == "") {
            outputCharacters = CN_ZERO + CN_DOLLAR;
        }
        if (decimal == "") {
            outputCharacters += CN_INTEGER;
        }
        outputCharacters = CN_SYMBOL + outputCharacters;
        return outputCharacters;
    };

    Object.defineProperty(Number.prototype, 'toDecimalSeparatedString', {
        configurable: true,
        enumerable: false,
        writable: true,
        value: function(ch) {
            if(isNaN(this))
                return '';
            /*
             正则表达式解析:/\B(?=(\d{3})+(?!\d))/g
             先把上面这个表达式的结构解析为如下形式：/x(?=y)/g。
             其中g代表全局匹配，x(?=y)匹配x当且仅当x后面是y，x代表\B，y代表(\d{3})+(?!\d)。

             \B匹配非单词边界，两边都是单词字符或者两边都是非单词字符，形如：\w\B\w或者\W\B\W。

             (\d{3})+(?!\d)又可以解析为：x(?!y)，匹配x当且仅当x后面不是y。其中x代表(\d{3})+，y代表\d。
             (\d{3})+匹配三个数字，出现次数>=1。
            */
            let parts = this.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, (ch === undefined) ? "," : ch);
            return parts.join('.');
        }
    });

    Object.safeNumber = function(obj) {
        const o = Number(obj);
        return isNaN(o) ? 0 : o;
    };

    // 增加一个不会被遍历到的属性
    Object.defineProperty(Object.prototype, 'getResponseMessage', {
        configurable: true,
        enumerable: false,
        writable: true,
        value: function(defaultMessage) {
            return (this && this.response && this.response.data && this.response.data.message) || defaultMessage || '';
        }
    });
    Object.defineProperty(Object.prototype, 'toKVString', {
        configurable: true,
        enumerable: false,
        writable: true,
        value: function(kvSeperator, seperator) {
            if(!kvSeperator)
                kvSeperator = '|';
            if(!seperator)
                seperator = ',';
            let rs = [];
            for(let k in this) {
                switch(typeof this[k]) {
                    case 'string':
                        rs.push(k+kvSeperator+this[k]);
                        break;
                    case 'number':
                        rs.push(k+kvSeperator+this[k].toString());
                        break;
                    case 'boolean':
                        rs.push(k+kvSeperator+(this[k]?'1':'0'));
                        break;
                }
            }
            return rs.join(seperator);
        }
    });
    Object.getResponseMessage = function(obj, defaultMessage) {
        return (obj && obj.response && obj.response.data && obj.response.data.message) || defaultMessage || '';
    };
})();

window.libaj = {
    init: function() {
        if (!window.context) {
            if (!window.location.origin) {
                window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
            }
            window.context = location.origin+"/V6.0";
        }
    },
    utils: function($window, $location) {
        let w = $window;
        let l = $location;
        let _export = {
            getBaseUri: function() {
                return window.location.origin;
            },
            getUriOf: function(uri) {
                if(uri.substr(0, 1) !== '/')
                    uri = '/' + uri;
                let path;
                let base = document.getElementsByTagName('base');
                if(base && base.length) {
                    path = base[0].href;
                    if(path.substr(-1) === '/')
                        return path + uri.substr(1);
                    else
                        return path + uri;
                } else {
                    path = window.location.pathname.split('/');
                    path.ltrim();
                    if (path.length <= 0)
                        path.push(uri);
                    else
                        path[path.length - 1] = uri;
                    return window.location.origin + '/' + path.join('/');
                }
            },
            gotoUri: function(uri) {
                window.location = _export.getUriOf(uri);
            },
            getPath: function() {
                return l ? l.path() : '';
            },
            checkPath: function(prefix) {
                if(l) {
                    let p = l.path();
                    return (prefix.indexOf(p) === 0) && (p.indexOf(prefix) === 0);
                }
                return false;
            },
            goBack: function() {
                if(w)
                    w.history.back();
            },
            gotoPath: function(path) {
                if(l)
                    l.path(path);
            },

            getPages: function(pages) {
                let r = [];
                let l = parseInt(pages);
                if(isNaN(l) || !l)
                    return [];
                for(let i = 0; i < l; i++)
                    r.push(i);
                return r;
            }
        };
        return _export;
    }
};
