<template>
  <div class="count_of_dev_container">
    <div class="work_box">
      <div class="title">设备数量</div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <template
          v-if="
            datum && datum.device_class_list && datum.device_class_list.length
          "
        >
          <pie-echarts
            v-if="!isLoading"
            :chartData="chartData"
            id="count_of_device"
            :className="'pie__chart'"
          ></pie-echarts>
        </template>
        <template v-else>
          <div class="text-center">暂无数据</div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CountOfDevice",
  props: ["datum", "isLoading"],
  data() {
    return {
      chartData: {
        dataOfSeries: [],
        posOfChart: ["45%", "50%"],
        radiusOfSeries: "50%",
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 台 ({d}%)",
        },
        labelOfSeries: {
          show: true,
        },
        // legend: {
        //   orient: "horizontal",
        //   data: [],
        //   bottom: 0,
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
      },
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatData();
      }
    },
  },
  methods: {
    formatData() {
      if (this.datum.device_class_list && this.datum.device_class_list.length) {
        this.datum.device_class_list.forEach((item) => {
          if (item.count != 0) {
            let obj = {
              name: item.name,
              value: item.count,
            };
            this.chartData.dataOfSeries.push(obj);
          }

          // this.chartData.legend.data.push(item.name);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
