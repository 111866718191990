import Events from './events';
import swal from 'sweetalert';

function hasParent() {
    return parent !== window;
}

function getHomeBus() {
    return parent.Bus;
}

let _ = {
    jumpToLogin(reason, extra) {
        parent.Bus.$emit(Events.LOGOUT, reason, extra);
    },
    newPage(id, param) {
        parent.Bus.$emit(Events.NEWPAGE, { id: id, param: param });
    },
    broadcast(ev, param) {
        parent.Bus.$emit(Events.BROADCAST, { ev: ev, param: param });
    },
    broadcastTo(id, ev, param) {
        parent.Bus.$emit(Events.BROADCAST, { id: id, ev: ev, param: param });
    },
    forceUpdateUser() {
        Bus.$emit(Events.FORCE_UPDATEUSER);
        if (hasParent())
            parent.Bus.$emit(Events.FORCE_UPDATEUSER);
    },

    notify: {
        showEx(colorName, text, timeout, placementFrom, placementAlign, animateEnter, animateExit) {
            let allowDismiss = true;
            let placement;
            let THEMES = {
                info: {
                    color: 'blue',
                    icon: 'ico-info'
                },
                success: {
                    color: 'green',
                    icon: 'ico-success'
                },
                warning: {
                    color: 'orange',
                    icon: 'ico-warning'
                },
                error: {
                    color: 'red',
                    icon: 'ico-error'
                },
                danger: {
                    color: 'red',
                    icon: 'ico-error'
                },
                question: {
                    color: 'yellow',
                    icon: 'ico-question'
                }
            };
            if (hasParent()) {
                parent.Bus.$emit(Events.NOTIFYDLG, {
                    colorName: colorName,
                    text: text,
                    timeout: timeout,
                    placementFrom: placementFrom,
                    placementAlign: placementAlign,
                    animateEnter: animateEnter,
                    animateExit: animateExit
                });
                return;
            }
            if (!timeout) timeout = 3000;
            if (!colorName) { colorName = 'info'; }
            if (!text) { text = ''; }
            if (!placementFrom) { placementFrom = 'top'; }
            if (!placementAlign) { placementAlign = 'center'; }
            if (!animateEnter) { animateEnter = 'animated fadeInDown'; }
            if (!animateExit) { animateExit = 'animated fadeOutUp'; }

            if (placementFrom)
                placement = placementFrom + placementAlign.substring(0, 1).toUpperCase() + placementAlign.substring(1);
            else
                placement = placementAlign;

            if (colorName.indexOf('alert-') == 0)
                colorName = colorName.substring(6);
            iziToast.show({
                color: THEMES[colorName].color,
                icon: THEMES[colorName].icon,
                id: null,
                message: text,
                theme: 'light', // dark
                close: allowDismiss,
                closeOnEscape: allowDismiss,
                closeOnClick: allowDismiss,
                position: placement, // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
                toastOnce: false,
                replaceToast: false,
                timeout: timeout,
                animateInside: true,
                drag: true,
                pauseOnHover: true,
                resetOnHover: false,
                progressBar: true,
                progressBarColor: '',
                progressBarEasing: 'linear',
                transitionIn: 'fadeInUp',
                transitionOut: 'fadeOut',
                transitionInMobile: 'fadeInUp',
                transitionOutMobile: 'fadeOutDown'
            });
        },
        success(text, timeout, placementFrom, placementAlign, animateEnter, animateExit) {
            _.notify.showEx('success', text, timeout, placementFrom, placementAlign, animateEnter, animateExit);
        },
        info(text, timeout, placementFrom, placementAlign, animateEnter, animateExit) {
            _.notify.showEx('info', text, timeout, placementFrom, placementAlign, animateEnter, animateExit);
        },
        warning(text, timeout, placementFrom, placementAlign, animateEnter, animateExit) {
            _.notify.showEx('warning', text, timeout, placementFrom, placementAlign, animateEnter, animateExit);
        },
        danger(text, timeout, placementFrom, placementAlign, animateEnter, animateExit) {
            _.notify.showEx('danger', text, timeout, placementFrom, placementAlign, animateEnter, animateExit);
        }
    },
    confirm: {
        showEx(text, title, type, cb, confirmText, cancelText, showCancel) {
            if (hasParent()) {
                parent.Bus.$emit(Events.CONFIRMDLG, {
                    text: text,
                    title: title,
                    type: type,
                    cb: cb,
                    confirmText: confirmText,
                    cancelText: cancelText,
                    showCancel: showCancel
                });
                return;
            }
            let showCancelButton = ((showCancel === undefined) ? true : showCancel);
            let content = document.createElement('div');
            content.id = 'id_' + Math.random();
            content.innerHTML = text;
            let d = {
                buttons: {
                    cancel: {
                        text: cancelText || '取消',
                        value: null,
                        visible: showCancelButton,
                        className: '',
                        closeModal: true
                    },
                    confirm: {
                        text: confirmText || '确定',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true
                    }
                },
                // showCancelButton: showCancelButton,
                // confirmButtonText: confirmText || '确定',
                content: content,
                title: title || '',
                dangerMode: type == 'error',
                icon: type
                    // html:true
            };
            //                title && (d.title = title);
            //             if(type) d.type = type;
            //             if(showCancelButton)
            //                 d.cancelButtonText = cancelText || '取消';
            //             else
            //                 d.cancelButtonText = null;
            swal(d
                /*, function(isConfirm) {
                                if(typeof cb === 'function') {
                                    // setTimeout(function() {
                                        cb(isConfirm);
                                    // });
                                }
                            }*/
            ).then(isConfirm => {
                if (typeof cb === 'function')
                    cb(isConfirm);
            });
        },
        success(text, title, cb, confirmText, cancelText, showCancel) {
            _.confirm.showEx(text, title, 'success', cb, confirmText, cancelText, showCancel);
        },
        info(text, title, cb, confirmText, cancelText, showCancel) {
            _.confirm.showEx(text, title, 'info', cb, confirmText, cancelText, showCancel);
        },
        warning(text, title, cb, confirmText, cancelText, showCancel) {
            _.confirm.showEx(text, title, 'warning', cb, confirmText, cancelText, showCancel);
        },
        danger(text, title, cb, confirmText, cancelText, showCancel) {
            _.confirm.showEx(text, title, 'error', cb, confirmText, cancelText, showCancel);
        },
    },

    setPageParamCallback(cb) {
        if (hasParent()) {
            let iframes = parent.document.getElementsByTagName('iframe');
            let l = iframes.length;
            while (l--) {
                if (iframes[l].contentWindow === window) {
                    // 找到对应的iframe
                    let dataId = iframes[l].getAttribute('data-id');
                    if (typeof dataId == 'string')
                        parent.Bus.$emit(Events.SETPAGECB, dataId, cb);
                }
            }
        }
    },
    sendPageParam(id, param) {
        parent.Bus.$emit(Events.SENDPAGEPARAM, id, param);
    }
};

export default _;