<template>
  <el-input type="text" v-model="innerValue" :size="size"
            :readonly="readonly" :disabled="disabled"
            @blur="proceed" @keyup.enter.native="proceed"></el-input>
</template>

<script>
  export default {
    name: "NumInput",
    props: {
      value: {
        type: Number|String,
        default: 0
      },
      textValue: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: ''
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      decimalSeparator: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        innerValue: ''
      };
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          this.copyValue();
        }
      },
      decimalSeparator: {
        handler() {
          this.copyValue();
        }
      }
    },
    methods: {
      copyValue() {
        this.innerValue = this.value.toDecimalSeparatedString(this.decimalSeparator ? ',' : undefined);
        this.$emit('update:textValue', this.innerValue);
      },
      proceed() {
        const value = Number(this.innerValue.replace(/,/g, ''));
        if(isNaN(value)) {
          this.innerValue = '';
          this.$emit('input', 0);
          this.$emit('change');
        }
        else {
          this.$emit('input', value);
          this.$emit('change');
        }
      }
    }
  }
</script>

<style scoped>

</style>