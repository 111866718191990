<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      options: {
        // 鼠标移上去有注释
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["70%", "80%"], //控制圈大小的
            //是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。当avoidLabelOverlap设置为false时会出现以下情况
            avoidLabelOverlap: true,
            center: ["25%", "50%"],
            silent: false, //是否响应鼠标样式，默认false，为响应
            hoverAnimation: true, //环形图是否响应鼠标变大，默认为true 响应
            label: {
              show: false,
              position: "center",
              fontSize: "20",
              fontWeight: "bold",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    "#0089f8",
                    "#9bf8fe",
                    "#1baff9",
                    "#0073ed",
                    "#7595f9",
                    "#04416c",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById(this.id));
    this.options.series[0].data = this.chartData.dataOfSeries;
    if (this.chartData.posOfChart) {
      this.options.series[0].center = this.chartData.posOfChart;
    }
    if (this.chartData.emphasisOfSeries) {
      this.options.series[0].emphasis = this.chartData.emphasisOfSeries;
    }
    if (this.chartData.labelOfSeries) {
      this.options.series[0].label = this.chartData.labelOfSeries;
    }
    if (this.chartData.radiusOfChart) {
      this.options.series[0].radius = this.chartData.radiusOfChart;
    }
    if (this.chartData.legend) {
      this.options.legend = this.chartData.legend;
    }
    if (this.chartData.nameOfSeries) {
      this.options.series[0].name = this.chartData.nameOfSeries;
    }
    this.myChart.setOption(this.options);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
