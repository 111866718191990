<template>
  <div class="level_change_container">
    <div class="work_box">
      <div class="title">职级结变化趋势</div>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="levelChange"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LevelChange",
  props: ["list", "isLoading"],
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%", top: "0" },
      },
    };
  },
  watch: {
    list: {
      handler() {
        this.formatData();
      },
      deep: true,
    },
  },
  methods: {
    // formatData() {
    //   if (this.list && this.list.length) {
    //     this.chartData.series = [];
    //     this.list.forEach((item, index) => {
    //       this.chartData.series.push({
    //         name: item.name,
    //         type: "bar",
    //         data: [],
    //       });
    //       if (item.special_job_name_list && item.special_job_name_list.length) {
    //         this.chartData.dataOfXaxis = [];
    //         item.special_job_name_list.forEach((item2) => {
    //           this.chartData.series[index].data.push(item2.count);
    //           this.chartData.dataOfXaxis.push(item2.name);
    //         });
    //       }
    //       this.chartData.dataOfLegend.push(item.name + "");
    //     });
    //   }
    // },
    formatData() {
      console.log(this.list);
      if (this.list && this.list.length) {
        this.chartData.series = [];

        this.chartData.dataOfXaxis = [];
        this.list.forEach((item, index) => {
          // 获取x轴数据
          this.chartData.dataOfXaxis.push(item.name);
          // 获取legend数据 初始化series数据
          if (item.special_job_name_list && item.special_job_name_list.length) {
            this.chartData.dataOfLegend = [];
            this.chartData.series = [];
            item.special_job_name_list.forEach((item2) => {
              this.chartData.dataOfLegend.push(item2.name);
              this.chartData.series.push({
                name: item2.name,
                type: "bar",
                data: [],
              });
            });
          }
        });
      }
      if (this.list && this.list.length) {
        this.list.forEach((year, yearIndex) => {
          // 获取legend数据 初始化series数据
          if (year.special_job_name_list && year.special_job_name_list.length) {
            year.special_job_name_list.forEach((edu, eduIndex) => {
              const count = edu.count;
              this.chartData.series[eduIndex].data[yearIndex] = count;
            });
          }
        });
      }
      console.log(this.chartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.level_change_container {
  height: 25%;
  background: green;
  & .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>
