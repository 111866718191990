<template>
  <div class="education_change_container">
    <div class="work_box">
      <div class="title">学历变化趋势</div>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="educationChange"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EducationChange",
  props: ["list", "isLoading"],
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%", top: "0" },
      },
    };
  },
  watch: {
    list: {
      handler() {
        this.formatData();
      },
      deep: true,
    },
  },
  methods: {
    formatData() {
      console.log(this.list);
      if (this.list && this.list.length) {
        this.chartData.series = [];

        this.chartData.dataOfXaxis = [];
        this.list.forEach((item, index) => {
          // 获取x轴数据
          this.chartData.dataOfXaxis.push(item.name);
          // 获取legend数据 初始化series数据
          if (item.education_list && item.education_list.length) {
            this.chartData.dataOfLegend = [];
            this.chartData.series = [];
            item.education_list.forEach((item2) => {
              this.chartData.dataOfLegend.push(item2.name);
              this.chartData.series.push({
                name: item2.name,
                type: "bar",
                data: [],
              });
            });
          }
        });
      }
      if (this.list && this.list.length) {
        this.list.forEach((year, yearIndex) => {
          // 获取legend数据 初始化series数据
          if (year.education_list && year.education_list.length) {
            year.education_list.forEach((edu, eduIndex) => {
              const count = edu.count;
              this.chartData.series[eduIndex].data[yearIndex] = count;
            });
          }
        });
      }
      console.log(this.chartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.education_change_container {
  height: 25%;
  background: green;
  & .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>
