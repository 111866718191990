<template>
  <div :id="id" :class="[className, 'charts']">3333</div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["options", "id", "className"],
  watch: {
    options: {
      handler(val, oldVal) {
        this.myChart.setOption(this.options);
      },
      deep: true,
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    // this.drawLine();
    this.myChart = echarts.init(document.getElementById(this.id));
    this.myChart.setOption(this.options);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>