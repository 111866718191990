<template>
  <div class="kejing_fangyuan_container">
    <div class="work_box">
      <div class="title">可经房源</div>
      <div class="content">
        <div class="huan">
          <div class="company">
            <div>
              <template v-if="datumOfProp && datumOfProp.org_list">
                <div v-for="item in datumOfProp.org_list" :key="item.name">
                  <span>{{ item.name }}</span>
                  <span class="num">{{ item.count }} </span>
                  <span>处</span>
                </div>
              </template>
            </div>
          </div>
          <div class="charts">
            <pie-echarts
              v-if="!isPropLoading"
              :chartData="countData"
              id="resource_count"
              :className="'pie__chart'"
            ></pie-echarts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "KeJingFangYuan",
  props: ["isPropLoading", "datumOfProp", "isSummaryLoading", "datumOfSummary"],
  watch: {
    datumOfProp(data) {
      if (data) {
        this.formatCountData(); //可经房源数据量
      }
    },
    datumOfSummary(data) {
      if (data) {
        this.formatSummaryOfRes();
      }
    },
  },
  data() {
    return {
      countData: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 处 ({d}%)",
        },
        dataOfSeries: [],
        posOfChart: ["50%", "60%"],
        radiusOfSeries: "50%",
        legend: {
          orient: "horizontal",
          left: 10,
          top: 0,
          textStyle: {
            color: "#fff",
          },
          data: [],
        },
      },
      summaryOfResData: {
        xAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            data: [],
            type: "line",
            lineStyle: {
              color: "#0084ca",
              // width: 1,
            },
            itemStyle: {
              borderType: "solid",
              color: "#fff",
            },
          },
        ],
      },
    };
  },
  methods: {
    formatCountData() {
      if (
        this.datumOfProp.manage_source_list &&
        this.datumOfProp.manage_source_list.length
      ) {
        this.countData.dataOfSeries = [];
        this.countData.legend.data = [];
        this.datumOfProp.manage_source_list.forEach((item) => {
          let obj = {
            name: item.name,
            value: item.count,
          };
          this.countData.dataOfSeries.push(obj);
          this.countData.legend.data.push(item.name);
        });
      }
    },
    formatSummaryOfRes() {
      if (
        this.datumOfSummary.month_list &&
        this.datumOfSummary.month_list.length
      ) {
        this.summaryOfResData.xAxis.data = [];
        this.summaryOfResData.series[0].data = [];
        this.datumOfSummary.month_list.forEach((item) => {
          this.summaryOfResData.xAxis.data.push(item.name + "月");
          this.summaryOfResData.series[0].data.push(item.rentable_area);
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
