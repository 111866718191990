<template>
  <div class="qianshou_rank_container">
    <div class="work_box">
      <div class="title">欠收金额排行</div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <hor-bar-mul-echarts
          v-if="!isLoading"
          :chartData="chartData"
          :className="'hor_bar_chart'"
          id="company_sjl2"
        ></hor-bar-mul-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QianshouRank",
  props: ["isLoading", "datum"],
  data() {
    return {
      keyObj: {},
      chartData: {
        series: [],
        // dataOfLegend: [],
        dataOfYaxis: [],
        grid: {
          left: "1%",
          right: "5%",
          bottom: "0%",
          top: "5%",
          containLabel: true,
        },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatChart();
      }
    },
  },
  methods: {
    formatChart() {
      this.chartData.series = [];
      for (let key in this.datum) {
        this.chartData.dataOfYaxis = [];
        let obj = {
          name: key,
          type: "bar",
          stack: "欠收金额",
          // label: {
          //   show: true,
          //   position: "insideRight",
          // },
          data: [],
        };
        let itemArr = this.datum[key];
        itemArr.forEach((item) => {
          this.chartData.dataOfYaxis.push(item.name);
          obj.data.push(item.amount);
        });
        this.chartData.series.push(obj);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
