<template>
  <div class="change_container">
    <div class="work_box">
      <div class="title">职工人数变化趋势</div>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="change"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Change",
  props: ["list", "isLoading"],
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%", top: "0" },
      },
    };
  },
  watch: {
    list: {
      handler(val, oldVal) {
        if (val) {
          this.formatData();
        }
      },
      deep: true,
    },
  },
  methods: {
    formatData() {
      console.log("开始", this.list);
      this.chartData.series = [{ name: "人数", type: "bar", data: [] }];
      this.chartData.dataOfXaxis = [];
      for (let key in this.list) {
        this.chartData.series[0].data.push(this.list[key].count);
        this.chartData.dataOfXaxis.push(this.list[key].name + "月");
        this.chartData.dataOfLegend = ["人数"];
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.change_container {
  height: 25%;
  background: green;
  & .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>
