<template>
  <div class="bennian_qianshou_fenbu_container">
    <div class="work_box">
      <div class="title">往年欠收分布</div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="wangnainqianshou"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WangnianQianshouFenbu",
  props: ["isLoading", "datum"],
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%" },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatChart();
      }
    },
  },
  methods: {
    formatChart() {
      this.chartData.series = [];
      this.chartData.dataOfLegend = [];
      for (let key in this.datum) {
        // 初始化legend
        const values = this.datum[key];
        this.chartData.dataOfLegend.push(key);
        // 初始化 series的data
        let obj = {
          name: key,
          type: "bar",
          data: [],
        };
        this.chartData.dataOfXaxis = [];
        values.forEach((item) => {
          this.chartData.dataOfXaxis.push(item.name);
          obj.data.push(item.amount);
        });
        this.chartData.series.push(obj);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
