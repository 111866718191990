<template>
  <div class="shebei_yanghu_container">
    <div class="work_box">
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="count">
          <chart-title
            :title="'设备数量'"
            :count="datum && datum.total_count"
            :unit="'台'"
          ></chart-title>
          <huan-echarts
            v-if="!isLoading"
            :chartData="chartData"
            id="shebei_yanghu"
            :className="'huan_chart'"
          ></huan-echarts>
        </div>
        <!-- <pie-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="shebei_yanghu"
          :className="'pie__chart'"
        ></pie-echarts> -->
      </div>
    </div>
  </div>
</template>
<script>
import ChartTitle from "@/components/common/chartTitle/ChartTitle";

export default {
  name: "ShebeiYanghu",
  props: ["datum", "isLoading"],
  components: { ChartTitle },
  data() {
    return {
      chartData: {
        nameOfSeries: "完成率",
        dataOfSeries: [],
        posOfChart: ["45%", "40%"],
        radiusOfChart: ["40%", "50%"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 件 ({d}%)",
        },
        legend: {
          orient: "horizontal",
          data: [],
          bottom: 0,
          textStyle: {
            color: "#fff",
          },
        },
        labelOfSeries: {
          normal: {
            show: false,
            fontSize: "20",
            fontWeight: "bold",
            position: "center",
            formatter: "{a}\n\n{d}%",
          },
        },
      },
      keys: {
        finished: "已完成",
        // revoked: "已作废",
        toAssign: "已指派",
        toConfirm: "待确认",
        toFinish: "待完成",
        toProceed: "待处理",
      },
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatData();
      }
    },
  },
  methods: {
    formatData() {
      this.chartData.dataOfSeries = [];
      this.chartData.legend.data = [];
      for (let key in this.keys) {
        let obj = {
          name: this.keys[key],
          value: this.datum[key],
          label: { show: key == "finished" ? true : false },
        };
        this.chartData.dataOfSeries.push(obj);
        this.chartData.legend.data.push(this.keys[key]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
