<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      option: {
        textStyle: {
          color: "#3a8dec",
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        xAxis: {},
        yAxis: {
          type: "value",
        },
        series: [],
      },
    };
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById(this.id));
    if (this.chartData.grid) {
      this.option.grid = this.chartData.grid;
    }
    if (
      this.chartData.xAxis &&
      this.chartData.xAxis.data &&
      this.chartData.xAxis.data.length
    ) {
      this.option.xAxis = this.chartData.xAxis;
    }
    if (
      this.chartData.series[0] &&
      this.chartData.series[0].data &&
      this.chartData.series[0].data.length
    ) {
      this.option.series = this.chartData.series;
    }
    this.myChart.setOption(this.option);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
