<template>
  <div class="HolyGrail">
    <top-container
      :routePath="$route.query.path"
      :isOrgShow="$route.path != '/' && true"
      :curRoute="curRoute"
    ></top-container>
    <router-view class="HolyGrail-body" />
  </div>
</template>

<script>
// import merge from 'webpack-merge';
import commonApi from "@/api/common";
import Events from "@/api/events";

export default {
  name: "App",
  components: {},
  data() {
    return {
      curRoute: { name: "index", url: "/" },
      routeObj: {
        index: "首页",
        renliziyuan: "人力资源",
        yunying: "运营",
        zichan: "资产",
        gongcheng: "工程",
        yingshouqiankuan:"营收欠款",
        xianjinshoujiaolv:"现金收缴率排行"
      },
    };
  },
  methods: {
    checkUser() {
      this.$store.dispatch("user/update");
    },
    init() {
      commonApi.init();
      this.checkUser();
      Bus.$on(Events.USER_UPDATED, (usr) => {
        this.onUsrUpdated(usr);
      });
      Bus.$on(Events.FORCE_UPDATEUSER, () => {
        this.checkUser();
      });
    },
    onUsrUpdated(usr) {
      // 如果登录获取用户失败，则强制跳转
      if (!usr) {
        if (this.$route.path != "/login") {
          this.$router.replace("/login");
        }
        // this.$framework.jumpToLogin("请重新登录");
        // this.$message("请重新登录");
      }
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    Bus.$off(Events.USER_UPDATED);
    Bus.$off(Events.FORCE_UPDATEUSER);
  },
  watch: {
    $route(to, from) {
      console.log('路由发生了变化')
      this.curRoute = {
        name: this.routeObj[this.$route.name],
        url: this.$route.path,
      };
      this.checkUser();
    },
  },
};
</script>

<style lang="scss">
// @import "./style/common.scss";
.HolyGrail {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > .HolyGrail-body {
    height: 85vh;
    width: 99vw;
    margin: 0 auto;
  }
}
</style>
