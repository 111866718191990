<template>
  <div class="content_container">
    <div>
      <div class="content_left" :style="{ width: contentLeftWidth }">
        <slot name="content_left"></slot>
      </div>
      <div class="content_right">
        <slot name="content_right"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentContainer",
  props: { contentLeftWidth: { type: String, default: "30%" } },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>