export default {
    // 千分符
    qff(str) {
        let str_0 = str.split('.')[0];
        let str_1 = str.split('.')[1];
        return str_0.replace(/\d/g, function () {
            if ((str_0.length - arguments[1] - 1) % 3 === 0 && str_0.length - 1 !== arguments[1]) {
                return arguments[0] + ","
            } else {
                return arguments[0];
            }
        })
    },
    strToArr(str, fuhao) {
        return str ? str.split(fuhao) : []
    }
}