<template>
  <div
    class="map_data_container"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="data">
      <template v-if="datum">
        <div class="item">
          <span>服务城市</span>
          <div class="number">
            <h2>{{ datum.city_count }}</h2>
            <span>个</span>
          </div>
        </div>
        <div class="item">
          <span>项目数量</span>
          <div class="number">
            <h2>{{ datum.project_count }}</h2>
            <span>个</span>
          </div>
        </div>
        <div class="item">
          <span>服务客户</span>
          <div class="number">
            <h2>{{ datum.room_count }}</h2>
            <span>户</span>
          </div>
        </div>
        <div class="item">
          <span>管理面积</span>
          <div class="number">
            <h2>{{ (datum.manage_area / 10000).toFixed(2) }}</h2>
            <span>万平米</span>
          </div>
        </div>
      </template>
    </div>
    <div class="chart">
      <!-- <bar-echarts
        v-if="!isLoading"
        :chartData="chartData"
        id="project_summary"
        :className="'bar_chart'"
      ></bar-echarts> -->
      <pie-echarts
        v-if="!isLoading"
        :chartData="chartData"
        id="project_summary"
        :className="'pie__chart'"
      ></pie-echarts>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapData",
  props: ["isLoading", "datum"],
  data() {
    return {
      chartData: {
        dataOfSeries: [],
        posOfChart: ["45%", "45%"],
        radiusOfSeries: "50%",
        nameOfSeries: "项目业态",
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 处 ({d}%)",
        },
        legend: {
          orient: "horizontal",
          data: [],
          bottom: 0,
          textStyle: {
            color: "#fff",
          },
        },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatData();
      }
    },
  },
  methods: {
    formatData() {
      this.chartData.dataOfSeries = [];
      this.chartData.legend.data = [];
      if (this.datum.form_list && this.datum.form_list.length) {
        this.datum.form_list.forEach((item) => {
          let obj = {
            name: item.name,
            value: item.project_count,
          };
          this.chartData.dataOfSeries.push(obj);
          this.chartData.legend.data.push(item.name);
        });
      }
      console.log(this.chartData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
