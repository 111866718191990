<template>
  <layout
    :navWidth="'47em'"
    :isAsideShow="false"
  >
    <template v-if="curOrg.type == 0">
      <div slot="nav" class="zichan_nav">
        <ke-jing-fang-yuan
          :isPropLoading="propertyOfRes.isLoading"
          :datumOfProp="propertyOfRes.datum"
          :isSummaryLoading="summaryOfRes.isLoading"
          :datumOfSummary="summaryOfRes.datum"
        ></ke-jing-fang-yuan>
        <ke-jing-mian-ji
          :isPropLoading="propertyOfRes.isLoading"
          :datumOfProp="propertyOfRes.datum"
          :isSummaryLoading="summaryOfRes.isLoading"
          :datumOfSummary="summaryOfRes.datum"
        ></ke-jing-mian-ji>
      </div>
      <div slot="content" class="zichan_content">
        <div class="_left">
          <zulin-jingying
            :isSummaryLoading="summaryOfRes.isLoading"
            :datumOfSummary="summaryOfRes.datum"
          ></zulin-jingying>
          <zulin-shoujiao
            :isLoading="bill.isLoading"
            :datum="bill.datum"
          ></zulin-shoujiao>
          <div class="touzi_zengjian">
            <touzi-fenhong
              :isLoading="bill.isLoading"
              :datum="bill.datum"
            ></touzi-fenhong>
            <add-minus></add-minus>
          </div>
        </div>
        <div class="_right">
          <kongzhi-rank
            :isLoading="summaryOfRes.isLoading"
            :datum="summaryOfRes.datum"
          ></kongzhi-rank>
          <qianfei-rank
            :isLoading="bill.isLoading"
            :datum="bill.datum"
          ></qianfei-rank>
        </div>
      </div>
    </template>
    <template v-else>
      <div slot="nav" class="project_zichan_nav">
        <base-info :isLoading="org.isLoading" :datum="org.datum" :curOrg="curOrg"></base-info>
      </div>
      <div slot="content" class="project_zichan_content">
        <div class="_left">
          <ke-jing-fang-yuan
            :isPropLoading="propertyOfRes.isLoading"
            :datumOfProp="propertyOfRes.datum"
            :isSummaryLoading="summaryOfRes.isLoading"
            :datumOfSummary="summaryOfRes.datum"
          ></ke-jing-fang-yuan>
          <ke-jing-mian-ji
            :isPropLoading="propertyOfRes.isLoading"
            :datumOfProp="propertyOfRes.datum"
            :isSummaryLoading="summaryOfRes.isLoading"
            :datumOfSummary="summaryOfRes.datum"
          ></ke-jing-mian-ji>
        </div>
        <div class="_right">
          <zulin-jingying
            :isSummaryLoading="summaryOfRes.isLoading"
            :datumOfSummary="summaryOfRes.datum"
          ></zulin-jingying>
          <zulin-shoujiao
            :isLoading="bill.isLoading"
            :datum="bill.datum"
          ></zulin-shoujiao>
          <add-minus></add-minus>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import propertyApi from "@/api/property/property";
import KeJingFangYuan from "./components/kejingfangyuan/KeJingFangYuan";
import KeJingMianJi from "./components/kejingmianji/KeJingMianJi";
import ZulinJingying from "./components/zulinJingying/ZulinJingying";
import ZulinShoujiao from "./components/zulinShoujiao/ZulinShoujiao";
import TouziFenhong from "./components/touziFenhong/TouziFenhong";
import AddMinus from "./components/addMinus/AddMinus";
import KongzhiRank from "./components/kongzhiRank/KongzhiRank";
import QianfeiRank from "./components/qianfeiRank/QianfeiRank";
import { mapState } from "vuex";
import resourceApi from "@/api/company/resource";
import orgApi from "@/api/org/org";
import BaseInfo from "@/components/common/baseInfo/BaseInfo";

export default {
  name: "Index",
  components: {
    KeJingFangYuan,
    KeJingMianJi,
    ZulinJingying,
    ZulinShoujiao,
    TouziFenhong,
    AddMinus,
    KongzhiRank,
    QianfeiRank,
    BaseInfo,
  },
  data() {
    return {
      bill: { isLoading: false, datum: null },
      propertyOfRes: { isLoading: false, datum: null },
      summaryOfRes: { isLoading: false, datum: null },
      org: { isLoading: false, datum: null },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        // console.log("资产模块的vuex发生变化");
        if (newVal) {
          this.getPropertyOfBill(); // 租金收缴情况
          this.getPropertyOfResource(); // 房源信息概况
          this.getSummaryOfResource(); // 资产经营数据（主页有用到）
          this.getDetailOfOrg();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        console.log(state);
        return state.curOrg ? state.curOrg : { path: [], id: 1 };
      },
    }),
  },
  methods: {
    getDetailOfOrg() {
      this.org.isLoading = true;
      orgApi.detailOfOrg(this.curOrg.id).then(
        (res) => {
          this.org.isLoading = false;
          this.org.datum = res.data.datum;
        },
        (err) => {
          this.org.isLoading = false;
        }
      );
    },
    getPropertyOfBill() {
      this.bill.isLoading = true;
      propertyApi
        .propertyOfBill(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.bill.isLoading = false;
            this.bill.datum = res.data.datum;
          },
          (err) => {
            this.bill.isLoading = false;
            console.log(err);
          }
        );
    },
    getPropertyOfResource() {
      this.propertyOfRes.isLoading = true;
      propertyApi
        .propertyOfResource(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.propertyOfRes.isLoading = false;
            this.propertyOfRes.datum = res.data.datum;
          },
          (err) => {
            this.propertyOfRes.isLoading = false;
            console.log(err);
          }
        );
    },
    getSummaryOfResource() {
      const param = {
        organization_id: this.curOrg.id,
        need_month_report: true,
      };
      this.summaryOfRes.isLoading = true;
      resourceApi.getSummaryOfResource(param).then(
        (res) => {
          this.summaryOfRes.isLoading = false;
          this.summaryOfRes.datum = res.data.datum;
        },
        (err) => {
          this.summaryOfRes.isLoading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
