<template>
  <div class="qianfei_rank_container">
    <div class="work_box">
      <div class="title">欠费排行</div>
      <div class="content">
        <hor-bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          :className="'hor_bar_chart'"
          id="qian_fei_rank"
        ></hor-bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ZulinJingying",
  props: ["isLoading", "datum"],
  watch: {
    datum(data) {
      console.log(data);
      if (data && data.org_list && data.org_list.length) {
        console.log(data.org_list);
        this.formatData();
      }
    },
  },
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfYaxis: [],
        tooltip: {
          trigger: "axis",
          formatter: "{a}:{c}",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
      },
    };
  },
  methods: {
    formatData() {
      this.chartData.dataOfLegend = ["欠费"];
      this.chartData.series = [
        {
          name: "欠费",
          type: "bar",
          stack: "总量",
          label: {
            show: false,
            position: "insideRight",
          },
          data: [],
        },
      ];

      this.chartData.dataOfYaxis = [];
      this.datum.org_list.forEach((item) => {
        this.chartData.dataOfYaxis.push(item.name);
        this.chartData.series[0].data.push(item.rest_amount);
      });
      console.log(this.chartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>