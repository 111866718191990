<template>
  <div
    class="map_chart_container"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <template v-if="!isLoading && datum">
      <div class="_left">
        <div class="china" v-if="!datum.org_info.path.length">
          <div class="jingjinji">
            <map-echarts
              :chartData="mapChart"
              id="shoukuan1"
              :className="'map_chart'"
            ></map-echarts>
          </div>
          <div class="other">
            <div v-for="(value, key) in cityOfChina" :key="key">
              <map-echarts
                :chartData="value"
                :id="key"
                :className="'map_chart'"
              ></map-echarts>
            </div>
          </div>
        </div>
        <city-map-echarts
          v-else
          :chartData="cityChartData"
          id="cityChart"
          :className="'map_chart'"
        ></city-map-echarts>
      </div>
      <div class="_right">
        <div class="out_box" id="map_scroll_div">
          <template v-if="JSON.stringify(mapData) !== '{}'">
            <div id="map_scroll_begin">
              <div class="item" v-for="(value, key) in mapData" :key="key">
                <!-- <h4>
                  {{ key }}
                </h4> -->
                <div
                  class="company"
                  v-for="(itm, index) in value"
                  :key="index"
                  @click="select(itm, key)"
                >
                  {{ itm.name }}
                </div>
              </div>
            </div>
            <div id="map_scroll_end"></div>
          </template>
          <template v-else>
            <div class="item">
              <div class="company">暂无数据</div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "MapChart",
  props: ["isLoading", "datum"],
  data() {
    return {
      curCountry: "",
      mapChart: {},
      mapData: {},
      cityChartData: { dataOfMarkPoint: [], mapOfSeries: "", citys: {} },
      cityOfChina: {
        江苏: {
          cityOfChina: ["江苏省"],
          layoutCenter: ["-50%", "20%"],
          layoutSize: 700,
        },
        湖北: {
          cityOfChina: ["湖北省"],
          layoutCenter: ["-10%", "-10%"],
          layoutSize: 700,
        },
        辽宁: {
          cityOfChina: ["辽宁省"],
          layoutCenter: ["-70%", "120%"],
          layoutSize: 700,
        },
      },
    };
  },
  watch: {
    datum(data) {
      // console.log("当前地图的详情是", data);
      if (data) {
        this.formatMap();
        this.formatCityMap();
      }
    },
  },
  methods: {
    select(itm, key) {
      // console.log("当前一级的数据", this.datum, "itm====", itm, key);
      // 同步其他图表数据
      this.changeCurOrg(itm);
      this.curCountry = key;
    },
    changeCurOrg(itm) {
      const obj = {
        path: this.datum.org_info.path.length
          ? this.datum.org_info.path.concat([this.datum.org_info.id, itm.id])
          : [1, itm.id],
        id: itm.id,
        type: itm.type,
      };
      console.log(obj);
      window.sessionStorage.setItem("curOrgPath", obj.path);
      window.sessionStorage.setItem("curOrgId", obj.id);
      window.sessionStorage.setItem("curOrgType", obj.type);
      this.$store.commit("orgSelected", obj);
      this.$route.query["path"] = obj.path.join(",");
    },
    formatCityMap() {
      console.log(this.datum, this.curCountry);
      if (!this.curCountry) {
        return;
      }
      // 渲染城市对应的图表
      this.cityChartData.mapOfSeries = this.curCountry;
      this.cityChartData.dataOfMarkPoint = [];
      this.cityChartData.citys = {};
      if (this.datum.project_list && this.datum.project_list.length) {
        this.datum.project_list.forEach((item) => {
          let obj = {
            name: item.name,
            // coord: item.location ? [item.location.lng, item.location.lat] : [],116.507404,39.848718
            coord: item.location ? [item.location.lng, item.location.lat] : [],
          };
          this.cityChartData.dataOfMarkPoint.push(obj);
          if (!item.county) {
            item.county = { county_name: "" };
          }
          // item.province = "天津";
          if (item.county && item.county.county_name) {
            if (!this.cityChartData.citys[item.county.county_name]) {
              this.cityChartData.citys[item.county.county_name] = [];
            }
            this.cityChartData.citys[item.county.county_name].push({
              name: item.name,
              id: item.id,
            });
          }
        });
        console.log(this.cityChartData);
      }
    },
    formatMap() {
      // console.log("开始渲染地图");
      this.mapChart = {};
      this.mapData = {};
      if (
        this.datum.org_info &&
        this.datum.org_info.path &&
        !this.datum.org_list.length
      ) {
        // 说明是点击分公司后需要渲染project_list
        if (this.datum.project_list && this.datum.project_list.length) {
          this.datum.project_list.forEach((item) => {
            if (!item.province) {
              item.province = { province_name: "" };
            }
            // item.province = "天津";
            if (!this.mapData[item.province.province_name]) {
              this.mapData[item.province.province_name] = [];
            }
            this.mapData[item.province.province_name].push({
              name: item.name,
              id: item.id,
              type: 1,
            });
          });
        }
      } else {
        if (this.datum.org_list && this.datum.org_list.length) {
          this.datum.org_list.forEach((item) => {
            if (!item.province) {
              item.province = { province_name: "" };
            }
            // item.province = "天津";
            if (!this.mapChart[item.province.province_name]) {
              this.mapChart[item.province.province_name] = [];
            }
            this.mapChart[item.province.province_name].push({
              name: item.name,
              id: item.id,
            });
            // 格式化右侧表格数据
            if (!this.mapData[item.province.province_name]) {
              this.mapData[item.province.province_name] = [];
            }
            this.mapData[item.province.province_name].push({
              name: item.name,
              id: item.id,
              type: 0,
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
