<template>
  <div class="zulin_shoujiao_container">
    <div class="work_box">
      <div class="title">租金收缴情况</div>
      <div class="content">
        <div class="pie">
          <div class="chart">
            <huan-echarts
              v-if="!isLoading"
              :chartData="chartData"
              id="shoujiao_huan_chart"
              :className="'huan_chart'"
            ></huan-echarts>
          </div>
          <div class="data">
            <chart-label
              :label="'应收'"
              :count="
                datum &&
                  datum.total &&
                  (datum.total.total_amount / 10000).toFixed(2)
              "
              :unit="'万元'"
            ></chart-label>
            <chart-label
              :label="'已收'"
              :count="
                datum &&
                  datum.total &&
                  (datum.total.pay_amount / 10000).toFixed(2)
              "
              :unit="'万元'"
            ></chart-label>
            <chart-label
              :label="'欠费'"
              :count="
                datum &&
                  datum.total &&
                  (datum.total.rest_amount / 10000).toFixed(2)
              "
              :unit="'万元'"
            ></chart-label>
          </div>
        </div>
        <div class="bar">
          <bar-echarts
            v-if="!isLoading"
            :chartData="monthData"
            id="total_shoujiao_bar_chart"
            :className="'bar_chart'"
          ></bar-echarts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChartLabel from "@/components/common/chartLabel/ChartLabel";

export default {
  name: "ZulinShoujiao",
  props: ["isLoading", "datum"],
  components: { ChartLabel },

  watch: {
    datum(data) {
      if (data) {
        console.log(data);
        this.formatMonth();
      }
    },
  },
  data() {
    return {
      monthData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "10%", top: "5%" },
      },
      chartData: {
        nameOfSeries: "收缴率",
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        radiusOfChart: ["50%", "60%"],
        labelOfSeries: {
          normal: {
            show: false,
            fontSize: "20",
            fontWeight: "bold",
            position: "center",
            formatter: "{d}%",
          },
        },
        // legend: {
        //   orient: "horizontal",
        //   right: 10,
        //   data: [],
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
      },
    };
  },
  methods: {
    formatMonth() {
      this.monthData.dataOfLegend = [];
      this.monthData.series = [];

      this.chartData.dataOfSeries = [];
      // this.chartData.legend.data = [];

      const objs = {
        pay_amount: "已收",
        rest_amount: "欠收",
      };
      for (let key in objs) {
        const obj = {
          name: objs[key],
          type: "bar",
          data: [],
          stack: "收缴率",
        };
        // this.monthData.dataOfLegend.push(objs[key]);

        this.monthData.dataOfXaxis = [];
        this.datum.month_list.forEach((item) => {
          obj.data.push(Number(item[key]).toFixed(2));
          this.monthData.dataOfXaxis.push(item.name + "月");
        });
        this.monthData.series.push(obj);

        // format 环 图
        let obj_huan = {
          name: objs[key],
          value: this.datum.total[key],
          label: { show: key == "pay_amount" ? true : false },
        };
        console.log(obj_huan);
        this.chartData.dataOfSeries.push(obj_huan);
        // this.chartData.legend.data.push(objs[key]);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
