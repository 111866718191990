<template>
  <div class="add_minus_container">
    <div class="work_box">
      <div class="title">增加/减少</div>
      <div class="content">
        <div>
          <pie-echarts
            v-if="!isLoading"
            :chartData="addChartData"
            id="add_"
            :className="'pie__chart'"
          ></pie-echarts>
        </div>
        <div>
          <pie-echarts
            v-if="!isLoading"
            :chartData="minusChartData"
            id="minus_"
            :className="'pie__chart'"
          ></pie-echarts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ZulinJingying",
  props: ["datum", "isLoading"],
  watch: {
    datum(data) {
      if (data) {
        this.formatData();
      }
    },
  },
  data() {
    return {
      addChartData: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 平 ({d}%)",
        },
        dataOfSeries: [
          { value: 335, name: "承租" },
          { value: 310, name: "资源拓展" },
        ],
        nameOfSeries: "增加",
        posOfChart: ["45%", "60%"],
        radiusOfSeries: "50%",
        legend: {
          orient: "vertical",
          data: ["承租", "资源拓展"],
          left: 0,
          textStyle: {
            color: "#fff",
          },
        },
      },
      minusChartData: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 平 ({d}%)",
        },
        dataOfSeries: [
          { value: 335, name: "非经移交" },
          { value: 310, name: "自有宿舍" },
          { value: 234, name: "办公自用" },
        ],
        nameOfSeries: "减少",
        posOfChart: ["45%", "60%"],
        radiusOfSeries: "50%",
        legend: {
          orient: "horizontal",
          data: ["非经移交", "自有宿舍", "办公自用"],
          left: 0,
          textStyle: {
            color: "#fff",
          },
        },
      },
    };
  },
  methods: {
    formatData() {},
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>