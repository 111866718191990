<template>
  <div class="fugai_lv_container">
    <div class="work_box">
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="item">
          <h5>物管/业委会覆盖率</h5>
          <div class="chart">
            <huan-echarts
              v-if="!isLoading"
              :chartData="wg_chartData"
              id="wg_fugai_lv"
              :className="'huan_chart'"
            ></huan-echarts>
          </div>
        </div>
        <div class="item">
          <h5>党组织覆盖率</h5>
          <div class="chart">
            <huan-echarts
              v-if="!isLoading"
              :chartData="dzz_chartData"
              id="dzz_fugai_lv"
              :className="'huan_chart'"
            ></huan-echarts>
          </div>
        </div>
        <div class="item">
          <h5>物业服务覆盖率</h5>
          <div class="chart">
            <huan-echarts
              v-if="!isLoading"
              :chartData="wyfw_chartData"
              id="wyfw_fugai_lv"
              :className="'huan_chart'"
            ></huan-echarts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FugaiLv",
  props: ["datum", "isLoading"],
  data() {
    return {
      wg_chartData: {
        dataOfSeries: [],
        nameOfSeries: "物管/业委会覆盖率",
        posOfChart: ["50%", "50%"],
        radiusOfChart: ["50%", "60%"],
        labelOfSeries: {
          normal: {
            show: false,
            fontSize: 20,
            fontWeight: "bold",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
      dzz_chartData: {
        dataOfSeries: [],
        nameOfSeries: "党组织覆盖率",
        posOfChart: ["50%", "50%"],
        radiusOfChart: ["50%", "60%"],
        labelOfSeries: {
          normal: {
            show: false,
            fontSize: 20,
            fontWeight: "bold",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
      wyfw_chartData: {
        dataOfSeries: [
          {
            name: "已成立",
            value: 100,
            label: {
              show: true,
            },
          },
          {
            name: "未成立",
            value: 0,
            label: {
              show: false,
            },
          },
        ],
        nameOfSeries: "物业服务覆盖率",
        posOfChart: ["50%", "50%"],
        radiusOfChart: ["50%", "60%"],
        labelOfSeries: {
          normal: {
            fontSize: 20,
            show: false,
            fontWeight: "bold",
            position: "center",
            formatter: "{d}%",
          },
        },
      },
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatWg();
        this.formatDzz();
      }
    },
  },
  methods: {
    formatWg() {
      this.wg_chartData.dataOfSeries = [
        {
          name: "已成立",
          value: Number(this.datum.has_commission),
          label: { show: true },
        },
        {
          name: "未成立",
          value:
            Number(this.datum.total_count) - Number(this.datum.has_commission),
          label: { show: false },
        },
      ];
    },
    formatDzz() {
      this.dzz_chartData.dataOfSeries = [
        {
          name: "已成立",
          value: Number(this.datum.has_party_org),
          label: { show: true },
        },
        {
          name: "未成立",
          value:
            Number(this.datum.total_count) - Number(this.datum.has_party_org),
          label: { show: false },
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
