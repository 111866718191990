<template>
  <div class="nenghao_container">
    <div class="work_box">
      <div class="title">能耗分析</div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="renli"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "XunshiXunjian",
  props: ["datum", "isLoading"],
  data() {
    return {
      chartData: {
        series: [
          {
            name: "水",
            data: [120, 200, 150, 80, 70, 110, 130, 200, 150, 80, 70, 100],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
          },
          {
            name: "电",
            data: [120, 200, 150, 80, 70, 110, 130, 200, 150, 80, 70, 100],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
          },
          {
            name: "气",
            data: [120, 200, 150, 80, 70, 110, 130, 200, 150, 80, 70, 100],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
          },
        ],
        dataOfLegend: [],
        dataOfXaxis: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        grid: { right: "0%", bottom: "20%", left: "0%",top:'15%' },
      },
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        // this.formatData();
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>