<template>
  <div :id="id" :class="className">asd</div>
</template>

<script>
import * as echarts from "echarts";
import beijing from "@/assets/js/beijing";
import tianjin from "@/assets/js/tianjin";
import jiangsu from "@/assets/js/jiangsu";
import hubei from "@/assets/js/hubei";
import liaoning from "@/assets/js/liaoning";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      option: {
        tooltip: {
          show: true,
        },
        //配置属性
        series: [
          {
            show: true,
            // selectedMode: "multiple",
            name: "项目",
            type: "map",
            map: "",
            roam: false, //禁止缩放和拖动
            top: 5,
            bottom: 0,
            toolbox: {
              show: true,
              orient: "vertical",
              x: "right",
              y: "center",
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                restore: { show: true },
                saveAsImage: { show: true },
              },
            },
            markPoint: {
              symbol: "pin",
              symbolSize: 30,
              itemStyle: {
                emphasis: {
                  borderColor: "#1e90ff",
                  borderWidth: 2,
                },
              },
              data: [
                // {
                //   name: "石景山区",
                //   coord: [116.507404, 39.848718],
                // },
              ],
            },
            itemStyle: {
              normal: {
                //未选中状态
                borderWidth: 1, //边框大小
                borderColor: "#080e23",
                areaColor: "#0b2464", //背景颜色
                label: {
                  show: true, //显示名称
                },
                // color: "#fecb2e",
                shadowBlur: 10,
                shadowColor: "#0d5ebd",
              },
              emphasis: {
                // 也是选中样式
                borderWidth: 1,
                borderColor: "#fff",
                areaColor: "#15648e",
                label: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
            },
            label: {
              normal: {
                show: false, //默认不显示省份名称
              },
              emphasis: {
                show: true,
              },
            },
            data: [],
            silent: true, // true 禁止鼠标悬浮和点击效果
          },
        ],
      },
    };
  },
  mounted() {
    if (this.chartData) {
      if (this.chartData.mapOfSeries) {
        this.option.series[0].map = this.chartData.mapOfSeries;
      }
      this.option.series[0].data = [];
      for (let key in this.chartData.citys) {
        this.option.series[0].data.push({
          name: key,
          selected: true,
          label: { show: true },
        });
      }
      if (this.chartData.dataOfMarkPoint) {
        this.option.series[0].markPoint.data = this.chartData.dataOfMarkPoint;
      }
      if (this.chartData.itemStyle) {
        this.option.series[0].itemStyle = this.chartData.itemStyle;
      }
    }
    this.myChart = echarts.init(document.getElementById(this.id));
    this.myChart.setOption(this.option);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
    // this.myChart.on("click", function (params) {
    // });
  },
  methods: {
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
