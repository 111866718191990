<template>
  <div class="tabs_container">
    <div class="tab_title">
      <span
        v-for="(value, key) in tabs"
        :key="key"
        @click="select(key)"
        :class="(key == curKey) && 'active'"
        >{{ value.name }}</span
      >
    </div>
    <div class="tab_content">
      <div class="list">
        <slot name="list"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VerTabs",
  props: ["tabs", "curKey"],
  methods: {
    select(key) {
      this.$emit("update:curKey", key);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>