<template>
  <div class="shoukuan_chart_container">
    <div
      class="charge"
      v-loading="isLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="_left">
        <div class="money">
          <span>本周累计收款</span>
          <div class="number">
            <h1>{{ cards["本周累计收款"] }}</h1>
            <span>万元</span>
          </div>
        </div>
        <div class="money">
          <span>本月累计收款</span>
          <div class="number">
            <h2>{{ cards["本月累计收款"] }}</h2>
            <span>万元</span>
          </div>
        </div>
      </div>
      <div class="_right">
        <div class="money">
          <span>本年累计收款</span>
          <div class="number">
            <h1>{{ cards["本年累计收款"] }}</h1>
            <span>万元</span>
            <div>
              <span>（上年累计 </span>
              <h2>{{ cards["上年累计"] }}</h2>
              <span> 万元）</span>
            </div>
          </div>
        </div>
        <div class="year_money">
          <div>
            <span>收回本年</span>
            <div class="number">
              <h2>{{ cards["收回本年"] }}</h2>
              <span>万元</span>
            </div>
          </div>
          <div>
            <span>收回旧欠</span>
            <div class="number">
              <h2>{{ cards["收回旧欠"] }}</h2>
              <span>万元</span>
            </div>
          </div>
          <div>
            <span>预收下年</span>
            <div class="number">
              <h2>{{ cards["预收下年"] }}</h2>
              <span>万元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerTabs from "@/components/common/verTabs/VerTabs.vue";
import card from "@/components/common/card/Card";

export default {
  name: "ShouKuan",
  props: ["isLoading", "isLoadingOfIncome", "datum", "datumOfIncome", "height"],
  components: { card },
  data() {
    return {
      cards: {
        本周累计收款: 0,
        今日收款: 0,
        本年累计收款: 0,
        本月累计收款: 0,
        收回本年: 0,
        收回旧欠: 0,
        预收下年: 0,
      },
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%", top: "3%" },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatXaxias();
        this.formatCharge();
        this.formatCards();
      }
    },
  },
  methods: {
    formatCards() {
      this.cards["本周累计收款"] = (
        this.datumOfIncome.total.week.total / 10000
      ).toFixed(2);
      this.cards["本月累计收款"] = (
        this.datumOfIncome.total.month.total / 10000
      ).toFixed(2);
      this.cards["本年累计收款"] = (
        this.datumOfIncome.total.year.total / 10000
      ).toFixed(2);
      this.cards["收回本年"] = (
        this.datumOfIncome.total.year.current / 10000
      ).toFixed(2);
      this.cards["收回旧欠"] = (
        this.datumOfIncome.total.year.old / 10000
      ).toFixed(2);
      this.cards["预收下年"] = (
        this.datumOfIncome.total.year.future / 10000
      ).toFixed(2);
      // this.cards["上年累计"] = (
      //   this.datumOfIncome.total.last_year.total / 10000
      // ).toFixed(2);
    },
    formatXaxias() {
      this.chartData.dataOfXaxis = [];
      this.datum.month_list.forEach((item, index) => {
        this.chartData.dataOfXaxis.push(`${index + 1}月`);
      });
    },
    formatCharge() {
      console.log("------", this.datum.month_list);
      if (this.datum.month_list && this.datum.month_list.length) {
        let obj = {
          old: {
            name: "收旧欠",
            data: [],
          },
          current: {
            name: "收本年",
            data: [],
          },
          future: {
            name: "预收",
            data: [],
          },
        };
        this.chartData.series = [];
        this.chartData.dataOfLegend = [];
        for (let key in obj) {
          obj[key].data = [];
          this.datum.month_list.forEach((item) => {
            obj[key].data.push(item[key].toFixed(2));
          });
          this.chartData.series.push({
            name: obj[key].name,
            type: "bar",
            data: obj[key].data,
          });
          this.chartData.dataOfLegend.push(obj[key].name);
          console.log(obj[key].data);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
