<template>
  <layout :navWidth="curOrg.type == 0 ? '30em' : '30em'" :isAsideShow="false">
    <div slot="nav" class="yingshouqiankuan_nav">
      <qianshou-rank
        :isLoading="debt.isLoading"
        :datum="debt.datumRank"
      ></qianshou-rank>
    </div>
    <div slot="content" class="yingshouqiankuan_content">
      <div class="left_container">
        <total-money
          :isLoading="debt.isLoading"
          :datum="debt.datum"
        ></total-money>
        <duibi-box
          :isLoading="debt.isLoading"
          :datum="debt.datumRank"
        ></duibi-box>
      </div>
      <div class="right_container">
        <bennian-qianshou
          :isLoading="debt.isLoading"
          :datum="debt.datum"
        ></bennian-qianshou>
        <bennian-qianshou-fenbu
          :isLoading="debt.isLoading"
          :datum="debt.datumMonth"
        ></bennian-qianshou-fenbu>
        <wangnian-qianshou
          :isLoading="debt.isLoading"
          :datum="debt.datum"
        ></wangnian-qianshou>
        <wangnian-qianshou-fenbu
          :isLoading="debt.isLoading"
          :datum="debt.datumOldYear"
        ></wangnian-qianshou-fenbu>
      </div>
    </div>
  </layout>
</template>

<script>
import QianshouRank from "./components/qianshouRank/QianshouRank";
import TotalMoney from "./components/totalMoney/TotalMoney.vue";
import BennianQianshou from "./components/bennianqianshou/BennianQianshou";
import DuibiBox from "./components/duibibox/DuibiBox";
import BennianQianshouFenbu from "./components/bennianqianshoufenbu/BennianQianshouFenbu";
import WangnianQianshouFenbu from "./components/wangnianQianshouFenbu/WangnianQianshouFenbu";
import WangnianQianshou from "./components/wangnianQianshou/WangnianQianshou";

import { mapState } from "vuex";
import financialApi from "@/api/company/financial";

export default {
  name: "Index",
  components: {
    QianshouRank,
    TotalMoney,
    BennianQianshou,
    WangnianQianshou,
    DuibiBox,
    BennianQianshouFenbu,
    WangnianQianshouFenbu,
  },
  data() {
    return {
      totalMoney: { isLoading: false, datum: null },
      debt: {
        isLoading: false,
        datum: null,
        datumRank: null,
        datumMonth: null,
        datumOldYear: null,
      },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getSummaryOfDebt(); //营收欠款
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        return state.curOrg ? state.curOrg : { path: [], id: 1, type: 0 };
      },
    }),
  },
  methods: {
    formatDebt() {
      if (this.debt.datum.org_list) {
        let orgList = this.debt.datum.org_list;
        for (let key in orgList) {
          let tagList = orgList[key].total.tag_list;
          if (tagList) {
            for (let key2 in tagList) {
              if (tagList.hasOwnProperty(key2)) {
                if (!this.debt.datumRank) {
                  this.debt.datumRank = {};
                }
                if (!this.debt.datumRank[key2]) {
                  this.debt.datumRank[key2] = [];
                }
                this.debt.datumRank[key2].push({
                  name: orgList[key].name,
                  amount: orgList[key].total.tag_list[key2],
                });
              }
            }
          }
        }
      }
    },
    formatDebtToMonth() {
      if (this.debt.datum.month_list) {
        let monthList = this.debt.datum.month_list;
        for (let key in monthList) {
          let tagList = monthList[key].tag_list;
          if (tagList) {
            for (let key2 in tagList) {
              if (tagList.hasOwnProperty(key2)) {
                if (!this.debt.datumMonth) {
                  this.debt.datumMonth = {};
                }
                if (!this.debt.datumMonth[key2]) {
                  this.debt.datumMonth[key2] = [];
                }
                this.debt.datumMonth[key2].push({
                  name: `${key}月`,
                  amount: monthList[key].tag_list[key2],
                });
              }
            }
          }
        }
      }
    },
    formatDebtToOldYear() {
      if (this.debt.datum.year_list) {
        let yearList = this.debt.datum.year_list;
        for (let key in yearList) {
          let tagList = yearList[key].tag_list;
          if (tagList) {
            for (let key2 in tagList) {
              if (tagList.hasOwnProperty(key2)) {
                if (!this.debt.datumOldYear) {
                  this.debt.datumOldYear = {};
                }
                if (!this.debt.datumOldYear[key2]) {
                  this.debt.datumOldYear[key2] = [];
                }
                this.debt.datumOldYear[key2].push({
                  name: `${key}年`,
                  amount: yearList[key].tag_list[key2],
                });
              }
            }
          }
        }
      }
    },
    getSummaryOfDebt() {
      this.debt.isLoading = true;
      financialApi.debt
        .getSummaryOfDebt(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.debt.datum = res.data.datum;
            this.formatDebt();
            this.formatDebtToMonth();
            this.formatDebtToOldYear();
          },
          (err) => {
            // this.income.isLoading = false;
          }
        )
        .finally(() => {
          this.debt.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
