<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      option: {
        // tooltip: {
        //   trigger: "axis",
        //   formatter: "{a}:{c}%",
        //   axisPointer: {
        //     // 坐标轴指示器，坐标轴触发有效
        //     type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
        //   },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        color: [
          new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: "#4787c9" },
            { offset: 0.7, color: "#63bfca" },
            { offset: 1, color: "#7ff5cf" },
          ]),
          new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: "#008ef1" },
            { offset: 0.7, color: "#004f89" },
            { offset: 1, color: "#014577" },
          ]),
          "#101f39",
          "#0b1e54",
          "#00bcf8",
          "#006de7",
          "#00bbf7",
          "#00c1a8",
          "#0f1c41",
          "#756756",
          "#7595f9",
          "#182c5f",
          "#0b1e54",
        ],
        legend: {
          data: [],
          textStyle: {
            color: "#fff",
          },
        },
        textStyle: {
          color: "#fff",
        },
        grid: {
          left: "0%",
          right: "5%",
          bottom: "3%",
          top: "17%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [],
      },
    };
  },
  mounted() {
    if (this.chartData.tooltip) {
      this.option.tooltip = this.chartData.tooltip;
    }
    if (this.chartData.grid) {
      this.option.grid = this.chartData.grid;
    }
    if (this.chartData.series) {
      this.option.series = this.chartData.series;
    }
    if (this.chartData.dataOfLegend) {
      this.option.legend.data = this.chartData.dataOfLegend;
    }
    this.option.yAxis.data = this.chartData.dataOfYaxis;
    this.myChart = echarts.init(document.getElementById(this.id));
    this.myChart.setOption(this.option);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
