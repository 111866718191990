import common from '../common';
export default {
    // 设备数量
    countOfDevice({
        organization_id = 1
    }) {
        return common.get('bi/facility/device/count', {
            organization_id: organization_id
        })
    },
    // 机房数量
    countOfRoom({
        organization_id = 1
    }) {
        return common.get('bi/facility/room/count', {
            organization_id: organization_id
        })
    },
    // 任务统计
    summaryOfTask({
        organization_id = 1
    }) {
        return common.get('bi/facility/task/summary', {
            organization_id: organization_id
        })
    },
    // 超时预警
    reportOfTimeout({
        organization_id = 1
    }) {
        return common.get('bi/facility/timeout/report', {
            organization_id: organization_id
        })
    }
}