import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        curOrg: null
    },
    mutations: {
        orgSelected(state, curOrg) {
            state.curOrg = curOrg;
        }
    },
    modules: {
        user
    }
})
export default store;