import common from '../common';
export default {
    // 组织机构
    getOrg(param) {
        return common.get('bi/company/org/tree', param)
    },
    // 组织机构详情
    detailOfOrg(id) {
        return common.get('bi/company/org/detail/' + id)
    }
}