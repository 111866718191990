<template>
  <layout :navWidth="curOrg && curOrg.type == 1 ? '400px' : '45em'">
    <template v-if="curOrg && curOrg.type == 0">
      <div slot="nav" class="index__nav">
        <shouru-chart
          :isLoading="income.isLoading"
          :datum="income.datum"
        ></shouru-chart>
        <rank
          :datumOfCurrentBillRatio="RatioOfCurrentBill.datum"
          :isLoadingOfCurrentBillRatio="RatioOfCurrentBill.isLoading"
          :isLoadingOfIncome="income.isLoading"
          :datumOfIncome="income.datum"
          :curOrg="curOrg"
        ></rank>
        <yingshouqiankuan-chart
          :isLoading="debt.isLoading"
          :datum="debt.datum"
        ></yingshouqiankuan-chart>
      </div>
      <div slot="content" class="index__content">
        <div class="work_box">
          <div class="content">
            <shoukuan-chart
              :isLoading="charge.isLoading"
              :datum="charge.datum"
              :datumOfIncome="income.datum"
              :isLoadingOfIncome="income.isLoading"
              :height="curOrg && curOrg.type == 1 ? '100%' : '35%'"
              :curOrg="curOrg"
            ></shoukuan-chart>
            <map-chart
              :isLoading="distributionOfOrg.isLoading"
              :datum="distributionOfOrg.datum"
            ></map-chart>
            <map-data
              :isLoading="project.isLoading"
              :datum="project.datum"
            ></map-data>
          </div>
        </div>
      </div>
      <div slot="aside" class="index__aside">
        <renli-ziyuan
          :isLoading="renli.isLoading"
          :datum="renli.datum"
        ></renli-ziyuan>
        <zichan-guanli
          :isLoading="isResourceLoading"
          :datum="datumOfResource"
          :curOrg="curOrg"
        ></zichan-guanli>
        <gongcheng-yunwei
          :isLoading="isFacilityLoading"
          :data="datumOfFacility"
        ></gongcheng-yunwei>
      </div>
    </template>
    <template v-else>
      <div slot="nav" class="project_index__nav">
        <base-info
          :isLoading="org.isLoading"
          :datum="org.datum"
          :curOrg="curOrg"
        ></base-info>
      </div>
      <div slot="content" class="project_index__content">
        <div class="work_box shoukuan">
          <div class="content">
            <shoukuan-chart
              :isLoading="charge.isLoading"
              :datum="charge.datum"
              :height="curOrg && curOrg.type == 1 ? '100%' : '30%'"
            ></shoukuan-chart>
          </div>
        </div>
        <shouru-chart :isLoading="isLoading" :datum="datum"></shouru-chart>
      </div>
      <div slot="aside" class="project_index__aside">
        <renli-ziyuan
          :isLoading="renli.isLoading"
          :datum="renli.datum"
        ></renli-ziyuan>
        <zichan-guanli
          :isLoading="isResourceLoading"
          :datum="datumOfResource"
        ></zichan-guanli>
        <gongcheng-yunwei
          :isLoading="isFacilityLoading"
          :data="datumOfFacility"
        ></gongcheng-yunwei>
      </div>
    </template>
  </layout>
</template>

<script>
import { mapState } from "vuex";
import ShouruChart from "./components/shouruChart/ShouruChart";
import Rank from "./components/rank/Rank";
import ShoukuanChart from "./components/shoukuanChart/ShoukuanChart";
import YingshouqiankuanChart from "./components/yingshouqiankuanChart/YingshouqiankuanChart";
import MapChart from "./components/mapChart/MapChart";
import MapData from "./components/mapData/MapData";
import ZichanGuanli from "./components/zichanGuanli/ZichanGuanli";
import RenliZiyuan from "./components/renliZiyuan/RenliZiyuan";
import GongchengYunwei from "./components/gongchengYunwei/GongchengYunwei";
import financialApi from "@/api/company/financial";
import resourceApi from "@/api/company/resource";
import hrApi from "@/api/company/hr";
import facilityApi from "@/api/company/facility";
import projectApi from "@/api/company/project";
import distributionOfOrgApi from "@/api/company/org";
import orgApi from "@/api/org/org";
import BaseInfo from "@/components/common/baseInfo/BaseInfo";

export default {
  name: "Index",
  components: {
    ShouruChart,
    ShoukuanChart,
    MapChart,
    MapData,
    RenliZiyuan,
    GongchengYunwei,
    ZichanGuanli,
    Rank,
    BaseInfo,
    YingshouqiankuanChart,
  },
  data() {
    return {
      isLoading: false,
      isRatioLoading: false,
      isResourceLoading: false,
      isFacilityLoading: false,
      project: { isLoading: false, datum: null },
      distributionOfOrg: { isLoading: false, datum: null },
      renli: { isLoading: false, datum: null },
      datum: null,
      datumOfRatio: null,
      datumOfFacility: null,
      charge: { isLoading: false, datum: null },
      datumOfResource: null,
      org: { isLoading: false, datum: null },
      income: { isLoading: false, datum: null },
      debt: { isLoading: false, datum: null },
      RatioOfCurrentBill: { isLoading: false, datum: null },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        if (newVal) {
          // this.getSummaryOfBill(); // 收缴率统计
          this.getSummaryOfIncome(); // 营收收款
          this.getSummaryOfDebt(); //营收欠款
          this.getRatioOfBill(); // 收缴率统计
          this.getRatioOfCurrentBill(); //当年收缴率
          this.getSummaryOfHr(); // 人力资源概况
          this.getSummaryOfCharge(); // 收费数据
          this.getSummaryOfResource(); // 资产经营数据
          this.getSummaryOfFacility(); // 工程运维数据
          if (this.curOrg.type == 0) {
            this.getSummaryOfProject(); // 项目情况统计
            this.getDistributionOfOrg(); // 下级组织城市分布情况
          }
          this.getDetailOfOrg();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        return state.curOrg ? state.curOrg : null;
      },
    }),
  },
  mounted() {
    // this.getSummaryOfBill(); // 收缴率统计
    // this.getRatioOfBill(); // 收缴率统计
    // this.getSummaryOfHr(); // 人力资源概况
    // this.getSummaryOfCharge(); // 收费数据
    // this.getSummaryOfResource(); // 资产经营数据
    // this.getSummaryOfFacility(); // 工程运维数据
    // this.getSummaryOfProject(); // 项目情况统计
    // this.getDistributionOfOrg(); // 下级组织城市分布情况
  },
  methods: {
    getDetailOfOrg() {
      this.org.isLoading = true;
      orgApi.detailOfOrg(this.curOrg.id).then(
        (res) => {
          this.org.isLoading = false;
          this.org.datum = res.data.datum;
        },
        (err) => {
          this.org.isLoading = false;
        }
      );
    },
    getDistributionOfOrg() {
      this.distributionOfOrg.isLoading = true;
      distributionOfOrgApi
        .distributionOfOrg({ organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.distributionOfOrg.isLoading = false;
            this.distributionOfOrg.datum = res.data.datum;
          },
          (err) => {
            this.distributionOfOrg.isLoading = false;
          }
        );
    },
    getSummaryOfProject() {
      this.project.isLoading = true;
      projectApi.summaryOfProject({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.project.isLoading = false;
          this.project.datum = res.data.datum;
        },
        (err) => {
          this.project.isLoading = false;
        }
      );
    },
    getSummaryOfFacility() {
      this.isFacilityLoading = true;
      facilityApi
        .getSummaryOfFacility(
          this.curOrg && { organization_id: this.curOrg.id }
        )
        .then(
          (res) => {
            this.datumOfFacility = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.isFacilityLoading = false;
        });
    },
    getSummaryOfResource() {
      this.isResourceLoading = true;
      resourceApi
        .getSummaryOfResource(
          this.curOrg && { organization_id: this.curOrg.id }
        )
        .then(
          (res) => {
            this.datumOfResource = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.isResourceLoading = false;
        });
    },
    getSummaryOfIncome() {
      this.income.isLoading = true;
      financialApi.income
        .getSummaryOfIncome(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.income.datum = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.income.isLoading = false;
        });
    },
    getSummaryOfDebt() {
      this.debt.isLoading = true;
      financialApi.debt
        .getSummaryOfDebt(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.debt.datum = res.data.datum;
          },
          (err) => {
            // this.income.isLoading = false;
          }
        )
        .finally(() => {
          this.debt.isLoading = false;
        });
    },
    getSummaryOfBill() {
      this.isLoading = true;
      financialApi.bill
        .getSummaryOfBill(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.datum = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    getRatioOfBill() {
      this.isRatioLoading = true;
      financialApi.bill
        .getRatioOfBill(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.datumOfRatio = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.isRatioLoading = false;
        });
    },
    getRatioOfCurrentBill() {
      this.RatioOfCurrentBill.isLoading = true;
      financialApi.bill
        .getRatioOfCurrentBill(
          this.curOrg && { organization_id: this.curOrg.id }
        )
        .then(
          (res) => {
            this.RatioOfCurrentBill.datum = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.RatioOfCurrentBill.isLoading = false;
        });
    },
    getSummaryOfHr() {
      this.renli.isLoading = true;
      hrApi
        .getSummaryOfHr(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.renli.isLoading = false;
            this.renli.datum = res.data.datum;
          },
          (err) => {
            this.renli.isLoading = false;
          }
        );
    },
    getSummaryOfCharge() {
      this.charge.isLoading = true;
      financialApi.charge
        .getSummaryOfCharge(this.curOrg && { organization_id: this.curOrg.id })
        .then(
          (res) => {
            this.charge.datum = res.data.datum;
          },
          (err) => {}
        )
        .finally(() => {
          this.charge.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
