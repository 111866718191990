import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Login from "../views/login/Index.vue";
import Index from "../views/index/Index.vue";
import Renliziyuan from './renliziyuan'
import Zichan from './zichan'
import Gongcheng from './gongcheng'
import Yunying from './yunying'
import Yingshouqiankuan from './yingshouqiankuan'
import XianjinShoujiaolv from './xianjinshoujiaolv'


export default new Router({
    routes: [{
            path: '/',
            name: "index",
            component: Index
        },
        {
            path: '/login',
            name: "login",
            component: Login
        },
        ...Renliziyuan,
        ...Zichan,
        ...Gongcheng,
        ...Yunying,
        ...Yingshouqiankuan,
        ...XianjinShoujiaolv
    ]
})