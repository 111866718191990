<template>
  <div id="jiankong" style="width:950px;height:700px"></div>
</template>
<script>
import EZUIKit from "ezuikit-js";

export default {
  name: "VideoVomponent",
  props: ["isPlayerNull", "curVideo"],
  data() {
    return { player: null };
  },
  watch: {
    isPlayerNull(data) {
      if (!data) {
        this.player = data;
        // this.showVideo();
      }
    },
  },
  mounted() {
    this.showVideo();
  },
  methods: {
    showVideo() {
      console.log("show的时候", this.player, this.curVideo.url);
      if (this.player != null) return;
      this.player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: "jiankong",
        accessToken: this.curVideo.accessToken,
        url: this.curVideo.url,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        width: 950,
        height: 700,
      });
      this.$emit("update:isPlayerNull", this.player);
    },
  },
};
</script>
