<template>
  <div class="touzi_fenhong_container">
    <div class="work_box">
      <div class="title">投资分红</div>
      <div class="content">
        <div class="touzi">
          <div class="chart">
            <pie-echarts
              v-if="!isLoading"
              :chartData="touziChartData"
              id="touzi"
              :className="'pie__chart'"
            ></pie-echarts>
          </div>
          <div class="data">
            <div
              class="item"
              v-for="(item, index) in touziChartData.dataOfSeries"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <span>{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="fenhong">
          <div class="chart">
            <pie-echarts
              v-if="!isLoading"
              :chartData="fenhongChartData"
              id="fenhong"
              :className="'pie__chart'"
            ></pie-echarts>
          </div>
          <div class="data">
            <div
              class="item"
              v-for="(item, index) in touziChartData.dataOfSeries"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <span>{{ item.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ZulinJingying",
  props: ["datum", "isLoading"],
  watch: {
    datum(data) {
      if (data) {
        this.formatData();
      }
    },
  },
  data() {
    return {
      touziChartData: {
        dataOfSeries: [
          { value: 335, name: "公司1" },
          { value: 310, name: "公司2" },
          { value: 234, name: "公司3" },
          { value: 135, name: "公司4" },
          { value: 1548, name: "公司5" },
        ],
        nameOfSeries: "投资情况",
        posOfChart: ["45%", "50%"],
        radiusOfSeries: "50%",
      },
      fenhongChartData: {
        dataOfSeries: [
          { value: 335, name: "公司1" },
          { value: 310, name: "公司2" },
          { value: 234, name: "公司3" },
          { value: 135, name: "公司4" },
          { value: 1548, name: "公司5" },
        ],
        nameOfSeries: "分红情况",
        posOfChart: ["45%", "50%"],
        radiusOfSeries: "50%",
      },
    };
  },
  methods: {
    formatData() {},
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>