import common from '../common';
export default {
    // 租金收缴情况
    propertyOfBill(param) {
        return common.get('bi/property/bill/summary', param)
    },
    // 房源信息概况
    propertyOfResource(param) {
        return common.get('bi/property/resource/summary', param)
    }
}