import common from '../common';
export default {
    // 年龄人数统计
    summaryOfAge(param) {
        return common.get('bi/hr/age/summary', param)
    },
    // 部门岗位人数统计
    summaryOfDepartment(param) {
        return common.get('bi/hr/department/summary', param)
    },
    // 用工性质人数统计
    summary_CountOfJobType(param) {
        return common.get('bi/hr/job/type/summary', param)
    },
    // 性别人数统计
    summaryOfSex(param) {
        return common.get('bi/hr/sex/summary', param)
    },
    // 每月在职员工数量
    summaryOfMonth(param) {
        return common.get('bi/hr/staff/month/summary', param)
    },
    // 职级结构
    nameOfJob(param) {
        return common.get('bi/hr/special/job/name/summary', param)
    },
    // 职级变化趋势
    trendOfNameChange(param) {
        return common.get('bi/hr/special/job/name/trend', param)
    },
    // 学历结构
    summaryOfEducation(param) {
        return common.get('bi/hr/education/summary', param)
    },
    // 学历变化趋势
    summaryOfEducationChange(param) {
        return common.get('bi/hr/education/trend', param)
    },
    // 增员减员
    summaryOfAddMinus(param) {
        return common.get('bi/hr/changes/summary', param)
    }
}