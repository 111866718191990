import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import $ from "jquery";
import './plugins/frame.js'

import './plugins/element.js';
import './plugins/bootstrap.js';
import "./style/table.scss";
import "./style/common.scss";
import "./style/font.scss";
import './utils/filter'

import layout from "@/components/common/layout/Layout";
import topContainer from "@/components/common/topContainer/TopContainer";
import contentContainer from "@/components/common/contentContainer/ContentContainer";
import bottomContainer from "@/components/common/bottomContainer/BottomContainer";
import echarts from "@/components/charts/echarts";
import PieEcharts from "@/components/charts/pieEcharts/pieEcharts";
import HuanEcharts from "@/components/charts/huanEcharts/huanEcharts";
import BarEcharts from "@/components/charts/barCharts/barEcharts";
import HorBarEcharts from "@/components/charts/horBarEcharts/HorBarEcharts";
import HorBarMulEcharts from "@/components/charts/horBarMulEcharts/HorBarMulEcharts";
import MapEcharts from "@/components/charts/mapEcharts/MapEcharts";
import CityMapEcharts from "@/components/charts/cityMapEcharts/CityMapEcharts";
import LineEcharts from "@/components/charts/lineEcharts/LineEcharts";


Vue.config.productionTip = false;
Vue.config.productionTip = false;
Vue.component('layout', layout);
Vue.component('topContainer', topContainer);
Vue.component('contentContainer', contentContainer);
Vue.component('bottomContainer', bottomContainer);
Vue.component('echarts', echarts);
Vue.component('PieEcharts', PieEcharts);
Vue.component('HuanEcharts', HuanEcharts);
Vue.component('BarEcharts', BarEcharts);
Vue.component('HorBarEcharts', HorBarEcharts);
Vue.component('HorBarMulEcharts', HorBarMulEcharts);
Vue.component('MapEcharts', MapEcharts);
Vue.component('CityMapEcharts', CityMapEcharts);
Vue.component('LineEcharts', LineEcharts);
Vue.prototype.$ = $
if (process.env.NODE_ENV !== 'production')
    Vue.config.devtools = true;
window.Bus = new Vue();
window.app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');