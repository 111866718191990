<template>
  <div class="_card">
    <span class="title">{{ title }}</span>
    <section>
      <span class="num">{{ num }}</span>
      <span class="unit">{{ unit }}</span>
    </section>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: ["title", "num", "unit"],
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>