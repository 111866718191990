import common from '../common';
export default {
    bill: {
        // 收缴率统计
        getRatioOfBill(param) {
            return common.get('bi/company/financial/bill/ratio', param)
        },
        // 当年收缴率
        getRatioOfCurrentBill(param) {
            return common.get('bi/company/current/bill/ratio', param)
        },
        // 旧欠收缴率
        getRatioOfOldBill(param) {
            return common.get('bi/company/old/bill/ratio', param)
        },
        // 营收数据（应收）
        getSummaryOfBill(param) {
            return common.get('bi/company/financial/bill/summary', param)
        }
    },
    income: {
        // 营收收款统计
        getSummaryOfIncome(param) {
            return common.get('bi/company/income/summary', param)
        },
    },
    debt: {
        // 营收欠款统计
        getSummaryOfDebt(param) {
            return common.get('bi/company/debt', param)
        },
    },
    charge: {
        // 收费数据
        getSummaryOfCharge(param) {
            return common.get('bi/company/financial/charge/summary', param)
        }
    }

}