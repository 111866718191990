import Vue from 'vue';
import {mapGetters,mapState} from 'vuex';
// import BsModal from '@/components/common/BsModal';
import NumInput from '@/components/common/NumInput';

import Frame from '@/api/frame';

Vue.use({
    install(Vue) {
        // Vue.component('bs-modal', BsModal);
        Vue.component('num-input', NumInput);
        Vue.prototype.$framework = Frame;
        Vue.prototype.$permCheck = function() {
            if(!this.$userId)
                return false;
            if(this.$isSuper)
                return true;
            const acl = this.$acl;
            if(!acl)
                return false;
            let pageId, op;
            if(arguments.length == 1) {
                pageId = this.$route.meta.pageId;
                op = arguments[0];
            }
            else if(arguments.length == 2) {
                pageId = arguments[0];
                op = arguments[1];
            }
            return acl[pageId] && acl[pageId][op];
        };
        Vue.mixin({
            computed: {
                '$routeMeta'() {
                    return this.$route.meta;
                },
                // Vuex的user命名空间下的current映射到$currentUser
                ...mapState('user', {
                    '$currentUser': 'current'
                }),
                ...mapGetters('user', {
                    '$userId': 'id',
                    '$isSuper': 'isSuper',
                    '$acl': 'acl'
                })
            }
        });
    }
});
