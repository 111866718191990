import Vue from 'vue'
import Element from 'element-ui'
// import ReadonlyButton from "@/components/ReadonlyButton";
import 'element-ui/lib/theme-chalk/index.css'
import './style.css';
Element.Dialog.props.closeOnClickModal.default = false;
Element.Dialog.props.closeOnPressEscape.default = false;
Element.Dialog.props.appendToBody.default = true;
Element.Dialog.props.top.default = '1.75em';
Vue.use(Element)
// Vue.component(ReadonlyButton.name, ReadonlyButton);