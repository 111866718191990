<template>
  <div class="qushi_of_chengben_container">
    <div class="work_box">
      <div class="title">人均工资趋势</div>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="ChengbenOfRenjun"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChengbenOfRenjun",
  props: ["isLoading", "datum"],
  watch: {
    datum() {
      if (this.datum) {
        this.formatData(); //各年不同工种
      }
    },
  },
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%",top:'0' },
      },
    };
  },
  methods: {
    formatData() {
      this.chartData.series = [];
      this.chartData.dataOfLegend = [];

      for (let key in this.datum.job_type_count_list) {
        // 初始化legend
        this.chartData.dataOfLegend.push(key);
        // 初始化 series的data
        let obj = {
          name: key,
          type: "bar",
          data: [],
        };
        this.chartData.dataOfXaxis = [];
        for (let key2 in this.datum.year_cost_list) {
          obj.data.push(this.datum.year_cost_list[key2].job_type_list[key].avg);
          // 初始化x轴数据
          this.chartData.dataOfXaxis.push(key2 + "年");
        }
        this.chartData.series.push(obj);
        console.log(this.chartData);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.qushi_of_chengben_container {
  & > .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>