<template>
  <div class="top_container">
    <div class="time">
      <el-breadcrumb separator="/" v-if="curRoute.url != '/login'">
        <el-breadcrumb-item>
          <router-link :to="'/'">首页</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="curRoute.name != '首页' && curRoute.name != 'index'"
          ><router-link :to="curRoute.url">{{ curRoute.name }}</router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title">智慧物业管理驾驶舱</div>
    <div class="org">
      <template v-if="isOrgShow && curRoute.url != '/login'">
        <el-cascader
          v-model="orgPath"
          :options="options"
          @change="handleChange"
          :props="{ checkStrictly: true }"
          clearable
        ></el-cascader>
      </template>
    </div>
  </div>
</template>
<script>
import base from "@/utils/base";
import orgApi from "@/api/org/org";

export default {
  name: "ContentContainer",
  props: {
    routePath: {
      type: String,
      default: "",
    },
    curRoute: {
      type: Object,
      default: () => ({
        name: "首页",
        url: "/",
      }),
    },
    isOrgShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orgPath: [],
      options: [],
      orgType: "0,1",
    };
  },
  watch: {
    curRoute: {
      handler(val, oldVal) {
        this.getOrg();
      },
      deep: true,
    },
    routePath: {
      handler(val, oldVal) {
        if (val) {
          this.orgPath = base.strToArr(val, ",").map((item) => {
            return !isNaN(Number(item)) && Number(item);
          });
          this.commitOrg(this.orgPath);
        }
        if (this.$route.path == "/") {
          // 回首页的时候将组织回到集团
          this.$route.query["path"] = `${this.options[0].value}`;

          this.orgPath = base
            .strToArr(this.$route.query["path"], ",")
            .map((item) => {
              return !isNaN(Number(item)) && Number(item);
            });
          this.commitOrg(this.orgPath);
        }
      },
      deep: true,
    },
  },
  created() {
    this.getOrg();
  },
  methods: {
    formatOption(options) {
      options.forEach((item) => {
        item.disabled =
          this.curRoute.url == "/yingshouqiankuan" && item.type == 1
            ? true
            : false;
        if (item.children && item.children.length) {
          this.formatOption(item.children);
        }
      });
    },
    clearCurOrg() {
      window.sessionStorage.clear("curOrgPath");
      window.sessionStorage.clear("curOrgId");
      window.sessionStorage.clear("curOrgType");
    },
    commitOrg(arr) {
      this.$store.commit("orgSelected", {
        path: arr,
        id: arr[arr.length - 1],
        type: this.getType(this.options, arr[arr.length - 1]),
      });
    },
    handleChange(value) {
      const idArr = value.length ? value : [this.options[0].value];
      // 改变vuex
      this.commitOrg(idArr);
      // 第二步：改变路由参数
      this.$route.query["path"] = idArr.join(",");
      const idObj = {
        path: idArr,
        id: idArr[idArr.length - 1],
        type: this.getType(this.options, idArr[idArr.length - 1]),
      };
      window.sessionStorage.setItem("curOrgPath", idObj.path.join(","));
      window.sessionStorage.setItem("curOrgId", idObj.id);
      window.sessionStorage.setItem("curOrgType", idObj.type);
    },
    formatData(resData) {
      let arr = [];
      resData.forEach((item) => {
        let obj = {
          value: item.id,
          label: item.name,
          type: item.type,
        };
        arr.push(obj);
        if (item.nodes && item.nodes.length) {
          obj.children = this.formatData(item.nodes);
        }
      });
      return arr;
    },
    getType(options, id) {
      let type = null;
      for (let i = 0; i < options.length; i++) {
        const item = options[i];
        if (item.value == id) {
          type = item.type;
          break;
        }
        if (item.children && item.children.length) {
          type = this.getType(item.children, id);
          if (type !== null) return type;
        }
      }
      return type;
    },
    getOrg() {
      orgApi.getOrg({ type: this.orgType }).then(
        (res) => {
          if (res.data.data && res.data.data.length) {
            this.options = this.formatData(res.data.data);
            if (
              this.$route.path != "/" &&
              window.sessionStorage.getItem("curOrgId")
            ) {
              const idObj = {
                path: window.sessionStorage.getItem("curOrgPath"),
                id: window.sessionStorage.getItem("curOrgId"),
                type: window.sessionStorage.getItem("curOrgType"),
              };
              this.$route.query["path"] = idObj.path;
            } else {
              window.sessionStorage.setItem(
                "curOrgPath",
                `${this.options[0].value}`
              );
              window.sessionStorage.setItem("curOrgId", this.options[0].value);
              window.sessionStorage.setItem("curOrgType", this.options[0].type);
              this.$route.query["path"] = `${this.options[0].value}`;
            }
            this.orgPath = base
              .strToArr(this.$route.query["path"], ",")
              .map((item) => {
                return !isNaN(Number(item)) && Number(item);
              });
            if (!this.$store.state.curOrg) {
              this.commitOrg(this.orgPath);
            }
            // 与路由有关
            if (this.curRoute.url == "/") {
              this.clearCurOrg();
              this.$route.query["path"] = `${this.options[0].value}`;
              this.orgPath = base
                .strToArr(this.$route.query["path"], ",")
                .map((item) => {
                  return !isNaN(Number(item)) && Number(item);
                });
              this.commitOrg(this.orgPath);
            } else if (this.curRoute.url == "/yingshouqiankuan") {
              this.formatOption(this.options);
              console.log("当前的组织架构是", this.options);
            } else {
            }
          }
        },
        (err) => {}
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
