<template>
  <div class="jiankong_yujing_container">
    <div class="jiankong">
      <div
        class="work_box"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="content">
          <div v-for="(value, key) in type" :key="key">
            <h5>{{ value.name }}</h5>
            <div class="con">
              <div class="item">
                <div v-for="(item, index) in value.data" :key="index">
                  {{ `${item.org.name}-${item.title}超时` }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nenghao">
      <div class="work_box">
        <div class="title">能耗分析</div>
        <div class="content">
          <bar-echarts
            v-if="!isLoading"
            :chartData="chartData"
            id="nenghao_project"
            :className="'bar_chart'"
          ></bar-echarts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeOut",
  props: ["data", "isLoading"],
  data() {
    return {
      type: {
        0: {
          name: "养护超时预警",
          data: [],
        },
        1: {
          name: "巡检超时预警",
          data: [],
        },
      },
      chartData: {
        series: [
          {
            name: "水",
            data: [120, 200, 150, 80, 70, 110, 130, 200, 150, 80, 70, 100],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
          },
          {
            name: "电",
            data: [120, 200, 150, 80, 70, 110, 130, 200, 150, 80, 70, 100],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
          },
          {
            name: "气",
            data: [120, 200, 150, 80, 70, 110, 130, 200, 150, 80, 70, 100],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },
          },
        ],
        dataOfLegend: [],
        dataOfXaxis: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        grid: { right: "0%", bottom: "20%", left: "0%", top: "15%" },
      },
    };
  },
  watch: {
    data: {
      handler() {
        if (this.data && this.data.length) {
          this.formatData();
        }
      },
      deep: true,
    },
  },
  methods: {
    formatData() {
      this.data.forEach((item) => {
        this.type[item.planType].data.push(item);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>