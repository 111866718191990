<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      option: {
        grid: {
          right: "0%",
          bottom: "10%",
          left: "0%",
        },
        color: [
          "#007ce8",
          "#00d5b8",
          "#00bcf8",
          "#006de7",
          "#00bbf7",
          "#00c1a8",
          "#0f1c41",
          "#756756",
          "#7595f9",
          "#182c5f",
        ],
        textStyle: {
          color: "#3a8dec",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
          data: [],
          padding: 10,
          textStyle: {
            color: "#fff",
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: [],
            axisLine: {
              show: false,
            },
          },
        ],
        // yAxis: [
        //   {
        //     type: "value",
        //     axisLine: {
        //       show: false,
        //     },
        //   },
        // ],
        yAxis: {
          show: false,
        },
        series: [],
      },
    };
  },
  mounted() {
    if (this.chartData.tooltip) {
      this.option.tooltip = this.chartData.tooltip;
    }
    if (this.chartData.series) {
      this.option.series = this.chartData.series;
    }
    if (this.chartData.dataOfLegend) {
      this.option.legend.data = this.chartData.dataOfLegend;
    }
    this.option.xAxis[0].data = this.chartData.dataOfXaxis;
    if (this.chartData.axisLabel) {
      this.option.xAxis[0].axisLabel = this.chartData.axisLabel;
    }
    if (this.chartData.grid) {
      this.option.grid = this.chartData.grid;
    }
    this.myChart = echarts.init(document.getElementById(this.id));
    this.myChart.setOption(this.option);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
