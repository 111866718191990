<template>
  <div class="shishou_rank_container">
    <div class="work_box">
      <div class="title">实收金额排行</div>
      <div class="content">
        <hor-bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          :className="'hor_bar_chart'"
          id="company_sjl1"
        ></hor-bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShishouRank",
  props: ["isLoading", "datum"],
  watch: {
    datum(data) {
      if (data && data.org_list) {
        this.formatChart();
      }
    },
  },
  data() {
    return {
      chartData: {
        series: [],
        // dataOfLegend: [],
        dataOfYaxis: [],
        grid: {
          left: "1%",
          right: "5%",
          bottom: "0%",
          top: "5%",
          containLabel: true,
        },
      },
    };
  },
  methods: {
    formatChart() {
      if (this.datum.org_list) {
        let orgList = this.datum.org_list;
        // 从小到大排序
        orgList = Object.entries(orgList).sort((a, b) => {
          return a[1].year.total - b[1].year.total;
        });
        this.chartData.dataOfYaxis = [];
        this.chartData.series = [];
        orgList.forEach((itm) => {
          this.chartData.dataOfYaxis.push(itm[1].name);
          if (itm[1].year.tag_list) {
            for (let key in itm[1].year.tag_list) {
              let obj = {
                name: key,
                type: "bar",
                stack: "率",
                label: {
                  show: false,
                  position: "center",
                },
                data: [],
              };
              obj.data.push(Number(itm[1].year.tag_list[key].amount));
              this.chartData.series.push(obj);
            }
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
