<template>
  <div class="index__container">
    <div
      class="work_box"
      v-loading="isLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="title">
        <span>营收收款</span>
        <router-link
          :to="{
            name: 'yunying',
            query: $route.query,
          }"
          >前往 >></router-link
        >
      </div>
      <div class="content">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item
            :label="value.name"
            v-for="(value, key) in dataOfShoukuan"
            :key="key"
          >
            <div class="data_container">
              <div class="_left">
                <pie-echarts
                  v-if="!isLoading"
                  :chartData="value.chartData"
                  :id="value.name"
                  :className="'pie__chart'"
                ></pie-echarts>
              </div>
              <div class="_right">
                <div v-for="item in value.options" :key="item.name">
                  <h4
                    class="dot"
                    :style="{ 'background-color': item.bgColor }"
                  ></h4>
                  <span>{{ item.name }}：{{ item.value }}</span>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YingShou",
  props: ["isLoading", "datum", "curOrg"],
  watch: {
    datum(data) {
      if (data) {
        this.formatChart();
      }
    },
  },
  data() {
    return {
      colorList: [
        "#0089f8",
        "#9bf8fe",
        "#1baff9",
        "#0073ed",
        "#1e1988",
        "#3f5b9b",
        "#B17CFF",
        "#2576ac",
        "#79a3bd",
      ],
      dataOfShoukuan: {
        week: {
          name: "本周",
          options: [],
          chartData: {
            dataOfSeries: [],
            posOfChart: ["50%", "50%"],
            radiusOfSeries: "60%",
            // legend: {
            //   orient: "horizontal",
            //   data: [],
            //   bottom: 0,
            //   textStyle: {
            //     color: "#fff",
            //   },
            // },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b}: {c} ({d}%)",
            },
          },
        },
        month: {
          name: "本月",
          options: [],
          chartData: {
            dataOfSeries: [],
            posOfChart: ["50%", "50%"],
            radiusOfSeries: "60%",
            // legend: {
            //   orient: "horizontal",
            //   data: [],
            //   bottom: 0,
            //   textStyle: {
            //     color: "#fff",
            //   },
            // },
          },
        },
        year: {
          name: "本年",
          options: [],
          chartData: {
            dataOfSeries: [],
            posOfChart: ["50%", "50%"],
            radiusOfSeries: "60%",
            // legend: {
            //   orient: "horizontal",
            //   data: [],
            //   bottom: 0,
            //   textStyle: {
            //     color: "#fff",
            //   },
            // },
          },
        },
      },
    };
  },
  methods: {
    formatChart() {
      for (let key in this.dataOfShoukuan) {
        let values = this.dataOfShoukuan[key];
        values.chartData.dataOfSeries = [];
        values.options = [];
        if (
          this.datum.total &&
          this.datum.total[key] &&
          this.datum.total[key].tag_list
        ) {
          const tagList = this.datum.total[key].tag_list;
          for (let key2 in tagList) {
            let values2 = tagList[key2];
            let obj = {
              name: key2,
              value: values2.amount,
            };
            values.chartData.dataOfSeries.push(obj);
            // values.chartData.legend.data.push(key2);
            values.options.push(obj);
          }
        }
        if (values.options.length) {
          values.options.forEach((itm, i) => {
            itm.bgColor = this.colorList[i];
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
@import "~@/style/carousel.scss";
</style>
