<template>
  <div class="count_of_zhigong_container">
    <div class="work_box">
      <div class="title">职工人数</div>
      <div class="content">
        <pie-echarts
          v-if="!isLoading"
          :chartData="countChartData"
          id="count_pie_chart"
          :className="'pie__chart'"
        ></pie-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CountOfZhigongProject",
  props: ["isLoading", "datum"],
  data() {
    return {
      countChartData: {
        dataOfSeries: [],
        posOfChart: ["40%", "50%"],
        radiusOfSeries: "50%",
        // legend: {
        //   orient: "horizontal",
        //   data: [],
        //   bottom: 0,
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
      },
    };
  },
  watch: {
    datum() {
      this.formatCountData();
    },
  },
  methods: {
    formatCountData() {
      this.countChartData.dataOfSeries = [];
      // this.countChartData.legend.data = [];
      if (this.datum.job_type_list && this.datum.job_type_list.length) {
        this.datum.job_type_list.forEach((item) => {
          let obj = { name: item.name, value: item.count };
          this.countChartData.dataOfSeries.push(obj);
          // this.countChartData.legend.data.push(item.name);
        });
      }

      console.log(this.rentableAreaChartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>