<template>
  <!-- 圣杯布局 Holy Grail Layout -->
  <div class="layout">
    <nav class="HolyGrail-nav" :style="{ flex: `0 0 ${navWidth}` }">
      <slot name="nav"></slot>
    </nav>
    <main class="HolyGrail-content">
      <slot name="content"></slot>
    </main>
    <aside
      class="HolyGrail-aside"
      v-if="isAsideShow"
      :style="{ flex: `0 0 ${asideWidth}` }"
    >
      <slot name="aside"></slot>
    </aside>
  </div>
</template>
<script>
export default {
  name: "Layout",
  props: {
    isAsideShow: { type: Boolean, default: true },
    navWidth: { type: String, default: "40em" },
    asideWidth: { type: String, default: "35em" },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>