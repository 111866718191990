<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
import chinaData from "@/assets/js/china";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      option: {
        // legend: { type: "scroll" },
        // backgroundColor: "#FFFFFF",
        // title: {
        //   text: "全国地图大数据",
        //   subtext: "",
        //   x: "center",
        // },
        // tooltip: {
        //   trigger: "item",
        // },
        //左侧小导航图标
        // visualMap: {
        //   show: true,
        //   x: "left",
        //   y: "center",
        //   splitList: [
        //     { start: 500, end: 600 },
        //     { start: 400, end: 500 },
        //     { start: 300, end: 400 },
        //     { start: 200, end: 300 },
        //     { start: 100, end: 200 },
        //     { start: 0, end: 100 },
        //   ],
        //   color: [
        //     "#5475f5",
        //     "#9feaa5",
        //     "#85daef",
        //     "#74e2ca",
        //     "#e6ac53",
        //     "#9fb5ea",
        //   ],
        //   selectedMode: "multiple",
        // },

        //配置属性
        series: [
          {
            show: true,
            // selectedMode: "multiple",
            layoutCenter: ["-70%", "100%"],
            layoutSize: 2000,
            name: "数据",
            type: "map",
            map: "china",
            roam: false, //禁止缩放和拖动
            top: 5,
            bottom: 0,
            itemStyle: {
              normal: {
                //未选中状态
                // borderWidth: 1, //边框大小
                borderColor: "none",
                areaColor: "#05152e", //背景颜色
                // borderWidth: 1, //边框大小
                // borderColor: "#080e23",
                // areaColor: "#0b2464", //背景颜色
                label: {
                  show: true, //显示名称
                },
                color: "#fecb2e",
                // shadowBlur: 10,
                // shadowColor: "#0d5ebd",
              },
              emphasis: {
                // 也是选中样式
                borderWidth: "none",
                borderColor: "#fff",
                areaColor: "#05152e", //背景颜色
                shadowBlur: 10,
                shadowColor: "#0d5ebd",
                label: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                    fontSize: 9,
                  },
                },
              },
            },
            label: {
              normal: {
                show: false, //默认不显示省份名称
              },
              emphasis: {
                show: true,
              },
            },
            data: [
              // { name: "北京", selected: true, label: { show: true } }, // selected:true 默认选中
              // { name: "天津", selected: true, label: { show: true } }, // selected:true 默认选中
            ],
            silent: true, // true 禁止鼠标悬浮和点击效果
          },
        ],
      },
    };
  },
  mounted() {
    if (this.chartData) {
      this.option.series[0].data = [];
      let arr = ["北京", "天津", "河北"];
      if (this.chartData.cityOfChina) {
        arr = this.chartData.cityOfChina;
      }
      arr.forEach((itm) => {
        this.option.series[0].data.push({
          name: itm,
          selected: true,
          label: { show: true },
        });
      });
      if (this.chartData.layoutCenter) {
        this.option.series[0].layoutCenter = this.chartData.layoutCenter;
      }
      if (this.chartData.layoutCenter) {
        this.option.series[0].layoutSize = this.chartData.layoutSize;
      }
    }
    this.myChart = echarts.init(document.getElementById(this.id));
    this.myChart.setOption(this.option);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
    // this.myChart.on("click", function (params) {
    // });
  },
  methods: {
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
