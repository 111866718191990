<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="1200px"
    :before-close="handleClose"
  >
    <video-tab :btnList="btnList" :curVideo.sync="curVideo">
      <div class="video_arr" slot="video_arr">
        <video-component
          v-if="dialogVisible && isPlayerNull"
          :curVideo="curVideo"
        ></video-component>
      </div>
    </video-tab>
  </el-dialog>
</template>

<script>
import Frame from "@/api/frame";
import VideoTab from "./VideoTab";
import VideoComponent from "./VideoComponent";
import cameraApi from "@/api/camera/camera";

export default {
  name: "CreateVideo",
  components: { VideoTab, VideoComponent },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    curOrg: Object,
  },
  watch: {
    show(data) {
      this.dialogVisible = data;
      this.getBtnList();
    },
    dialogVisible(data) {
      if (!data) {
        this.$emit("update:show", data);
      }
    },
    curVideo(data) {
      this.isPlayerNull = false;
      setTimeout(() => {
        this.isPlayerNull = true;
      }, 100);
    },
  },
  data() {
    return {
      isLoding: false,
      isPlayerNull: true,
      btnList: [],
      dialogVisible: false,
      curVideo: null,
      // curVideo: {
      //   id: "jiankong1",
      //   name: "监控点1",
      //   url: "ezopen://open.ys7.com/E92995306/1.hd.live",
      //   player: null,
      // },
      player: null,
    };
  },
  methods: {
    getBtnList() {
      cameraApi.getCamera({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.isLoading = false;
          this.btnList = res.data.data;
          this.curVideo = this.btnList[0];
        },
        (err) => {
          this.isLoading = false;
        }
      );
    },
    handleClose(done) {
      this.toHide();
      // this.$confirm("确认关闭？")
      //   .then((_) => {

      //   })
      //   .catch((_) => {});
    },
    toHide() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
/* 弹出层设置背景色  头部 */
.el-dialog {
  background: linear-gradient(to top, #081429, #06143b) !important;
  border: 1px solid #0084ca;
  // & > .el-dialog__header {
  //   background: rgba(255,255,255,.5);
  // }
  // /* 弹出层设置背景色 底部*/

  // & > .el-dialog__footer {
  //   background-color: #c4c432 !important;
  // }
}
</style>
