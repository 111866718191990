<template>
  <div class="park_box_container">
    <div class="work_box">
      <div class="title">车位</div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="chart">
          <template v-if="!isLoading">
            <bar-echarts
              v-for="(value, key) in tabs"
              :key="key"
              v-if="key == curKey"
              :chartData="chartData"
              :id="'park_' + key"
              :className="'bar_chart'"
            ></bar-echarts>
          </template>
        </div>
        <div class="area">
          <ver-tabs :tabs="tabs" :curKey.sync="curKey">
            <div slot="list">
              <template v-if="datum">
                <div
                  class="item"
                  v-for="(item, index) in datum[curKey]"
                  v-if="item.count != 0"
                  :key="index"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.count }} 个</span>
                </div>
              </template>
            </div>
          </ver-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VerTabs from "@/components/common/verTabs/VerTabs.vue";
export default {
  name: "ParkBox",
  props: ["datum", "isLoading", "curOrg"],
  components: { VerTabs },
  data() {
    return {
      curKey: "org_list",
      tabs: {
        org_list: {
          name: "架构",
        },
        pp_type_list: {
          name: "类型",
        },
        right_ownertype_list: {
          name: "产权",
        },
      },
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "5%", bottom: "5%", top: "5%", left: "15%" },
        axisLabel: {
          show: false,
          interval: 0,
          rotate: 50,
          //倾斜度 -90 至 90 默认为0
          margin: 6,
          textStyle: {
            fontWeight: "bolder",
          },
        },
      },
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatData();
      }
    },
    curKey() {
      this.formatData();
    },
    curOrg: {
      handler(val, oldVal) {
        if (val && val.type == 1) {
          delete this.tabs.org_list;
          this.curKey = "pp_type_list";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatData() {
      if (this.datum && this.datum[this.curKey].length) {
        this.chartData.series = [
          {
            name: "数量",
            type: "bar",
            data: [],
          },
        ];
        this.chartData.dataOfLegend = [];
        this.chartData.dataOfXaxis = [];
        this.datum[this.curKey].forEach((item) => {
          if (item.count != 0) {
            this.chartData.series[0].data.push(item.count);
            this.chartData.dataOfLegend.push(item.name);
            this.chartData.dataOfXaxis.push(item.name);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
.item {
  border-bottom: 1px dashed #0084ca;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
}
</style>
