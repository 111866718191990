<template>
  <div class="count_of_zhigong_container">
    <div class="work_box">
      <!-- <div class="title">职工总人数</div> -->
      <div class="content">
        <div class="count">
          <chart-title
            :title="'职工总人数'"
            :count="datum && datum.total_count"
            :unit="'人'"
          ></chart-title>
          <div class="charts">
            <pie-echarts
              v-if="!isLoading"
              :chartData="countChartData"
              id="count_pie_chart"
              :className="'pie__chart'"
            ></pie-echarts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChartTitle from "@/components/common/chartTitle/ChartTitle";
export default {
  name: "CountOfZhigong",
  props: ["isLoading", "datum", "countOfDepartment"],
  components: { ChartTitle },
  data() {
    return {
      countChartData: {
        dataOfSeries: [],
        posOfChart: ["40%", "50%"],
        radiusOfSeries: "50%",
        legend: {
          orient: "vertical",
          data: [],
          right: 0,
          top: "center",
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 人 ({d}%)",
        },
      },
      chartOfDepartment: {
        客服部: {
          dataOfSeries: [],
          posOfChart: ["40%", "50%"],
          radiusOfSeries: "50%",
          // legend: {
          //   orient: "horizontal",
          //   data: [],
          //   bottom: 0,
          //   textStyle: {
          //     color: "#fff",
          //   },
          // },
        },
        工程部: {
          dataOfSeries: [],
          posOfChart: ["40%", "50%"],
          radiusOfSeries: "50%",
          // legend: {
          //   orient: "horizontal",
          //   data: [],
          //   bottom: 0,
          //   textStyle: {
          //     color: "#fff",
          //   },
          // },
        },
      },
    };
  },
  watch: {
    datum() {
      this.formatCountData();
    },
  },
  methods: {
    formatCountData() {
      this.countChartData.dataOfSeries = [];
      this.countChartData.legend.data = [];
      if (this.datum.job_type_list && this.datum.job_type_list.length) {
        this.datum.job_type_list.forEach((item) => {
          let obj = { name: item.name, value: item.count };
          this.countChartData.dataOfSeries.push(obj);
          this.countChartData.legend.data.push(item.name);
        });
      }

      console.log(this.rentableAreaChartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>