<template>
  <div
    class="content_right_container"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- <h1>智能科技平台登录页</h1> -->
    <div class="content_login_box">
      <!-- <div><span class="title">用户登录</span></div> -->
      <div class="input">
        <input
          type="text"
          placeholder="输入用户名"
          v-model="user.username"
        />
        <input
          type="password"
          placeholder="输入密码"
          v-model="user.password"
          @keyup.enter="toLogin"
        />
      </div>
      <div class="btns">
        <button class="button primary" @click="toLogin">登录</button>
      </div>
    </div>
  </div>
</template>
<script>
import userApi from "@/api/user/user";
import commonApi from "@/api/common";
import Frame from "@/api/frame";

export default {
  name: "Index",
  data() {
    return {
      user: { username: "", password: "" },
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    toLogin() {
      this.isLoading = true;
      commonApi.login(this.user.username, this.user.password).then(
        (res) => {
          this.isLoading = false;
          this.$message("登录成功");
          this.$router.replace({ name: "index", path: "/" });
        },
        (err) => {
          this.isLoading = false;
          this.$message("登录失败:" + Object.getResponseMessage(err));
          console.log();
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.content_right_container {
  background: url("../../assets/img/login_bg.png") center no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  padding-right: 20em;
  padding-top: 17em;
  & > h1 {
    width: 25%;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: -webkit-linear-gradient(right, #58a7ed, #7bedd5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  & > .content_login_box {
    border: 1px solid #1b69a9;
    width: 25%;
    height: 45%;
    background: rgba(32, 163, 245, 0.1);
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    & > div {
      &.input {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 80%;
        height: 50%;
        color: #559ff2;
        & > input {
          height: 22%;
          outline: none;
          background: rgba(32, 163, 245, 0.1);
          border: none;
          border: 1px solid #1b69a9;
          padding-left: 1em;
          &::placeholder {
            color: #8fa6c9;
          }
        }
      }
      &.btns {
        height: 14%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        width: 80%;
        color: #559ff2;
        & > button {
          border: 1px solid #1b69a9;
          flex: 1;
          // background: linear-gradient(to right, #4f97d2, #7bedd5);
          background: #4f97d2;
          font-size: 1.4em;
        }
      }
      & > span.title {
        display: flex;
        align-items: center;
        font-size: 1.6em;
      }
    }
  }
}
</style>
