<template>
  <div class="renliziyuan_container">
    <div class="work_box">
      <div class="title">
        <span>人力资源</span>
        <router-link :to="{ name: 'renliziyuan', query: $route.query }"
          >前往 >></router-link
        >
      </div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <template v-if="datum">
          <div class="count">
            <card
              class="_card"
              :title="'员工数量'"
              :num="datum.total_count"
              :unit="'人'"
            ></card>
            <div class="chart">
              <!-- <huan-echarts
                v-if="!isLoading"
                :chartData="renliCountChart"
                id="renli_count__"
                :className="'huan_chart'"
              ></huan-echarts> -->
              <pie-echarts
                v-if="!isLoading"
                :chartData="renliCountChart"
                id="renli_count__"
                :className="'pie__chart'"
              ></pie-echarts>
            </div>
          </div>
          <!-- <div class="chengben">
            <card
              class="_card"
              :title="'人均工资'"
              :num="
                datum.year_cost_list
                  ? datum.year_cost_list[new Date().getFullYear()].cost
                  : 0
              "
              :unit="'元'"
            ></card>

            <div class="chart">
              <bar-echarts
                v-if="!isLoading"
                :chartData="costChartData"
                id="renli"
                :className="'bar_chart'"
              ></bar-echarts>
            </div>
          </div> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import card from "@/components/common/card/Card";

export default {
  name: "renliziyuan",
  components: { card },
  props: ["isLoading", "datum"],
  data() {
    return {
      costChartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%" },
      },
      renliCountChart: {
        dataOfSeries: [],
        posOfChart: ["50%", "35%"],
        radiusOfSeries: "45%",
        legend: {
          orient: "horizontal",
          data: [],
          bottom: 0,
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 人 ({d}%)",
        },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatCostChartData();
        this.formatCountData();
      }
    },
  },
  methods: {
    formatCountData() {
      this.renliCountChart.dataOfSeries = [];
      this.renliCountChart.legend.data = [];
      for (let key in this.datum.job_type_count_list) {
        let obj = {
          name: key,
          value: this.datum.job_type_count_list[key],
        };
        this.renliCountChart.dataOfSeries.push(obj);
        this.renliCountChart.legend.data.push(key);
      }
    },
    formatCostChartData() {
      this.costChartData.series = [];
      this.costChartData.dataOfLegend = [];

      for (let key in this.datum.job_type_count_list) {
        // 初始化legend
        this.costChartData.dataOfLegend.push(key);
        // 初始化 series的data
        let obj = {
          name: key,
          type: "bar",
          data: [],
        };
        this.costChartData.dataOfXaxis = [];
        for (let key2 in this.datum.year_cost_list) {
          obj.data.push(this.datum.year_cost_list[key2].job_type_list[key].avg);
          // 初始化x轴数据
          this.costChartData.dataOfXaxis.push(key2 + "年");
        }
        this.costChartData.series.push(obj);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
