import common from './common';
export default {
    // 项目概况
    summaryOfProject({
        organization_id = 1
    }) {
        return common.get('bi/operation/project/summary', {
            organization_id: organization_id
        })
    },
    // 房间概况
    summaryOfRoom({
        organization_id = 1
    }) {
        return common.get('bi/operation/room/summary', {
            organization_id: organization_id
        })
    },
    // 车位概况
    summaryOfPark({
        organization_id = 1
    }) {
        return common.get('bi/operation/park/summary', {
            organization_id: organization_id
        })
    },
    // 营收数据
    summaryOfFinancial({
        organization_id = 1
    }) {
        return common.get('bi/operation/financial/summary', {
            organization_id: organization_id
        })
    },
    // 覆盖率
    summaryOfStructure({
        organization_id = 1
    }) {
        return common.get('bi/operation/project/structure', {
            organization_id: organization_id
        })
    }

}