<template>
  <h4>
    {{ title }}：<span class="num">{{ count }}</span
    >{{ unit }}
  </h4>
</template>
<script>
export default {
  name: "ChartTitle",
  props: {
    title: String,
    count: Number || String,
    unit: String,
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>