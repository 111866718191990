<template>
  <div class="zulin_container">
    <div class="work_box">
      <div class="title">租赁经营情况</div>
      <div class="content">
        <div class="pie">
          <div class="chart">
            <huan-echarts
              v-if="!isSummaryLoading"
              :chartData="summaryData"
              :className="'huan_chart'"
              id="area_huan_chart"
            ></huan-echarts>
          </div>
          <div class="data">
            <chart-label
              :label="'可经营'"
              :count="
                datumOfSummary &&
                  Number(datumOfSummary.rentable_area).toFixed(2)
              "
              :unit="'平米'"
            ></chart-label>
            <chart-label
              :label="'已出租'"
              :count="
                datumOfSummary && Number(datumOfSummary.rent_area).toFixed(2)
              "
              :unit="'平米'"
            ></chart-label>
            <chart-label
              :label="'未出租'"
              :count="
                datumOfSummary &&
                  Number(
                    datumOfSummary.rentable_area - datumOfSummary.rent_area
                  ).toFixed(2)
              "
              :unit="'平米'"
            ></chart-label>
          </div>
        </div>
        <div class="bar">
          <bar-echarts
            v-if="!isSummaryLoading"
            :chartData="monthData"
            id="total_area_bar_chart"
            :className="'bar_chart'"
          ></bar-echarts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChartLabel from "@/components/common/chartLabel/ChartLabel";

export default {
  name: "ZulinJingying",
  props: ["isSummaryLoading", "datumOfSummary"],
  components: { ChartLabel },
  watch: {
    datumOfSummary(data) {
      if (data) {
        this.formatSummary();
      }
    },
  },
  data() {
    return {
      monthData: {
        series: [],
        // dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "10%", top: "10%" },
      },
      summaryData: {
        nameOfSeries: "出租率",
        dataOfSeries: [],
        posOfChart: ["50%", "50%"],
        radiusOfChart: ["60%", "70%"],
        labelOfSeries: {
          normal: {
            show: false,
            position: "center",
            formatter: "{a}\n\n{d}%",
          },
        },
      },
    };
  },
  methods: {
    formatMonth() {
      // this.monthData.dataOfLegend = ["空置率", "出租率"];
      this.monthData.series = [
        { name: "空置率", type: "bar", data: [], stack: "出租" },
        { name: "出租率", type: "bar", data: [], stack: "出租" },
      ];

      this.monthData.dataOfXaxis = [];
      this.datumOfSummary.month_list.forEach((item) => {
        this.monthData.series[0].data.push(
          (
            ((item.rentable_area - item.rent_area) / item.rentable_area) *
            100
          ).toFixed(2)
        );
        this.monthData.series[1].data.push(
          ((item.rent_area / item.rentable_area) * 100).toFixed(2)
        );
        this.monthData.dataOfXaxis.push(item.name + "月");
      });
    },
    formatSummary() {
      this.summaryData.dataOfSeries = [
        {
          name: "未出租",
          value: (
            this.datumOfSummary.rentable_area - this.datumOfSummary.rent_area
          ).toFixed(2),
          label: { show: false, fontSize: 20 },
        },
        {
          name: "已出租",
          value: this.datumOfSummary.rent_area.toFixed(2),
          label: { show: true, fontSize: 20 },
        },
      ];
      // this.summaryData.legend.data = ["未出租", "已出租"];
      this.formatMonth();
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
