<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["chartData", "id", "className"],
  data() {
    return {
      myChart: null,
      options: {
        legend: {
          orient: "vertical",
          right: 10,
          data: [],
          textStyle: {
            color: "#fff",
          },
        },
        // 鼠标移上去有注释
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 万 ({d}%)",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "70%", //控制圈大小的
            center: [],
            //是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。当avoidLabelOverlap设置为false时会出现以下情况
            avoidLabelOverlap: true,
            label: {
              show: false, // 内容是否在饼图上显示
              position: "center",
            },
            emphasis: {
              label: {
                show: false, // 鼠标移上去是否在圈中显示
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              normal: {
                color: function(params) {
                  //自定义颜色
                  var colorList = [
                    "#0089f8",
                    "#9bf8fe",
                    "#1baff9",
                    "#0073ed",
                    "#1e1988",
                    "#3f5b9b",
                    "#B17CFF",
                    "#2576ac",
                    "#79a3bd",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById(this.id));
    this.options.series[0].data = this.chartData.dataOfSeries;
    this.options.series[0].center = this.chartData.posOfChart;
    if (this.chartData.nameOfSeries) {
      this.options.series[0].name = this.chartData.nameOfSeries;
    }
    if (this.chartData.labelOfSeries) {
      this.options.series[0].label = this.chartData.labelOfSeries;
    }
    if (this.chartData.tooltip) {
      this.options.tooltip = this.chartData.tooltip;
    }
    if (this.chartData.legend) {
      this.options.legend = this.chartData.legend;
    }
    if (this.chartData.radiusOfSeries) {
      this.options.series[0].radius = this.chartData.radiusOfSeries;
    }
    this.myChart.setOption(this.options);
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
