<template>
  <div class="label">
    <span>{{ label }}</span>
    <div>
      <span class="num">{{ Number(count).toFixed(2) }}</span
      >{{ unit }}
    </div>
  </div>
</template>
<script>
export default {
  name: "ChartLabel",
  props: {
    label: String,
    count: Number | String,
    unit: String,
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>