<template>
  <div class="zichan_container">
    <div class="work_box">
      <div class="title">
        <span>资产</span>
        <router-link :to="{ name: 'zichan', query: $route.query }"
          >前往 >></router-link
        >
      </div>
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <template v-if="datum">
          <div class="top">
            <card
              :title="'可经营面积'"
              :num="datum.rentable_area"
              :unit="'平米'"
            ></card>
          </div>
          <div class="bottom">
            <div class="mianji">
              <div class="chart">
                <div class="ct">
                  <pie-echarts
                    v-if="!isLoading"
                    :chartData="rentableAreaChartData"
                    id="pie_chart"
                    :className="'pie__chart'"
                  ></pie-echarts>
                </div>
                <div class="chart_data">
                  <chart-label
                    :label="'已出租'"
                    :count="datum && datum.rent_area"
                    :unit="'平米'"
                  ></chart-label>
                  <chart-label
                    :label="'未出租'"
                    :count="datum && datum.rentable_area - datum.rent_area"
                    :unit="'平米'"
                  ></chart-label>
                </div>
              </div>
            </div>
            <div class="gongsi" v-if="curOrg && curOrg.type == 0">
              <!-- <h5 style="text-align: center">空置率</h5> -->
              <hor-bar-echarts
                v-if="!isLoading"
                :chartData="chartData"
                :className="'hor_bar_chart'"
                id="kong_zhi_lv"
              ></hor-bar-echarts>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import card from "@/components/common/card/Card";
import ChartLabel from "@/components/common/chartLabel/ChartLabel";

export default {
  name: "zichan",
  props: ["isLoading", "datum", "curOrg"],
  components: { card, ChartLabel },
  data() {
    return {
      speed: 50,
      chartData: {
        series: [],
        // dataOfLegend: [],
        dataOfYaxis: [],
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "0%",
          containLabel: true,
        },
      },
      rentableAreaChartData: {
        dataOfSeries: [],
        posOfChart: ["45%", "50%"],
        radiusOfSeries: "50%",
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} 平 ({d}%)",
        },
        // legend: {
        //   orient: "horizontal",
        //   data: [],
        //   bottom: 0,
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
      },
      rentablePerData: [], //空置率
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatResourceData();
        this.formatRentablePerData(); //空置率
      }
    },
  },
  methods: {
    formatResourceData() {
      this.rentableAreaChartData.dataOfSeries = [
        { name: "已出租", value: this.datum.rent_area },
        { name: "未出租", value: this.datum.rentable_area },
      ];
      // this.rentableAreaChartData.legend.data = ["已出租", "未出租"];
    },
    formatRentablePerData() {
      this.chartData.series = [
        {
          name: "空置率",
          type: "bar",
          stack: "总量",
          label: {
            show: false,
            position: "right",
          },
          data: [],
        },
        {
          name: "入住率",
          type: "bar",
          stack: "总量",
          label: {
            show: false,
            position: "right",
          },
          data: [],
        },
      ];

      this.chartData.dataOfYaxis = [];
      this.datum.org_list.forEach((item) => {
        if (!isNaN(Number(item.percent)) && Number(item.percent != 0)) {
          this.chartData.dataOfYaxis.push(item.name);
          this.chartData.series[0].data.push(item.percent.toFixed(2));
          this.chartData.series[1].data.push(100 - item.percent.toFixed(2));
        }
      });
      console.log(this.chartData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
