<template>
  <div class="count_of_department_container">
    <div class="work_box">
      <div class="title">各组织/部门职工人数</div>
      <div class="content">
        <template
          v-if="
            !countOfDepartment.isLoading &&
              countOfDepartment.data &&
              countOfDepartment.data.length
          "
        >
          <div class="department">
            <div class="out_box">
              <template
                v-if="countOfDepartment.data && countOfDepartment.data.length"
              >
                <div
                  class="item"
                  v-for="(item, index) in countOfDepartment.data"
                  v-if="item.count != 0"
                  :key="index"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.count }} 人</span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CountOfDepartment",
  props: ["isLoading", "countOfDepartment"],
  data() {
    return {
      countChartData: {
        dataOfSeries: [],
        posOfChart: ["40%", "50%"],
        radiusOfSeries: "50%",
        legend: {
          orient: "horizontal",
          data: [],
          bottom: 0,
          textStyle: {
            color: "#fff",
          },
        },
      },
      chartOfDepartment: {
        客服部: {
          dataOfSeries: [],
          posOfChart: ["40%", "50%"],
          radiusOfSeries: "50%",
          legend: {
            orient: "horizontal",
            data: [],
            bottom: 0,
            textStyle: {
              color: "#fff",
            },
          },
        },
        工程部: {
          dataOfSeries: [],
          posOfChart: ["40%", "50%"],
          radiusOfSeries: "50%",
          legend: {
            orient: "horizontal",
            data: [],
            bottom: 0,
            textStyle: {
              color: "#fff",
            },
          },
        },
        财务部: {
          dataOfSeries: [],
          posOfChart: ["40%", "50%"],
          radiusOfSeries: "50%",
          legend: {
            orient: "horizontal",
            data: [],
            bottom: 0,
            textStyle: {
              color: "#fff",
            },
          },
        },
      },
    };
  },
  watch: {
    datum() {
      this.formatCountData();
    },
    countOfDepartment: {
      handler(val, oldVal) {
        console.log(this.countOfDepartment);
        this.formatDepartmentData();
      },
      deep: true,
    },
  },
  methods: {
    formatDepartmentData() {
      this.countChartData.dataOfSeries = [];
      this.countChartData.legend.data = [];
      if (
        this.countOfDepartment &&
        this.countOfDepartment.data &&
        this.countOfDepartment.data.length
      ) {
        this.countOfDepartment.data.forEach((item) => {
          for (let key in this.chartOfDepartment) {
            // console.log(item.name, key);
            if (item.name == key) {
              console.log(item, item.name, key);
              if (item.job_list && item.job_list.length) {
                item.job_list.forEach((itm) => {
                  let obj = { name: itm.name, value: itm.count };
                  this.chartOfDepartment[key].dataOfSeries.push(obj);
                  this.chartOfDepartment[key].legend.data.push(itm.name);
                });
                console.log(this.chartOfDepartment[key]);
              }
            }
          }
        });
      }
    },
    formatCountData() {
      this.countChartData.dataOfSeries = [];
      this.countChartData.legend.data = [];
      if (this.datum.job_type_list && this.datum.job_type_list.length) {
        this.datum.job_type_list.forEach((item) => {
          let obj = { name: item.name, value: item.count };
          this.countChartData.dataOfSeries.push(obj);
          this.countChartData.legend.data.push(item.name);
        });
      }

      console.log(this.rentableAreaChartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
