import commonApi from '../api/common';
import Events from '../api/events';

export default {
    namespaced: true,
    state: {
        current: null
            // current:{
            //     id:69,
            //     organization:124,
            //     username:'10102',
            //     real_name:'zym2 朝内',
            //     mobile:'112233',
            //     created_time:'2019-03-11 14：21：10',
            //     valid_date:'2999-12-31',
            //     comments:'',
            //     disabled:0,
            //     super:0,
            //     orgs:[],
            //     organization_name:'测试部门',
            //     parent_path:'0,1',
            //     path:'0,1,124'
            // }
    },
    mutations: {
        update(state, usr) {
            // console.log("****", usr);
            state.current = usr;
            if (state.current) {
                // 处理用户头像
                if (!state.current.props)
                    state.current.props = {};
                if (state.current.props.avatar)
                    state.current.props.avatarUrl = commonApi.buildUrl('f/' + state.current.props.avatar);
            }
            Bus.$emit(Events.USER_UPDATED, usr);
        }
    },
    actions: {
        update({ commit }) {
            // console.log('更新用户信息');
            commonApi.current().then(res => {
                commit('update', res.data.datum);
                // console.log('获取成功',arguments);
            }, err => {
                commit('update', null);
                // console.log('获取失败',error.response.data.message);
            }).catch(err => {
                // console.log("catch错误",err);
                commit('update', null);
            });
        }
    },
    getters: {
        id(state) {
            return state.current ? state.current.id : null;
        },
        isSuper(state) {
            return state.current && state.current.super;
        },
        acl(state) {
            return state.current && state.current.acl ? state.current.acl : {};
        }
    }
};