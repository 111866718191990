<template>
  <div class="chengben_of_renjun_container">
    <div class="work_box">
      <chart-title
        :title="'人均工资'"
        :count="datum && datum.year_cost_list[new Date().getFullYear()].cost"
        :unit="'元'"
      ></chart-title>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="yearOfChartData"
          id="year_ChengbenOfRenjun"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
import ChartTitle from "@/components/common/chartTitle/ChartTitle";

export default {
  name: "ChengbenOfRenjun",
  props: ["isLoading", "datum"],
  components: { ChartTitle },
  watch: {
    datum() {
      if (this.datum) {
        this.formatYearData(); //今年不同工种
      }
    },
  },
  data() {
    return {
      yearOfChartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%",top:'5%' },
      },
    };
  },
  methods: {
    formatYearData() {
      const curYear = new Date().getFullYear();
      this.yearOfChartData.series = [
        {
          name: "成本",
          type: "bar",
          data: [],
        },
      ];
      this.yearOfChartData.dataOfLegend = [];
      this.yearOfChartData.dataOfXaxis = [];
      if (
        this.datum.year_cost_list[curYear] &&
        this.datum.year_cost_list[curYear].job_type_list
      ) {
        for (let key in this.datum.year_cost_list[curYear].job_type_list) {
          this.yearOfChartData.dataOfXaxis.push(key);
          this.yearOfChartData.series[0].data.push(
            this.datum.year_cost_list[curYear].job_type_list[key].cost
          );
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.chengben_of_renjun_container {
  & > .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>