<template>
  <div class="bennian_qianshou_container">
    <div class="work_box">
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="_left">
          <h3>
            <span>本年欠收</span>
            <span class="yw">{{ (dataOfDebt.amount / 10000).toFixed(2) }}</span>
            <span>万</span>
          </h3>
          <div class="options">
            <h4 v-for="(value, key) in dataOfDebt.options" :key="key">
              <span>{{ value.name }}</span>
              <div>
                <span class="yw">{{ (value.value / 10000).toFixed(2) }}</span>
                <span>万</span>
              </div>
            </h4>
          </div>
        </div>
        <div class="_right">
          <pie-echarts
            v-if="!isLoading"
            :chartData="dataOfDebt.chartData"
            id="bennianqianshou"
            :className="'pie__chart'"
          ></pie-echarts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BennianQianshou",
  props: ["isLoading", "datum"],
  data() {
    return {
      colorList: [
        "#0089f8",
        "#9bf8fe",
        "#1baff9",
        "#0073ed",
        "#1e1988",
        "#3f5b9b",
        "#B17CFF",
        "#2576ac",
        "#79a3bd",
      ],
      keyObj: {},
      dataOfDebt: {
        name: "今年",
        amount: 0,
        options: [],
        chartData: {
          dataOfSeries: [],
          posOfChart: ["50%", "55%"],
          radiusOfSeries: "70%",
        },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatChart();
      }
    },
  },
  methods: {
    formatChart() {
      this.dataOfDebt.chartData.dataOfSeries = [];
      this.dataOfDebt.options = [];
      if (this.datum.total && this.datum.total.current) {
        // 获取本年的总数据
        this.dataOfDebt.amount = this.datum.total.current.total;
        const tagList = this.datum.total.current.tag_list;

        for (let key2 in tagList) {
          let values2 = tagList[key2];
          let obj = {
            name: key2,
            value: values2,
          };
          this.dataOfDebt.chartData.dataOfSeries.push(obj);
          // this.dataOfDebt.chartData.legend.data.push(key2);
          this.dataOfDebt.options.push(obj);
        }
      }
      if (this.dataOfDebt.options.length) {
        this.dataOfDebt.options.forEach((itm, i) => {
          itm.bgColor = this.colorList[i];
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
