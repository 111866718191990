export default {
    title: '智慧物业管理驾驶舱',
    shortTitle: '驾驶舱',
    companyIcon: 'static/img/logo/zz.png',
    companyName: 'xx物业管理有限公司',
    copyRightYear: '2019',

    veryLongDate: '2999-12-31',

    storage: {
        scope: 'financial.aio'
    },
    apiUrl: 'api/v3',
    defaultFileFieldName: '_fofaio', //文件上传对应的input的name
    enableSocketIO: false, //与后端实时通信的开关
    auth: {
        defaultKeep: true //保持登录
    },
    gtUrl: document.location.protocol === 'https:' ?
        'https://local-service.ipuapp.tech:10443/' : 'http://local-service.ipuapp.tech:10080/',
};
