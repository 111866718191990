import Axios from "axios";
import Config from "../config";
import '@/corelib/libutils'

const names = {
    keepLogin: 'keepLogin',
    token: 'lastToken',
    autoLoginToken: ''
};

function getSession(scope, name, defValue) {
    let obj;
    try {
        obj = JSON.parse(sessionStorage[scope]);
    } catch (e) {
        obj = {};
    }
    return name ? (obj[name] !== undefined ? obj[name] : defValue) : obj;
}

function setSession(scope, name, value) {
    let obj = getSession(scope);
    if (value === undefined)
        delete obj[name];
    else
        obj[name] = value;
    try {
        sessionStorage[scope] = JSON.stringify(obj);
    } catch (e) {
        sessionStorage[scope] = "{}";
    }
}

function getLocal(scope, name, defValue) {
    let obj;
    try {
        obj = JSON.parse(localStorage[scope]);
    } catch (e) {
        obj = {};
    }
    return name ? (obj[name] !== undefined ? obj[name] : defValue) : obj;
}

function setLocal(scope, name, value) {
    let obj = getLocal(scope);
    if (value === undefined)
        delete obj[name];
    else
        obj[name] = value;
    try {
        localStorage[scope] = JSON.stringify(obj);
    } catch (e) {
        localStorage[scope] = "{}";
    }
}

let lastToken = '';
let keepLogin = Config.auth.defaultKeep;
let modules = {};
const apiUrl = Config.apiUrl + (Config.apiUrl.substr(-1) === '/' ? '' : '/');
let _ = {
    getSessionParam(name, value) {
        return getSession(Config.storage.scope, name, value);
    },
    setSessionParam(name, value) {
        setSession(Config.storage.scope, name, value);
    },
    getLocalParam(name, value) {
        return getLocal(Config.storage.scope, name, value);
    },
    setLocalParam(name, value) {
        setLocal(Config.storage.scope, name, value);
    },

    getLoginParam: function (name, defaultValue) {
        return keepLogin ? _.getLocalParam(name, defaultValue) :
            _.getSessionParam(name, defaultValue);
    },
    setLoginParam: function (name, value) {
        if (keepLogin)
            _.setLocalParam(name, value)
        else
            _.setSessionParam(name, value);
    },
    switchKeepLogin: function (en) {
        if (en) {
            _.setLocalParam(names.token, lastToken);
            _.setSessionParam(names.token);
        } else {
            _.setLocalParam(names.token, null);
            _.setSessionParam(names.token, lastToken);
        }
        keepLogin = en ? 1 : 0;
        _.setLocalParam(names.keepLogin, keepLogin);
    },

    autoInit(scope) {
        let token = getLocal('xjl_autologin', 'token', null);
        if (!token)
            token = scope.$route.query.lastToken;
        setLocal('xjl_autologin', 'token');
        scope.$route.query.lastToken = null;
        _.init(token);

    },
    init(token) {
        keepLogin = _.getLocalParam(names.keepLogin, keepLogin);
        if (token)
            _.setLoginParam(names.token, token);
        else
            token = _.getLoginParam(names.token);
        lastToken = token ? token : '';
        document.title = Config.title
    },
    buildBaseParam(obj) {
        if (!obj)
            obj = {};
        obj.faio_token = lastToken;
        return obj;
    },
    buildUrl(url, params) {
        params = _.buildBaseParam(params);
        return apiUrl + url + '?' + String.urlencode(params);
    },
    request(_method, _url, _data, _params) {
        if (!_params)
            _params = {};
        _params._ = Math.random();
        _.buildBaseParam(_params);
        return Axios({
            method: _method,
            url: apiUrl + _url,
            // baseURL: '',
            // transformRequest: [function(data) {
            //   return data;
            // }],
            // transformResponse: [function(data) {
            //   return data;
            // }],
            headers: {
                'Content-Type': 'application/json'
            },
            params: _params,
            data: _data,
            // timeout: 1000,
            withCredentials: true,
            responseType: 'json'
        });
    },
    upload(_url, _files, _params, onUploadProgress) {
        if (!_params)
            _params = {};
        _.buildBaseParam(_params);
        if (_files instanceof File)
            _params['_file_'] = _files;
        else if (_files instanceof Array) {
            let idx = 1;
            _files.forEach(file => {
                if (file instanceof File) {
                    _params[`_file_${idx}`] = file;
                    idx++;
                }
            });
        }
        return Axios({
            method: 'POST',
            url: apiUrl + _url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: _params.toFormData(),
            withCredentials: true,
            responseType: 'json',
            onUploadProgress: onUploadProgress
        });
    },
    get(_url, _params) {
        return _.request('GET', _url, null, _params);
    },
    post(_url, _data, _params) {
        return _.request('POST', _url, _data, _params);
    },
    put(_url, _data, _params) {
        return _.request('PUT', _url, _data, _params);
    },
    Delete(_url, _params) {
        return _.request('DELETE', _url, null, _params);
    },

    createCommonInterface(moduleName) {
        if (!modules[moduleName]) {
            modules[moduleName] = {
                fill(list, opt, done) {
                    _.get(moduleName, opt).then(
                        res => {
                            list.splice(0);
                            list.push.apply(list, res.data.data);
                        },
                        err => {
                            list.splice(0);
                        }
                    ).catch(e => {
                        list.splice(0);
                    }).finally(() => {
                        if (done)
                            done();
                    });
                },
                fillMap(map, opt, done) {
                    _.get(moduleName, opt).then(
                        res => {
                            for (let k in map) {
                                if (map.hasOwnProperty(k))
                                    delete map[k];
                            }
                            let l = res.data.data.length;
                            while (l--) {
                                map[res.data.data[l].id] = res.data.data[l];
                            }
                        },
                        err => {
                            for (let k in map) {
                                if (map.hasOwnProperty(k))
                                    delete map[k];
                            }
                        }
                    ).catch(e => {
                        for (let k in map) {
                            if (map.hasOwnProperty(k))
                                delete map[k];
                        }
                    }).finally(() => {
                        if (done)
                            done();
                    });
                },
                search(opt) {
                    return _.get(moduleName, opt);
                },
                getOfId(id, param) {
                    return _.get(moduleName + '/detail/' + id, param);
                },
                add(data) {
                    return _.post(moduleName, data);
                },
                update(id, data) {
                    return _.put(moduleName + '/' + id, data);
                },
                batchUpdate(data) {
                    return _.put(moduleName, data);
                },
                remove(id) {
                    return _.Delete(moduleName + '/' + id);
                },
                Common: _
            }
        }
        return modules[moduleName]
    },
    login(u, p, keepToken) {
        return _.post('user/login', {
            username: u,
            password: p
        }).then(res => {
            lastToken = res.data.datum.token;
            _.setLoginParam(names.token, lastToken);
            return new Promise((resolve, reject) => {
                resolve(res);
            });
        }, err => {
            if (!keepToken) {
                lastToken = '';
                _.setLoginParam(names.token, lastToken);
            }
            return new Promise((resolve, reject) => {
                reject(err);
            });
        });
    },
    logout() {
        return _.Delete('user/current/logout').then(res => {
            lastToken = '';
            _.setLoginParam(names.token, '');
            return new Promise((resolve, reject) => {
                resolve(res);
            });
        }, err => {
            return new Promise((resolve, reject) => {
                reject(err);
            });
        });
    },
    current() {
        return _.get('user/current');
    },
    menu() {
        return _.get('user/current/menu');
    },
    updateCurrent(data) {
        return _.put('user/current', data);
    },
    token() {
        return lastToken;
    },
    keepLogin() {
        return keepLogin;
    }
};
export default _;