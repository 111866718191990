<template>
  <div class="kongzhi_rank_container">
    <div class="work_box">
      <div class="title">空置排行</div>
      <div class="content">
        <hor-bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          :className="'hor_bar_chart'"
          id="kong_zhi_paihang"
        ></hor-bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ZulinJingying",
  props: ["isLoading", "datum"],
  watch: {
    datum(data) {
      console.log(data);
      if (data && data.org_list && data.org_list.length) {
        console.log(data.org_list);
        this.formatData();
      }
    },
  },
  data() {
    return {
      chartData: {
        series: [],
        // dataOfLegend: [],
        dataOfYaxis: [],
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a}:{c}%",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
      },
    };
  },
  methods: {
    formatData() {
      let labelObj = {
        rest_amount: "总欠收",
        pay_amount: "总实收",
      };
      this.chartData.dataOfLegend = ["空置率"];
      this.chartData.series = [
        {
          name: "空置率",
          type: "bar",
          stack: "总量",
          label: {
            show: false,
            position: "right",
          },
          data: [],
        },
        {
          name: "入住率",
          type: "bar",
          stack: "总量",
          label: {
            show: false,
            position: "right",
          },
          data: [],
        },
      ];

      this.chartData.dataOfYaxis = [];
      this.datum.org_list.forEach((item) => {
        this.chartData.dataOfYaxis.push(item.name);
        this.chartData.series[0].data.push(item.percent.toFixed(2));
        this.chartData.series[1].data.push(100 - item.percent.toFixed(2));
      });
      console.log(this.chartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>