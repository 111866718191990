<template>
  <layout :navWidth="curOrg.type == 0 ?'40em' : '30em'" :isAsideShow="false">
    <template v-if="curOrg.type == 0">
      <div slot="nav" class="gongcheng_nav">
        <count-of-device
          :isLoading="device.isLoading"
          :datum="device.datum"
        ></count-of-device>
        <count-of-room
          :isLoading="room.isLoading"
          :datum="room.datum"
        ></count-of-room>
      </div>
      <div slot="content" class="gongcheng_content company">
        <xunshi-xunjian
          :isLoading="task.isLoading"
          :datum="task && task.datum && task.datum[1]"
        ></xunshi-xunjian>
        <shebei-yanghu
          :isLoading="task.isLoading"
          :datum="task && task.datum && task.datum[0]"
        ></shebei-yanghu>
        <time-out
          :isLoading="timeout.isLoading"
          :data="timeout.data"
        ></time-out>
        <nenghao :isLoading="room.isLoading" :datum="room.datum"></nenghao>
      </div>
    </template>
    <template v-else>
      <div slot="nav" class="gongcheng_nav">
        <base-info :isLoading="org.isLoading" :datum="org.datum" :curOrg="curOrg"></base-info>
        <!-- <gongcheng-department></gongcheng-department> -->
      </div>
      <div slot="content" class="gongcheng_content project">
        <count-of-device
          :isLoading="device.isLoading"
          :datum="device.datum"
        ></count-of-device>
        <xunshi-xunjian
          :isLoading="task.isLoading"
          :datum="task && task.datum && task.datum[1]"
        ></xunshi-xunjian>
        <shebei-yanghu
          :isLoading="task.isLoading"
          :datum="task && task.datum && task.datum[0]"
        ></shebei-yanghu>
        <count-of-room
          :isLoading="room.isLoading"
          :datum="room.datum"
        ></count-of-room>
        <jiankong-yujing
          :isLoading="timeout.isLoading"
          :data="timeout.data"
        ></jiankong-yujing>
      </div>
    </template>
  </layout>
</template>

<script>
import { mapState } from "vuex";
import facilityApi from "@/api/facility/facility";
import CountOfDevice from "./components/countOfDevice/CountOfDevice";
import CountOfRoom from "./components/countOfRoom/CountOfRoom";
import XunshiXunjian from "./components/xunshiXunjian/XunshiXunjian";
import ShebeiYanghu from "./components/shebeiYanghu/ShebeiYanghu";
import TimeOut from "./components/timeOut/TimeOut";
import Nenghao from "./components/nenghao/Nenghao";
import BaseInfo from "@/components/common/baseInfo/BaseInfo";
import GongchengDepartment from "./components/project/gongchengDepartment/GongchengDepartment";
import JiankongYujing from "./components/project/jiankongYujing/JiankongYujing";
import orgApi from "@/api/org/org";

export default {
  name: "Index",
  components: {
    CountOfDevice,
    CountOfRoom,
    XunshiXunjian,
    ShebeiYanghu,
    TimeOut,
    Nenghao,
    BaseInfo,
    GongchengDepartment,
    JiankongYujing,
  },
  data() {
    return {
      device: { isLoading: false, datum: null },
      room: { isLoading: false, datum: null },
      task: { isLoading: false, datum: null },
      timeout: { isLoading: false, data: [] },
      org: { isLoading: false, datum: null },
    };
  },
  watch: {
    curOrg: {
      handler(newVal, oldVal) {
        // console.log("工程模块的vuex发生变化");
        this.getCountOfDevice(); // 设备数量
        this.getCountOfRoom(); // 机房数量
        this.getSummaryOfTask(); // 任务统计
        this.getReportOfTimeout(); // 超时预警
        this.getDetailOfOrg();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      curOrg: (state) => {
        return state.curOrg ? state.curOrg : { path: [], id: 1, type: 0 };
      },
    }),
  },
  // mounted() {
  //   this.getCountOfDevice(); // 设备数量
  //   this.getCountOfRoom(); // 机房数量
  //   this.getSummaryOfTask(); // 任务统计
  //   this.getReportOfTimeout(); // 超时预警
  // },
  methods: {
    getDetailOfOrg() {
      this.org.isLoading = true;
      orgApi.detailOfOrg(this.curOrg.id).then(
        (res) => {
          this.org.isLoading = false;
          this.org.datum = res.data.datum;
        },
        (err) => {
          this.org.isLoading = false;
        }
      );
    },
    getReportOfTimeout() {
      this.timeout.isLoading = true;
      facilityApi.reportOfTimeout({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.timeout.isLoading = false;
          if (res.data.data && res.data.data.length) {
            this.timeout.data = res.data.data;
          } else {
            // 暂时数据模拟
            this.timeout.data = [
              {
                planType: 0,
                title: "第一套设备养护",
                org: { name: "1项目" },
              },
              {
                planType: 0,
                title: "第一套设备养护",
                org: { name: "1项目" },
              },
              {
                planType: 0,
                title: "第一套设备养护",
                org: { name: "1项目" },
              },
              {
                planType: 1,
                title: "planA计划巡检",
                org: { name: "3项目" },
              },
              {
                planType: 1,
                title: "planB计划巡检",
                org: { name: "3项目-" },
              },
              {
                planType: 1,
                title: "planC计划巡检",
                org: { name: "3项目--" },
              },
              {
                planType: 1,
                title: "planA计划巡检",
                org: { name: "3项目" },
              },
              {
                planType: 0,
                title: "**第二套设备养护",
                org: { name: "4项目" },
              },
              {
                planType: 0,
                title: "planB计划巡检",
                org: { name: "4项目" },
              },
            ];
          }
        },
        (err) => {
          this.timeout.isLoading = false;
          console.log(err);
        }
      );
    },
    getCountOfDevice() {
      this.device.isLoading = true;
      facilityApi.countOfDevice({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.device.isLoading = false;
          this.device.datum = res.data.datum;
        },
        (err) => {
          this.device.isLoading = false;
          console.log(err);
        }
      );
    },
    getCountOfRoom() {
      this.room.isLoading = true;
      facilityApi.countOfRoom({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.room.isLoading = false;
          this.room.datum = res.data.datum;
        },
        (err) => {
          this.room.isLoading = false;
          console.log(err);
        }
      );
    },
    getSummaryOfTask() {
      this.task.isLoading = true;
      facilityApi.summaryOfTask({ organization_id: this.curOrg.id }).then(
        (res) => {
          this.task.isLoading = false;
          this.task.datum = res.data.datum;
        },
        (err) => {
          this.task.isLoading = false;
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
