<template>
  <div class="yingshou_data_container">
    <div class="work_box">
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <hor-tabs v-if="!isLoading" :tabs="classifyObj" :curTab.sync="curTab">
          <div slot="list" class="list_chart">
            <div
              v-for="(value, key) in classifyObj"
              :key="key"
              v-if="key == curTab"
            >
              <div class="top">
                <huan-echarts
                  v-if="key == curTab"
                  :chartData="classifyObj[curTab].chartData"
                  :id="curTab"
                  :className="'huan_chart'"
                ></huan-echarts>
                <line-echarts
                  v-if="key == curTab"
                  :className="'line_chart'"
                  :id="'yunying' + curTab"
                  :chartData="classifyObj[curTab].sjlChartData"
                >
                </line-echarts>
              </div>
              <div class="bottom">
                <!-- </ver-tabs> -->
                <div class="_left">
                  <h6>
                    应收<span class="num">
                      {{
                        curYearData[curTab] &&
                          (curYearData[curTab].total_amount / 10000).toFixed(2)
                      }} </span
                    >万
                  </h6>
                  <h6>
                    已收<span class="num">
                      {{
                        curYearData[curTab] &&
                          (curYearData[curTab].pay_amount / 10000).toFixed(2)
                      }}</span
                    >万
                  </h6>
                  <h6>
                    欠收<span class="num">
                      {{
                        curYearData[curTab] &&
                          (curYearData[curTab].rest_amount / 10000).toFixed(2)
                      }} </span
                    >万
                  </h6>
                </div>
                <div class="_right">
                  <bar-echarts
                    v-if="!isLoading"
                    :chartData="classifyObj[curTab].curYearChartData"
                    id="renli"
                    :className="'bar_chart'"
                  ></bar-echarts>
                </div>
              </div>
            </div>
          </div>
        </hor-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import VerTabs from "@/components/common/verTabs/VerTabs.vue";
import HorTabs from "@/components/common/horTabs/HorTabs.vue";

export default {
  name: "YingshouData",
  props: ["datum", "isLoading"],
  components: { VerTabs, HorTabs },
  data() {
    return {
      curKey: "total_amount",
      pieKeys: {
        pay_amount: "已缴",
        rest_amount: "未缴",
      },
      classifyObj: {},
      yearKeys: {
        old_year_classify_list: {
          val: "去年",
          lineColor: "#0089f8",
          textColor: "#0089f8",
        },
        this_year_classify_list: {
          val: "今年",
          lineColor: "#9bf8fe",
          textColor: "#9bf8fe",
        },
      },
      yearObj: {},
      curYearData: {},
      curYearKeys: { pay_amount: "已收", rest_amount: "欠费" },
      curTab: 1,
    };
  },
  watch: {
    datum() {
      if (this.datum) {
        this.formatCurYearData();
        if (this.datum.classify_list && this.datum.classify_list.length) {
          this.curTab = this.datum.classify_list[0].id;
          this.datum.classify_list.forEach((item, index) => {
            this.formatChart(item, index);
          });
        }
      }
    },
    curTab() {},
  },
  methods: {
    formatCurYearData() {
      if (
        this.datum.this_year_classify_list &&
        this.datum.this_year_classify_list.length
      ) {
        this.datum.this_year_classify_list.forEach((item) => {
          this.curYearData[item.id] = {
            pay_amount: item.pay_amount,
            rest_amount: item.rest_amount,
            total_amount: item.total_amount,
          };
        });
      }
    },
    formatChart(item, index) {
      this.classifyObj[item.id] = {
        name: item.name,
        chartData: {
          nameOfSeries: "收缴率",
          dataOfSeries: [],
          posOfChart: ["45%", "50%"],
          radiusOfChart: ["40%", "50%"],
          labelOfSeries: {
            normal: {
              show: false,
              fontWeight: "bold",
              position: "center",
              formatter: "{a}\n\n{d}%",
            },
          },
        },
        sjlChartData: {
          xAxis: {
            type: "category",
            data: [],
          },
          series: [],
          grid: { right: "0%", bottom: "15%", left: "0%", top: "10%" },
        },
        curYearChartData: {
          series: [],
          dataOfLegend: [],
          dataOfXaxis: [],
          grid: { right: "0%", bottom: "15%", left: "5%", top: "0%" },
        },
      };
      // 环形图
      for (let key in this.pieKeys) {
        let objs = {
          name: this.pieKeys[key],
          value: item[key].toFixed(2),
          label: { show: key == "pay_amount" ? true : false },
        };
        this.classifyObj[item.id].chartData.dataOfSeries.push(objs);
      }
      // 折线图
      // this.classifyObj[item.id].sjlChartData.series = [];
      for (let key in this.yearKeys) {
        let obj = {
          name: this.yearKeys[key].val,
          data: [],
          type: "line",
          lineStyle: {
            color: this.yearKeys[key].lineColor,
            // width: 1,
          },
          itemStyle: {
            borderType: "solid",
            color: this.yearKeys[key].textColor,
          },
        };
        this.classifyObj[item.id].sjlChartData.xAxis.data = [];
        if (
          this.datum[key][index].month_list &&
          this.datum[key][index].month_list.length
        ) {
          this.datum[key][index].month_list.forEach((items, indexs) => {
            this.classifyObj[item.id].sjlChartData.xAxis.data.push(
              items.name + "月"
            );
            const lv =
              items.total_amount == 0
                ? 100
                : ((items.pay_amount / items.total_amount) * 100).toFixed(2);
            obj.data.push(lv);
          });
        }
        this.classifyObj[item.id].sjlChartData.series.push(obj);
      }

      // 今年对应的应收已收欠费柱状图
      for (let key in this.curYearKeys) {
        let obj = {
          name: this.curYearKeys[key],
          type: "bar",
          stack: "应收",
          data: [],
        };
        this.classifyObj[item.id].curYearChartData.dataOfXaxis = [];
        if (
          this.datum["this_year_classify_list"][index].month_list &&
          this.datum["this_year_classify_list"][index].month_list.length
        ) {
          this.datum["this_year_classify_list"][index].month_list.forEach(
            (items, indexs) => {
              this.classifyObj[item.id].curYearChartData.dataOfXaxis.push(
                items.name + "月"
              );
              obj.data.push(items[key].toFixed(2));
            }
          );
        }
        this.classifyObj[item.id].curYearChartData.series.push(obj);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
