<template>
  <div class="total_money_container">
    <div class="work_box">
      <!-- <div class="title">欠收总额</div> -->
      <div
        class="content"
        v-loading="isLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-carousel style="height: 100%" :interval="50000" arrow="always">
          <el-carousel-item
            :label="value.name"
            v-for="(value, key) in dataOfDebt"
            :key="key"
          >
            <div class="data_container">
              <div class="_left">
                <h3>
                  <div>欠收总额</div>
                  <div>
                    <span class="yw">{{
                      (value.amount / 10000).toFixed(2)
                    }}</span
                    ><span>万元</span>
                  </div>
                </h3>
              </div>
              <div class="_center">
                <pie-echarts
                  v-if="!isLoading && datum"
                  :chartData="value.chartData"
                  :id="key"
                  :className="'pie__chart'"
                ></pie-echarts>
              </div>
              <div class="_right">
                <div v-for="item in value.options" :key="item.name">
                  <h4
                    class="dot"
                    :style="{ 'background-color': item.bgColor }"
                  ></h4>
                  <span>{{ item.name }}：{{ item.value }}</span>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TotalMoney",
  props: ["datum", "isLoading"],
  data() {
    return {
      colorList: [
        "#0089f8",
        "#9bf8fe",
        "#1baff9",
        "#0073ed",
        "#1e1988",
        "#3f5b9b",
        "#B17CFF",
        "#2576ac",
        "#79a3bd",
      ],
      dataOfDebt: {
        total: {
          name: "全部",
          amount: 0,
          options: [],
          chartData: {
            dataOfSeries: [],
            posOfChart: ["50%", "55%"],
            radiusOfSeries: "70%",
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b}: {c} ({d}%)",
            },
          },
        },
        current: {
          name: "本年",
          amount: 0,
          options: [],
          chartData: {
            dataOfSeries: [],
            posOfChart: ["50%", "55%"],
            radiusOfSeries: "70%",
          },
        },
        old: {
          name: "往年",
          amount: 0,
          options: [],
          chartData: {
            dataOfSeries: [],
            posOfChart: ["50%", "55%"],
            radiusOfSeries: "70%",
          },
        },
      },
    };
  },
  watch: {
    datum(data) {
      if (data) {
        this.formatChart();
      }
    },
  },
  methods: {
    formatChart() {
      for (let key in this.dataOfDebt) {
        let values = this.dataOfDebt[key];
        values.chartData.dataOfSeries = [];
        values.options = [];
        if (
          this.datum.total &&
          this.datum.total[key] &&
          this.datum.total[key].tag_list
        ) {
          // 获取全部、本年、往年的总数据
          this.dataOfDebt[key].amount = this.datum.total[key].total;
          const tagList = this.datum.total[key].tag_list;

          for (let key2 in tagList) {
            let values2 = tagList[key2];
            let obj = {
              name: key2,
              value: values2,
            };
            values.chartData.dataOfSeries.push(obj);
            // values.chartData.legend.data.push(key2);
            values.options.push(obj);
          }
        }
        if (values.options.length) {
          values.options.forEach((itm, i) => {
            itm.bgColor = this.colorList[i];
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "./style.scss";
@import "~@/style/carousel.scss";
</style>