<template>
  <div class="base_info_container">
    <div class="lunbo">
      <div class="work_box">
        <div class="content" @click="showVideo">
          <!-- <img style="width: 100%; height: 100%" :src="imgArr[0]" alt="" @click="showVideo" /> -->

          <el-carousel height="100%">
            <template v-if="imgArr.length">
              <el-carousel-item v-for="(item, index) in imgArr" :key="index">
                <img style="width: 100%; height: 100%" :src="item" alt="" />
              </el-carousel-item>
            </template>
            <template v-else>
              <el-carousel-item>
                <img
                  style="width: 100%; height: 100%"
                  src="../../../assets/img/project_img.png"
                  alt=""
                />
              </el-carousel-item>
            </template>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="base_data">
      <div class="work_box">
        <!-- <div class="title">基本信息</div> -->
        <div
          class="content"
          v-loading="isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div v-if="datum">
            <div class="item">
              <span>占地面积：{{ datum.cover_area }} 平米</span>
            </div>
            <div class="item">
              <span>建筑面积：{{ datum.builtup_area }}平米</span>
            </div>
            <div class="item">
              <span>收费面积：{{ datum.charge_area }}平米</span>
            </div>
            <div class="item">
              <span>建成时间：{{ datum.builtup_date }}</span>
              <span>入住时间：{{ datum.join_date }}</span>
            </div>
            <div class="item">
              <span
                >所在城市：{{
                  datum.province_name + "" + datum.city_name
                }}</span
              >
            </div>
            <div class="item">
              <span>所在区域：{{ datum.town_name }}</span>
            </div>
            <div class="item">
              <span>所在街道：{{ datum.street }}</span>
            </div>
            <div class="item">
              <span>项目负责人：{{ datum.manage_people }}</span>
            </div>
            <div class="item">
              <span>所属上级单位：{{ datum.develop_company }}</span>
            </div>
            <div class="item comments">
              <span>{{ datum.comments ? datum.comments : "暂无备注" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-video :show.sync="createVideo.show" :curOrg="curOrg"></create-video>
  </div>
</template>
<script>
import Config from "@/config";
import Common from "@/api/common";
import CreateVideo from "@/components/dlgs/CreateVideo";

export default {
  name: "BaseInfo",
  props: ["isLoading", "datum", "curOrg"],
  components: { CreateVideo },
  data() {
    return {
      createVideo: { show: false },
      info: {},
      imgArr: [],
      noImg: [
        {
          name: "图片1",
          url: "/img/project_img.png",
        },
      ],
    };
  },
  watch: {
    datum(data) {
      this.info = data;
      if (this.info.photo) {
        this.imgArr[0] = Common.buildUrl("file/download/" + this.info.photo);
      }
    },
  },
  methods: {
    showVideo() {
      this.createVideo.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>
