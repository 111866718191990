<template>
  <div class="sex_container">
    <div class="work_box">
      <div class="title">性别结构</div>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="sex"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sex",
  props: ["datum", "isLoading"],
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%",top:'0%' },
      },
    };
  },
  watch: {
    datum() {
      this.formatData();
    },
  },
  methods: {
    formatData() {
      if (this.datum.sex_list && this.datum.sex_list.length) {
        this.chartData.series = [];
        this.datum.sex_list.forEach((item, index) => {
          this.chartData.series.push({
            name: item.name,
            type: "bar",
            data: [],
          });
          if (item.job_type_list && item.job_type_list.length) {
            this.chartData.dataOfXaxis = [];
            item.job_type_list.forEach((item2) => {
              this.chartData.series[index].data.push(item2.count);
              this.chartData.dataOfXaxis.push(item2.name);
            });
          }
          this.chartData.dataOfLegend.push(item.name);
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.sex_container {
  height: 25%;
  background: green;
  & .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>