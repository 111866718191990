<template>
  <div class="sallary_container">
    <div class="level_classify">
      <div class="work_box">
        <div class="title">人均工资</div>
        <div class="content">
          <bar-echarts
            v-if="!isLoading"
            :chartData="yearOfChartData"
            id="year_chengben"
            :className="'bar_chart'"
          ></bar-echarts>
        </div>
      </div>
    </div>
    <div class="qushi">
      <div class="work_box">
        <div class="title">人均工资趋势</div>
        <div class="content">
          <bar-echarts
            v-if="!isLoading"
            :chartData="chartData"
            id="chengben"
            :className="'bar_chart'"
          ></bar-echarts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sallary",
  props: ["isLoading", "datum"],
  watch: {
    datum() {
      if (this.datum) {
        this.formatData(); //各年不同工种
        this.formatYearData(); //今年不同工种
      }
    },
  },
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%" },
      },
      yearOfChartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%" },
      },
    };
  },
  methods: {
    formatData() {
      this.chartData.series = [];
      this.chartData.dataOfLegend = [];

      for (let key in this.datum.job_type_count_list) {
        // 初始化legend
        this.chartData.dataOfLegend.push(key);
        // 初始化 series的data
        let obj = {
          name: key,
          type: "bar",
          data: [],
        };
        this.chartData.dataOfXaxis = [];
        for (let key2 in this.datum.year_cost_list) {
          obj.data.push(this.datum.year_cost_list[key2].job_type_list[key].avg);
          // 初始化x轴数据
          this.chartData.dataOfXaxis.push(key2 + "年");
        }
        this.chartData.series.push(obj);
        console.log(this.chartData);
      }
    },
    formatYearData() {
      const curYear = new Date().getFullYear();
      this.yearOfChartData.series = [
        {
          name: "人数",
          type: "bar",
          data: [],
        },
      ];
      this.yearOfChartData.dataOfLegend = [];
      this.yearOfChartData.dataOfXaxis = [];
      if (
        this.datum.year_cost_list[curYear] &&
        this.datum.year_cost_list[curYear].job_type_list
      ) {
        for (let key in this.datum.year_cost_list[curYear].job_type_list) {
          this.yearOfChartData.dataOfXaxis.push(key);
          this.yearOfChartData.series[0].data.push(
            this.datum.year_cost_list[curYear].job_type_list[key].cost
          );
        }
      }
      console.log(this.yearOfChartData);
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
@import "./style.scss";
</style>