<template>
  <div class="age_container">
    <div class="work_box">
      <div class="title">年龄结构</div>
      <div class="content">
        <bar-echarts
          v-if="!isLoading"
          :chartData="chartData"
          id="age"
          :className="'bar_chart'"
        ></bar-echarts>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sex",
  props: ["datum", "isLoading"],
  data() {
    return {
      chartData: {
        series: [],
        dataOfLegend: [],
        dataOfXaxis: [],
        grid: { right: "0%", bottom: "20%", left: "0%", top: "0%" },
      },
    };
  },
  watch: {
    datum() {
      this.formatData();
    },
  },
  methods: {
    formatData() {
      if (this.datum.age_list && this.datum.age_list.length) {
        this.chartData.series = [
          {
            name: "正式",
            type: "bar",
            data: [],
          },
        ];
        this.chartData.dataOfXaxis = [];
        this.datum.age_list.forEach((item, index) => {
          this.chartData.series[0].data.push(item.count);
          this.chartData.dataOfXaxis.push(item.name);
          this.chartData.dataOfLegend.push(item.name);
        });
        console.log(this.chartData);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/work-box.scss";
.age_container {
  height: 25%;
  background: green;
  & .work_box {
    height: 100%;
    & > .content {
      display: flex;
    }
  }
}
</style>
