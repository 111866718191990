import { render, staticRenderFns } from "./ShebeiYanghu.vue?vue&type=template&id=475ff82d&scoped=true&"
import script from "./ShebeiYanghu.vue?vue&type=script&lang=js&"
export * from "./ShebeiYanghu.vue?vue&type=script&lang=js&"
import style0 from "./ShebeiYanghu.vue?vue&type=style&index=0&id=475ff82d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475ff82d",
  null
  
)

export default component.exports